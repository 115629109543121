import { Box, Img, SlideFade, Stack } from '@chakra-ui/react';
import { PROTECTED_PATHS } from '../../../../app/constants';
import Container from '../../../../components/Container';
import { PrimaryButton } from '../../../../components/CustomButton';
import Header from '../../../../components/Header';
import { Success } from '../../../../components/Success';
import { BuyDataForm } from './BuyDataForm';
import { COLORS } from '../../../../constants/colors';

const DataPayment = ({
  logo,
  dataPlans,
  handleDataSubscription,
  setMobileNumber,
  mobileNumber,
  selectedOption,
  setSelectedOption,
  loading,
  success,
}) => {
  const { MY_WALLET } = PROTECTED_PATHS;

  return success ? (
    <Success />
  ) : (
    <SlideFade in={true} offsetX="-2000px">
      <Stack>
        <Box shadow="sm">
          <Container>
            <Header goto={MY_WALLET} />
          </Container>
        </Box>
        <Container>
          <Stack py="5" color={COLORS.green}>
            <Img pb="3" w="100px" h="100px" src={logo} alt="airtel" />
            <BuyDataForm
              dataPlans={dataPlans}
              setMobileNumber={setMobileNumber}
              mobileNumber={mobileNumber}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
            />
          </Stack>
          <PrimaryButton
            my="6"
            isLoading={loading}
            btnText="Make Payment"
            onClick={handleDataSubscription}
          />
        </Container>
      </Stack>
    </SlideFade>
  );
};

export default DataPayment;
