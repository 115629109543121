import { Box, Flex, Img, Text } from "@chakra-ui/react";
import { CarouselProvider, Slide, Slider } from "pure-react-carousel";
import { useNavigate } from "react-router-dom";
import { v4 } from "uuid";
import { COLORS } from "../constants/colors";
import { PROTECTED_PATHS } from "../app/constants";
import { dynamicPathhandler } from "./utils/dynamicPathHandler";

const TopArtisans = ({ topArtisans, navigateToAll }) => {
  const navigate = useNavigate();
  const { ARTISAN } = PROTECTED_PATHS;
  const parentPath = dynamicPathhandler(ARTISAN); // Use dynamicPathhandler

  const handleArtisanClick = (artisanId) => {
    const safeArtisanId = artisanId.replaceAll("/", "-");
    navigate(`${parentPath}/${safeArtisanId}`);
  };

  return (
    <Box py="15px">
      <Flex mt="30px" justify={"space-between"}>
        <Text fontSize="1rem" color={COLORS.primary} mb="10px">
          Top Artisans
        </Text>
        <Text
          color={COLORS.primary}
          cursor={"pointer"}
          onClick={navigateToAll}
        >
          See All
        </Text>
      </Flex>
      <CarouselProvider
        infinite={true}
        visibleSlides={3}
        dragEnabled={true}
        isPlaying={true}
        naturalSlideWidth={70}
        naturalSlideHeight={70}
        totalSlides={topArtisans.length}
      >
        <Slider>
          {topArtisans.map((artisan, index) => (
            <Slide key={v4()} index={index}>
              <Box
                bg={"#fff"}
                borderRadius="10px"
                p="5px"
                shadow="md"
                textAlign="center"
                cursor="pointer"
                onClick={() => handleArtisanClick(artisan.artisanId)}
              >
                <Img
                  src={artisan.image}
                  alt={`${artisan.name}'s profile`}
                  borderRadius="full"
                  boxSize="40px"
                  mx="auto"
                  mb="3px"
                />
                <Text fontSize="xs" color={COLORS.green}>
                  {artisan.name}
                </Text>
                <Text
                  fontSize="xs"
                  fontWeight={"bold"}
                  color={COLORS.green}
                >
                  {artisan.skillType}
                </Text>
              </Box>
            </Slide>
          ))}
        </Slider>
      </CarouselProvider>
    </Box>
  );
};

export default TopArtisans;
