import { Box, HStack, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import React from 'react';
import { errandTabHeads, selectedTabStyles } from './errandsData';
import { v4 } from 'uuid';
import { PrimaryButton } from '../../../components/CustomButton';

const ErrandTabs = ({ errands }) => {
  const pendingErrands = errands.filter((errand) => errand?.status?.toLowerCase() === 'pending');
  const ongoingErrands = errands.filter((errand) => errand?.status?.toLowerCase() === 'ongoing');
  const completedErrands = errands.filter((errand) => errand?.status?.toLowerCase() === 'completed');

  return (
    <Tabs size='sm'>
      <TabList justifyContent='space-between'>
        {errandTabHeads.map((tab) => (
          <Tab className='errand-tab' _selected={{ ...selectedTabStyles }} _focus={{ border: 'none' }} key={v4()} fontWeight='600'>
            {tab}
          </Tab>
        ))}
      </TabList>
      <TabPanels textAlign='center' color='primaryDark' fontWeight='500'>
        <TabPanel mt='4' bg='bgColorLight'>
          {pendingErrands?.map((item) => (
            <HStack my='4' justify='space-between' fontSize='13px' key={v4()}>
              <Text>{item?.errandTitle}</Text>
              <Text>{item?.status}</Text>
              <Box>
                <PrimaryButton size='xs' w='70px' btnText='Cancel' />
              </Box>
            </HStack>
          ))}
        </TabPanel>
        <TabPanel mt='4' bg='bgColorLight'>
          {ongoingErrands?.map((item) => (
            <HStack my='4' justify='space-between' key={v4()}>
              <Text>{item?.errandTitle}</Text>
              <Text>{item?.status}</Text>
            </HStack>
          ))}
        </TabPanel>
        <TabPanel mt='4' bg='bgColorLight'>
          {completedErrands?.map((item) => (
            <HStack my='4' justify='space-between' key={v4()}>
              <Text>{item?.errandTitle}</Text>
              <Text>{item?.status}</Text>
            </HStack>
          ))}
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default ErrandTabs;
