import { HStack, Stack, Text, VStack } from '@chakra-ui/react';
import { RiWhatsappLine, RiMessengerLine } from 'react-icons/ri';
import { infoNotifier } from '../../../components/NotificationHandler';

const SendMessage = ({ onClose, singleTopProductDetails }) => {
  // const postersNumber = '+2347068993251';
  const postersNumber = singleTopProductDetails?.phoneNumber;
  const message = 'hello';

  const handleShareViaWhatsapp = async () => {
    if (!postersNumber) {
      infoNotifier('Product owner phone number is not available');
    }
    alert('Please note this depends on wether the number is registered on whatsapp ');
    // to open whatapp to the number with default message
    window.open(`whatsapp://send?phone=${postersNumber}&text=${message}`, '_blank');

    // https://api.whatsapp.com/send/?phone=07068993251&text&app_absent=0
    // “https://web.whatsapp.com/send?phone=number&text=message&app_absent=0”

    // to open the exact number on whatsapp without message
    // window.open(`https://wa.me/${postersNumber}`, '_blank');
  };

  const handleShareViaMessenger = () => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=example.org`, '_blank');
    // window.open(`fb-messenger://send?link=akogwu11`, '_blank');
  };

  return (
    <Stack>
      <Text fontSize='1rem' fontWeight='600' textAlign='center'>
        Select channel
      </Text>

      <HStack py='5' justify='space-around'>
        <VStack textAlign='center' onClick={handleShareViaWhatsapp}>
          <Text as='span'>Whatapp</Text>
          <RiWhatsappLine size={30} color='#42ed51' />
        </VStack>

        <VStack textAlign='center' onClick={handleShareViaMessenger}>
          <Text as='span'>Facebook</Text>
          <RiMessengerLine size={30} color='#616ff2' />
        </VStack>
      </HStack>
    </Stack>
  );
};

export default SendMessage;
