import {
  Container,
  Flex,
  Heading,
  SlideFade,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { PUBLIC_PATHS } from "../../app/constants";
import { MdArrowBack } from "react-icons/md";
import { COLORS } from "../../constants/colors";

const PrivacyPolicy = () => {
  return (
    <SlideFade in={true} offsetX="-5000px">
      <Container>
        <Stack
          // w="100%"
          as="section"
          py="30px"
          px="10%"
          bg="#000"
          color={COLORS.white}
          w="550px"
          ml="-20px"
        >
          <Flex>
            <Link to={PUBLIC_PATHS.LOGIN}>
              <MdArrowBack cursor="pointer" size={22} />
            </Link>
          </Flex>
          <Heading>Privacy Policy</Heading>
          <Text>MyEstate App Privacy Policy Last Updated: January, 4 2023</Text>
          <Text my="1rem">
            {" "}
            Welcome to MyEstate, the security response app ("MyEstate," "we,"
            "our," or "us").
          </Text>
          <Text>
            {" "}
            This Privacy Policy is designed to help you understand how we
            collect, use, disclose, and safeguard your personal information.
          </Text>
          <Text>
            1. Information We Collect 1.1 Information You Provide We may collect
            personal information that you voluntarily provide to us when using
            MyEstate, such as: Contact information (e.g., name, email address,
            phone number) User profile information Location information Incident
            reports and details
          </Text>
          <Text>
            1.2 Information We Collect Automatically We may also automatically
            collect certain information when you use MyEstate, including: Device
            information (e.g., device type, operating system) Log information
            (e.g., access times, IP address) Usage information (e.g., features
            used, interactions)
          </Text>
          <Text>
            2. How We Use Your Information We use the collected information for
            various purposes, including but not limited to: Providing and
            improving MyEstate services Communicating with you Analyzing usage
            patterns Responding to security incidents
          </Text>
          <Text>
            3. Sharing Your Information We may share your information with third
            parties in the following circumstances: With your consent To comply
            with legal obligations To protect our rights and interests In
            connection with a merger, acquisition, or sale of assets{" "}
          </Text>
          <Text>
            {" "}
            4. Data Security We take reasonable measures to protect your
            personal information from unauthorized access, disclosure,
            alteration, and destruction. However, no method of transmission over
            the internet or electronic storage is completely secure.{" "}
          </Text>
          <Text>
            {" "}
            5. Your Choices You have choices regarding the collection, use, and
            sharing of your information. You can: Opt out of certain data
            collection Update or delete your account information{" "}
          </Text>
          <Text>
            6. Children's Privacy MyEstate is not directed towards individuals
            under the age of 13, and we do not knowingly collect personal
            information from children.{" "}
          </Text>
          <Text>
            7. Changes to this Privacy Policy We may update this Privacy Policy
            to reflect changes in our practices. We encourage you to review this
            policy periodically.{" "}
          </Text>
          <Text>
            8. Contact Us If you have any questions or concerns about this
            Privacy Policy, please contact us at{" "}
            <Text as="span" color={COLORS.green}>
              MyEstate.ng
            </Text>
            .
          </Text>
        </Stack>
      </Container>
    </SlideFade>
  );
};

export default PrivacyPolicy;
