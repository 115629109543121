import ekedc from '../../../assets/ekedc.png';

export const cardStyles = {
  bg: '#F5EEFF',
  px: '5',
  py: '4',
  maxW: '150px',
  h: '100px',
  flexDirection: 'column',
  borderRadius: '5px',
  border: '1px solid #3E3E3E0F',
};

export const transactions = [
  {
    source: 'Self Deposit GTB',
    date: 'October 28, 2021',
    amount: '3600.00',
  },
  {
    source: 'USSD',
    date: 'October 28, 2021',
    amount: '3600.00',
  },
  {
    source: 'Self Deposit GTB',
    date: 'October 28, 2021',
    amount: '3600.00',
  },
  {
    source: 'Self Deposit GTB',
    date: 'October 28, 2021',
    amount: '3600.00',
  },
];

export const billOptionsStyle = {
  gap: '1rem',
  align: 'center',
  bg: 'bgColorLight',
  py: '2',
  px: '3',
  borderRadius: '5px',
  shadow: 'sm',
  mt: '5',
  cursor: 'pointer',
};

export const utitlityBillsImages = {
  EkoElectric: ekedc,
  IkejaElectric: '/disco images/ikeja.webp',
  AbujaElectric: '/disco images/abuja.png',
  KanoElectric: '/disco images/kano.png',
  EnuguElectric: '/disco images/enugu.jpg',
  PortHarcourtElectric: '/disco images/pH.jpg',
  IbadanElectric: '/disco images/ibadan.jpg',
  KadunaElectric: '/disco images/kaduna.jpg',
  JosElectric: '/disco images/jos.jpeg',
  BeninElectric: '/disco images/benin.png',
  YolaElectric: '/disco images/yola.jpg',
};

export const chooseImageToDisplay = (provider) => {
  switch (provider) {
    case 'Ikeja Electric':
      return utitlityBillsImages.IkejaElectric;

    case 'Eko Electric':
      return utitlityBillsImages.EkoElectric;

    case 'Abuja Electric':
      return utitlityBillsImages.AbujaElectric;

    case 'Kano Electric':
      return utitlityBillsImages.KanoElectric;

    case 'Enugu Electric':
      return utitlityBillsImages.EnuguElectric;

    case 'Port Harcourt Electric':
      return utitlityBillsImages.PortHarcourtElectric;

    case 'Ibadan Electric':
      return utitlityBillsImages.IbadanElectric;

    case 'Kaduna Electric':
      return utitlityBillsImages.KadunaElectric;

    case 'Jos Electric':
      return utitlityBillsImages.JosElectric;
    case 'Benin Electric':
      return utitlityBillsImages.BeninElectric;

    case 'Yola Electric':
      return utitlityBillsImages.YolaElectric;

    default:
      return ekedc;
  }
};
