import {
  Box,
  FormControl,
  FormLabel,
  Img,
  Input,
  SlideFade,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";
import Container from "../../../../components/Container";
import { PrimaryButton } from "../../../../components/CustomButton";
import Header from "../../../../components/Header";
import { Success } from "../../../../components/Success";
import airtel from "../../../../assets/Airtel.svg";
import useAirtimePurchase from "./useAirtimePurchase";
import { inputBorder } from "../../../post advert/components/data";
import { PROTECTED_PATHS } from "../../../../app/constants";
import { COLORS } from "../../../../constants/colors";

const AirtelAirtimePayment = () => {
  const { MY_WALLET } = PROTECTED_PATHS;
  const inputTextColor = useColorModeValue(COLORS.white, COLORS.grey);

  const {
    success,
    loading,
    amount,
    mobileNumber,
    setAmount,
    setMobileNumber,
    handleAirtimePurshase,
  } = useAirtimePurchase();

  return success ? (
    <Success />
  ) : (
    <SlideFade in={true} offsetX="-2000px">
      <Stack>
        <Box shadow="sm">
          <Container>
            <Header goto={MY_WALLET} />
          </Container>
        </Box>
        <Container>
          <Stack py="5" color={COLORS.green}>
            <Img pb="3" w="100px" src={airtel} alt="airtel" />
            {/* <PaymentForm /> */}
            <FormControl>
              <Stack spacing="0">
                <FormLabel
                  htmlFor="decoder"
                  mb="0 !important"
                  fontSize="14px"
                  pt="5"
                >
                  Phone Number
                </FormLabel>
                <Input
                  {...inputBorder}
                  type="number"
                  placeholder="081555555555"
                  bg={COLORS.lightBrown}
                  _placeholder={{ opacity: 1, color: `${inputTextColor}` }}
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                />
                <FormLabel htmlFor="amount" mb="0" fontSize="14px" pt="5">
                  Amount
                </FormLabel>
                <Input
                  {...inputBorder}
                  placeholder="500"
                  bg={COLORS.lightBrown}
                  _placeholder={{ opacity: 1, color: `${inputTextColor}` }}
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </Stack>
            </FormControl>
          </Stack>
          <PrimaryButton
            my="6"
            btnText="Make Payment"
            isLoading={loading}
            onClick={() => handleAirtimePurshase(4)}
          />
        </Container>
      </Stack>
    </SlideFade>
  );
};

export default AirtelAirtimePayment;
