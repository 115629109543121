import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  useColorModeValue,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { deleteContact } from "../service/deleteContact";
import { BsThreeDotsVertical } from "react-icons/bs";
import { COLORS } from "../../../constants/colors";

export const MenuItems = ({ contactList, userId, setRefresh }) => {
  const cardBg = useColorModeValue(COLORS.lightGrey, COLORS.white);
  const { guestName, phoneNumber } = contactList;
  const navigate = useNavigate();

  const handleDelete = async () => {
    await deleteContact(userId, contactList?._id, setRefresh);
  };

  return (
    <Menu bg={cardBg} color={cardBg}>
      <MenuButton
        as={Button}
        bg="transparent"
        _selected={{ bg: "none", border: "none" }}
      >
        <BsThreeDotsVertical />
      </MenuButton>
      <MenuList color={cardBg}>
        <MenuItem
          onClick={() =>
            navigate(`/invite-guest?name=${guestName}&phone=${phoneNumber}`)
          }
        >
          Invite
        </MenuItem>
        <MenuItem onClick={handleDelete}>Delete contact</MenuItem>
      </MenuList>
    </Menu>
  );
};
