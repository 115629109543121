import { PROTECTED_PATHS } from "../../app/constants";
import { RiCameraLensLine, RiAdvertisementLine } from "react-icons/ri";
import { BiWallet } from "react-icons/bi";
import { BsHouse, BsGear, BsPeople, BsShop } from "react-icons/bs";
import { IoIosNotificationsOutline } from "react-icons/io";
import { IoChatboxEllipsesOutline } from "react-icons/io5";
import { VscPerson } from "react-icons/vsc";
import { FaRegIdCard, FaShopify,FaPeopleArrows } from "react-icons/fa";
import { MdHowToVote } from "react-icons/md";
import { FiShoppingCart } from "react-icons/fi";
import { TbBrandGoogleAnalytics } from "react-icons/tb";
import { MdOutlineSlowMotionVideo } from "react-icons/md";

export const NAV_ITEMS = [
  {
    title: "Home",
    to: PROTECTED_PATHS.MY_ESTATE,
    icon: <BsHouse size={20} />,
  },
  // {
  //   title: "Artisans",
  //   to: PROTECTED_PATHS.ARTISAN_PAGE,
  //   icon: <BsPeople size={20} />,
  // },
  //   {
  //   title: 'Checkout Items',
  //   to: PROTECTED_PATHS.GATE_ITEM_PASS,
  //   icon: <FaPeopleArrows size={18} />,
  // },
  {
    title: "Market place",
    to: PROTECTED_PATHS.MARKET_PLACE,
    icon: <BsShop size={20} />,
  },
  // {
  //   title: 'My Wallet',
  //   to: PROTECTED_PATHS.MY_WALLET,
  //   icon: <BiWallet size={20} />,
  // },
  // {
  //   title: 'My Errands',
  //   to: MY_ERRANDS,
  //   icon: <FaPeopleArrows size={18} />,
  // },

  // {
  //   title: 'Post Advert',
  //   to: PROTECTED_PATHS.POST_ADVERT,
  //   icon: <RiAdvertisementLine size={25} />,
  // },
  {
    title: "My Shop",
    to: PROTECTED_PATHS.SHOP,
    icon: <FiShoppingCart size={22} />,
  },
  {
    title: "Billing Analysis",
    to: PROTECTED_PATHS.BILLING_ANALYSIS,
    icon: <TbBrandGoogleAnalytics size={22} />,
    // icon: <FaShopify size={22} />,
  },
  // {
  //   title: 'News/Events',
  //   to: NEWS_EVENTS,
  //   icon: <BsCalendarEvent size={17} />,
  // },
  {
    title: "Guest Log",
    to: PROTECTED_PATHS.GUEST_LIST,
    icon: <VscPerson size={25} />,
  },
  {
    title: "Special Request",
    to: PROTECTED_PATHS.SPECIAL_GUEST_REQUEST,
    icon: <BsPeople size={22} />,
  },
  {
    title: "Notifications",
    to: PROTECTED_PATHS.NOTIFICATIONS,
    icon: <IoIosNotificationsOutline size={22} />,
  },
  {
    title: "How to Videos",
    to: PROTECTED_PATHS.VIDEO,
    // externalLink: "https://www.youtube.com/playlist?list=PLiPHQO1Q8o_-Nt_q0sL7rwjzTIMBjJQIV",
    icon: <MdOutlineSlowMotionVideo size={22}/>,
  },
  {
    title: "Issue Report",
    to: PROTECTED_PATHS.ISSUE_REPORT,
    icon: <RiCameraLensLine size={20} />,
  },
  {
    title: "Polls",
    to: PROTECTED_PATHS.POLLS,
    icon: <MdHowToVote size={20} />,
  },

  {
    title: "ID Card",
    to: PROTECTED_PATHS.ID_CARD,
    icon: <FaRegIdCard size={20} />,
  },
  {
    title: "Chat with MyEstateNG",
    to: PROTECTED_PATHS.CHAT,
    externalLink: "https://chat.usegerant.com/index.php?p=link&sp=3&ssp=en",
    icon: <IoChatboxEllipsesOutline size={20} />,
  },
  {
    title: "Settings",
    to: PROTECTED_PATHS.SETTINGS,
    icon: <BsGear size={20} />,
  },
];
