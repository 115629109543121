import { useContext } from "react";
import { AuthContext } from "../../../context/authContext";
// import SingleGuest from '../../my-estate/components/singleGuest';
import ContactList from "./contactList";

const Contacts = ({ contactList, searchTerm, setRefresh }) => {

  const { userInfo } = useContext(AuthContext);
  const { userId } = userInfo;

  return (
    <>
      {!searchTerm &&
        contactList?.map((guest) => (
          <ContactList
            key={guest?._id}
            contactList={guest}
            userId={userId}
            setRefresh={setRefresh}
          />
        ))}

      {searchTerm &&
        !Number(searchTerm) &&
        contactList
          ?.filter((contacts) =>
            searchTerm
              ? contacts?.guestName
                  ?.toLowerCase()
                  ?.includes(searchTerm.toLocaleLowerCase())
              : contacts
          )
          ?.map((guest) => (
            <ContactList
              key={guest?._id}
              contactList={guest}
              userId={userId}
              setRefresh={setRefresh}
            />
          ))}

      {searchTerm &&
        !isNaN(searchTerm) &&
        contactList
          ?.filter((contact) => {
            const phoneNumber = contact?.phoneNumber?.split("").slice(4);
            const phoneWithZero = ["0", ...phoneNumber];

            return searchTerm
              ? phoneWithZero?.join("")?.includes(searchTerm)
              : contact;
          })
          ?.map((guest) => (
            <ContactList
              key={guest?._id}
              contactList={guest}
              userId={userId}
              setRefresh={setRefresh}
            />
          ))}
    </>
  );
};

export default Contacts;
