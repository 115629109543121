import { Image, Stack } from '@chakra-ui/react';
import BillPicker from '../BillPicker';
import mtn from '../../../../assets/MTN.svg';
import glo from '../../../../assets/Glo.svg';
import etisalat from '../../../../assets/9mobile.svg';
import airtel from '../../../../assets/Airtel.svg';
import { PROTECTED_PATHS } from '../../../../app/constants';

const BuyDataModal = () => {
  return (
    <Stack fontWeight='500' spacing='6'>
      <BillPicker to={PROTECTED_PATHS.MTN_DATA}>
        <Image h='30px' src={mtn} alt='MTN' />
      </BillPicker>

      <BillPicker to={PROTECTED_PATHS.GLO_DATA}>
        <Image h='35px' src={glo} alt='Glo' />
      </BillPicker>

      <BillPicker to={PROTECTED_PATHS.ETISALAT_DATA}>
        <Image h='35px' src={etisalat} alt='9Mobile' />
      </BillPicker>

      <BillPicker to={PROTECTED_PATHS.AIRTEL_DATA}>
        <Image h='35px' src={airtel} alt='Airtel' />
      </BillPicker>
    </Stack>
  );
};

export default BuyDataModal;
