import { Image, Stack } from '@chakra-ui/react';
import BillPicker from '../BillPicker';
import mtn from '../../../../assets/MTN.svg';
import glo from '../../../../assets/Glo.svg';
import etisalat from '../../../../assets/9mobile.svg';
import airtel from '../../../../assets/Airtel.svg';
import { PROTECTED_PATHS } from '../../../../app/constants';

const BuyAirtimeModal = () => {
  const { MTN_AIRTIME, GLO_AIRTIME, AIRTEL_AIRTIME, ETISALAT_AIRTIME } =
    PROTECTED_PATHS;

  return (
    <Stack fontWeight='500' spacing='6'>
      <BillPicker to={MTN_AIRTIME}>
        <Image h='30px' src={mtn} alt='MTN' />
      </BillPicker>

      <BillPicker to={GLO_AIRTIME}>
        <Image h='35px' src={glo} alt='Glo' />
      </BillPicker>

      <BillPicker to={ETISALAT_AIRTIME}>
        <Image h='35px' src={etisalat} alt='9Mobile' />
      </BillPicker>

      <BillPicker to={AIRTEL_AIRTIME}>
        <Image h='35px' src={airtel} alt='Airtel' />
      </BillPicker>
    </Stack>
  );
};

export default BuyAirtimeModal;
