import {
  Box,
  Flex,
  HStack,
  Img,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Square,
  Stack,
  Text,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { useContext } from "react";
import { AuthContext } from "../../../context/authContext";
import { deleteIssue } from "../services/reportIssue";
import { issueTypeStyles } from "./issueReportData";
import CautionAlertDialog from "../../../components/CautionAlertDialog";
import { useNavigate } from "react-router-dom";
import { dynamicPathhandler } from "../../../components/utils/dynamicPathHandler";
import { PROTECTED_PATHS } from "../../../app/constants";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { CgClose } from "react-icons/cg";
import { COLORS } from "../../../constants/colors";

const IssueCard = ({ issue, setRefresh }) => {
  const cardBg = useColorModeValue(COLORS.lightGrey, COLORS.white);
  const bgColor = useColorModeValue(COLORS.primary, COLORS.green);
  const appTextColor = useColorModeValue(COLORS.primary, COLORS.primary);
  const { userInfo } = useContext(AuthContext);
  const { userId } = userInfo;
  const navigate = useNavigate();
  const { VIEW_REPORT_DETAILS, SEND_MESSAGE_TO_ADMIN } = PROTECTED_PATHS;
  const parentPath = dynamicPathhandler(VIEW_REPORT_DETAILS);
  const messageParent = dynamicPathhandler(SEND_MESSAGE_TO_ADMIN);

  const handleDeleteIssue = (issue) => {
    deleteIssue(userId, issue?._id).then(setRefresh);
  };

  return (
    <Flex
      justify="space-between"
      p="8px"
      shadow="sm"
      gap="10px"
      my="20px"
      bg="gray.50"
      rounded="lg"
    >
      <Box width="30%">
        <Img
          borderRadius="5px"
          h="100px"
          w="100%"
          objectFit="cover"
          src={issue.media[0]}
          alt="issue image"
        />
      </Box>
      <Stack flex={1} mt="10px">
        <Text fontSize="15px" fontWeight="semibold">
          {issue?.issueType}
        </Text>
        <Text fontSize="15px" fontWeight="500" lineHeight={0.4}>
          {issue?.title}
        </Text>
        <Text fontSize="14px" noOfLines={1} maxW={"200px"}>{issue?.description}</Text>
      </Stack>
      <Box mt="10px" cursor="pointer">
        <Menu>
          <MenuButton as={"p"}>
            <BiDotsVerticalRounded size={"1.5rem"} />
          </MenuButton>
          <MenuList bg={cardBg}>
            <MenuItem
              onClick={() => navigate(`${parentPath}/${issue._id}`)}
              fontWeight={"semibold"}
              fontSize="17px"
              ml="2px"
            >
              View
            </MenuItem>
            <CautionAlertDialog
              size="xs"
              cautionTitle={"Are you sure you will like to delete?"}
              onContinue={() => handleDeleteIssue(issue)}
              buttonProps={{ bg: { cardBg }, color: { cardBg } }}
              button={"Delete"}
            />
          </MenuList>
        </Menu>
      </Box>
    </Flex>
  );
};

export default IssueCard;
