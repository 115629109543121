import { HStack, Img, Stack, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { PROTECTED_PATHS } from '../../../app/constants';
import boyImage from '../../../assets/boyImage.jpg';
import { dynamicPathhandler } from '../../../components/utils/dynamicPathHandler';

const SingleOffer = ({ imageUrl, itemName, itemDescription, price, _id }) => {
  const navigate = useNavigate();
  const { OFFER_JOINT_ONE } = PROTECTED_PATHS;
  const parentPath = dynamicPathhandler(OFFER_JOINT_ONE);

  return (
    <HStack
      my='4'
      bg='#fff'
      shadow='md'
      borderRadius='5px'
      pr='1.5rem'
      cursor='pointer'
      h='105px'
      onClick={() => navigate(`${parentPath}/${_id}`)}
    >
      <Img
        w={['120px']}
        maxW='120px'
        h={['100px']}
        objectFit='cover'
        borderRadius='5px'
        src={imageUrl?.length > 0 ? imageUrl[0] : boyImage}
        alt='news-image'
      />
      <Stack spacing={'3'} color='#7A7A7A'>
        <Text fontWeight='bold'>{itemName}</Text>
        <Text m='0 !important' fontSize='12px'>
          {itemDescription}
        </Text>

        <HStack justify='space-between'>
          <Text fontWeight='bold' fontSize='14px'>
            &#8358; {(price)?.toLocaleString()}
          </Text>
          <Text> more details </Text>
        </HStack>
      </Stack>
    </HStack>
  );
};

export default SingleOffer;
