import {
  Box,
  Flex,
  Img,
  ScaleFade,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import Container from "../../components/Container";
import Header from "../../components/Header";
import HeadSeemore from "../../components/HeadSeemore";
import OffersCard from "./components/OffersCard";
import OfferWithPrice from "./components/OfferWithPrice";
import Footer from "../../components/footer";
import { useNavigate } from "react-router-dom";
import { PROTECTED_PATHS } from "../../app/constants";
import { getOffers } from "./service/offer";
import { AuthContext } from "../../context/authContext";
import FullPageLoader from "../../components/FullPageLoader";
import { v4 } from "uuid";
import { getAdvertProducts } from "./service/topProducts";
import { CarouselProvider, Slide, Slider } from "pure-react-carousel";
import { getCategories, getMarketplaceBanner } from "./service";
import { PrimaryButton } from "../../components/CustomButton";
import { transition } from "../..";
import { COLORS } from "../../constants/colors";
import market from "./market.png";
import { dynamicPathhandler } from "../../components/utils/dynamicPathHandler";

const MarketPalce = () => {
  const pageBg = useColorModeValue(COLORS.bgGrey, COLORS.darkModeBg);
  const cardBg = useColorModeValue(COLORS.primary, COLORS.white);
  const heroBg = useColorModeValue(COLORS.primary, COLORS.black);
  const pendCardBg = useColorModeValue(COLORS.white, COLORS.white);
  const appTextColor = useColorModeValue(COLORS.white, COLORS.grey);
  const { ALL_CATEGORY, ALL_TOP_PRODUCT, CATEGORY } = PROTECTED_PATHS;
  const { userInfo } = useContext(AuthContext);
  const { userId } = userInfo;
  const [loading, setLoading] = useState(false);
  const [offers, setOffers] = useState([]);
  const [advertProducts, setAdvertProducts] = useState([]);
  const numberOfAdvertsToRender = 3;
  const [advertTorender, setAdvertTorender] = useState(numberOfAdvertsToRender);
  const [banner, setBanner] = useState([]);
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();
  const parentPath = dynamicPathhandler(CATEGORY);

  useEffect(() => {
    getOffers(userId, setLoading, setOffers);
    getAdvertProducts(userId, setLoading, setAdvertProducts);
    getMarketplaceBanner(userId, setLoading, setBanner);
    getCategories(userId, setLoading, setCategories);
  }, [userId]);

  const handleLoadMore = () => {
    setAdvertTorender(advertTorender + numberOfAdvertsToRender);
  };

  console.log(advertProducts, "advertProducts");
  return loading ? (
    <FullPageLoader />
  ) : (
    <ScaleFade initialScale={0.3} in={true} {...transition}>
      <Box>
        <Box shadow="sm">
          <Container>
            <Header menu />
          </Container>
        </Box>

        <Stack pt="20px">
          <Container pos="relative">
            <Stack spacing={"5"}>
              {/* <SearchBar to={FILTER_PAGE} /> */}
              <Box>
                {banner.length > 0 ? (
                  <CarouselProvider
                    infinite={true}
                    visibleSlides={1}
                    dragEnabled={false}
                    isPlaying={true}
                    naturalSlideWidth={120}
                    naturalSlideHeight={55}
                    totalSlides={banner?.length}
                  >
                    <Slider>
                      {banner &&
                        banner?.map((b) => (
                          <Slide key={v4()}>
                            <Img {...flasSaleImageStyles} src={b?.imageUrl} />
                          </Slide>
                        ))}
                    </Slider>
                  </CarouselProvider>
                ) : (
                  <Flex bg={COLORS.lightGreen} borderRadius="10px">
                    <Flex>
                      <Text
                        fontSize="17px"
                        maxW={"70%"}
                        p="35px 20px"
                        fontWeight="600"
                        color={heroBg}
                      >
                        Get an affordable and good quality item in our market
                        place.
                      </Text>
                      <Img w="30%" rounded="lg" src={market} alt="Hero image" />
                    </Flex>
                  </Flex>
                )}
              </Box>

              <Box>
                <HeadSeemore head="Categories" seemore to={ALL_CATEGORY} />
                <CarouselProvider
                  infinite={true}
                  visibleSlides={2.5}
                  dragEnabled={false}
                  isPlaying={true}
                  naturalSlideWidth={100}
                  naturalSlideHeight={120}
                  totalSlides={categories?.length}
                >
                  <Slider>
                    <Flex gap={["2rem", ".5rem"]}>
                      {categories?.map((category, index) => (
                        <Slide>
                          <Flex
                            bg="#fff"
                            shadow="md"
                            rounded="2xl"
                            p={"5px"}
                            cursor="pointer"
                            w="120px"
                            mt="10px"
                            justify="center"
                            key={index}
                            // onClick={() => navigate(SAME_CATEGORY)}
                            onClick={() =>
                              navigate(
                                `${parentPath}/${category?.categoryName.toLowerCase()}`
                              )
                            }
                          >
                            <Box>
                              <Img
                                w={["90px"]}
                                maxW="90px"
                                h={["80px"]}
                                objectFit="cover"
                                rounded="lg"
                                src={category?.image}
                                alt={category?.categoryName}
                              />

                              <Text
                                py="5px"
                                noOfLines={1}
                                fontSize="12px"
                                fontWeight="semibold"
                                color="#7A7A7A"
                              >
                                {category?.categoryName}
                              </Text>
                            </Box>
                          </Flex>
                        </Slide>
                      ))}
                    </Flex>
                  </Slider>
                </CarouselProvider>
                {/* post advert button 
              <Circle zIndex="10" className="plus" bg="primary">
                <Link to={POST_ADVERT}>
                  <BsPlusLg size={20} />
                </Link>
              </Circle>*/}

                <Box mt="1rem">
                  {/*<HeadSeemore head="Offers" seemore to={ALL_OFFERS} />*/}
                  <HeadSeemore head="Offers" />

                  {offers?.length > 0 ? (
                    <CarouselProvider
                      infinite={true}
                      visibleSlides={1.5}
                      dragEnabled={false}
                      isPlaying={true}
                      naturalSlideWidth={100}
                      naturalSlideHeight={90}
                      totalSlides={offers?.length}
                    >
                      <Slider>
                        <Flex gap={["2rem", "1.8rem"]}>
                          {offers?.map((offers, index) => (
                            <Slide key={v4()} index={index}>
                              <OffersCard offers={offers} />
                            </Slide>
                          ))}
                        </Flex>
                      </Slider>
                    </CarouselProvider>
                  ) : (
                    <Flex
                      rounded={"lg"}
                      bg={cardBg}
                      justify="center"
                      align="center"
                      h="100px"
                    >
                      <Text
                        textAlign={"center"}
                        fontSize="18px"
                        color={appTextColor}
                      >
                        No offers yet
                      </Text>
                    </Flex>
                  )}
                </Box>

                <Box mt="0rem">
                  <HeadSeemore
                    head="Top Products"
                    seemore
                    to={ALL_TOP_PRODUCT}
                  />
                  {advertProducts?.length > 0 ? (
                    <CarouselProvider
                      infinite={true}
                      visibleSlides={2.5}
                      dragEnabled={true}
                      isPlaying={true}
                      naturalSlideWidth={120}
                      naturalSlideHeight={160}
                      totalSlides={advertProducts?.length}
                    >
                      <Slider>
                        <Flex gap={["2rem", "1.8rem"]}>
                          {advertProducts
                            ?.slice(0, advertTorender)
                            ?.map((advert, index) => (
                              <Slide key={v4()} index={index}>
                                <OfferWithPrice advert={advert} />
                              </Slide>
                            ))}
                        </Flex>
                      </Slider>
                    </CarouselProvider>
                  ) : (
                    <Flex
                      rounded={"lg"}
                      bg={cardBg}
                      justify="center"
                      align="center"
                      h="100px"
                    >
                      <Text
                        textAlign={"center"}
                        fontSize="18px"
                        color={appTextColor}
                      >
                        No top products yet
                      </Text>
                    </Flex>
                  )}

                  <Box py="10" pb="20">
                    {advertTorender < advertProducts?.length && (
                      <PrimaryButton
                        btnText="Load More"
                        onClick={handleLoadMore}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
            </Stack>
          </Container>
          <Footer />
        </Stack>
      </Box>
    </ScaleFade>
  );
};

export default MarketPalce;

const flasSaleImageStyles = {
  w: "100%",
  h: "150px",
  objectFit: "cover",
  borderRadius: "10px",
  alt: "flash sales",
};
