import { Box, HStack, Stack, Text } from '@chakra-ui/react';
import Container from '../../components/Container';
import Header from '../../components/Header';
import SingleEvents from './components/SingleEvents';
import SingleNews from './components/SingleNews';

const NewsEvents = () => {
  return (
    <Stack as='main'>
      <Box shadow='md'>
        <Container>
          <Header />
        </Container>
      </Box>
      <Container py='2'>
        <Text fontWeight='700' fontSize='16px'>
          Events
        </Text>
        <HStack overflowX='hidden'>
          {[...Array(2)].map((event, index) => (
            <SingleEvents key={index} />
          ))}
        </HStack>
      </Container>
      <Container py='2'>
        <Text fontWeight='700' fontSize='16px'>
          News
        </Text>
        <Stack spacing='5'>
          {[...Array(4)].map((event, index) => (
            <SingleNews key={index} />
          ))}
        </Stack>
      </Container>
    </Stack>
  );
};

export default NewsEvents;
