import {
  Box,
  Center,
  Circle,
  Flex,
  Img,
  Input,
  Stack,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { PROTECTED_PATHS } from "../../app/constants";
import FullPageLoader from "../../components/FullPageLoader";
import { AuthContext } from "../../context/authContext";
import { getContactList, getGuests } from "./service";
import GuestList from "./components/GuestList";
import Contacts from "./components/Contacts";
import { COLORS } from "../../constants/colors";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { addBtnStyles } from "../issue report";
import Footer from "../../components/footer";
import Container from "../../components/Container";

export const GuestHistory = () => {
  const appTextColor = useColorModeValue(COLORS.black, COLORS.white);
  const bgColor = useColorModeValue(COLORS.primary, COLORS.green);
  const tabColor = useColorModeValue(COLORS.primary, COLORS.green);
  const [guests, setGuests] = useState([]);
  const { userInfo } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [contactList, setContactList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [refresh, setRefresh] = useState(false);
  const { userId } = userInfo;
  const navigate = useNavigate();
  const { INVITE_GUEST } = PROTECTED_PATHS;

  const tabStyles = {
    _focus: { border: "none" },
    _selected: {
      color: appTextColor,
      // bg: pageBg,
      height: "35px",
      borderBottom: "10px",
      borderRadius: "5px",
    },
  };

  useEffect(() => {
    getGuests(userId, setLoading, setGuests);
    getContactList(userId, setLoading, setContactList);
  }, [userInfo, refresh, userId]);

  return loading ? (
    <FullPageLoader />
  ) : (
    <Stack pb="15vh" position="relative">
      <Box cursor="pointer" pb="1rem">
        <Container>
          <Header menu />
        </Container>
      </Box>

      <Container>
        <Tabs isFitted variant="unstyled" position="relative">
          <TabList gap="1rem">
            <Tab {...tabStyles} color={COLORS.lightGrey}>
              Guest List
              <Circle bg={tabColor} width="30px" height="20px" ml="10px">
                {guests?.length}
              </Circle>
            </Tab>
            <Tab {...tabStyles} color={COLORS.lightGrey}>
              Contacts
              <Circle bg={tabColor} width="30px" height="20px" ml="10px">
                {contactList?.length}
              </Circle>
            </Tab>
          </TabList>
          <TabIndicator
            mt="-1.5px"
            height="5px"
            bg={tabColor}
            borderTopRadius="5px"
          />
          <TabPanels mt="1.5rem">
            <TabPanel position="relative">
              <Input
                color={appTextColor}
                placeholder="Search guest list"
                _placeholder={{ opacity: 1, color: `${appTextColor}` }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value.trim())}
                width="100%"
                borderColor={appTextColor}
                borderRadius="10px"
                mb="2rem"
              />
              {guests?.length > 0 ? (
                <GuestList
                  setRefresh={setRefresh}
                  guests={guests}
                  searchTerm={searchTerm}
                  displayedGuests={guests}
                />
              ) : (
                <Center flexDirection="column" h="70vh" w="100%">
                  <Img src="/empty.svg" alt="empty" />
                  <Text
                    py="5"
                    fontWeight="500"
                    textAlign="center"
                    color={appTextColor}
                  >
                    You don't have any contact yet, Invite a guest to add
                    contacts
                  </Text>
                </Center>
              )}
              <Flex {...addBtnStyles} onClick={() => navigate(INVITE_GUEST)}>
                <BsFillPlusCircleFill color={bgColor} size={"2.5rem"} />
              </Flex>
            </TabPanel>

            <TabPanel>
              <Input
                color={appTextColor}
                placeholder="search contact..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                width="100%"
                borderColor={appTextColor}
                borderRadius="10px"
                mb="2rem"
              />
              {contactList?.length > 0 ? (
                <Contacts
                  setRefresh={setRefresh}
                  contactList={contactList}
                  searchTerm={searchTerm}
                />
              ) : (
                <Center flexDirection="column" h="70vh" w="100%">
                  <Img src="/empty.svg" alt="empty" />
                  <Text
                    py="5"
                    fontWeight="500"
                    textAlign="center"
                    color={appTextColor}
                  >
                    You dont have any contact yet, Invite a guest to add
                    contacts
                  </Text>
                </Center>
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Container>
      <Footer />
    </Stack>
  );
};
