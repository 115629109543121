export const daysOfTheWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const boldtext = { fontWeight: 600, fontSize: '16px' };

export const countPickedDaysInDateRange = (range, pickedDays) => {
  let count = 0;

  range.forEach((r) => {
    pickedDays.forEach((pd) => {
      if (pd === r) {
        count++;
      }
    });
  });

  return count;
};
