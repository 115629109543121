// GatePassRecords.jsx
import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Text,
  Stack,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Select,
  useDisclosure
} from "@chakra-ui/react";
import Container from "../../components/Container";
import Header from "../../components/Header";
import Footer from "../../components/footer";
import { COLORS } from "../../constants/colors";
import { MdPhone, MdStar, MdAdd } from "react-icons/md";

const GatePassRecords = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [type, setType] = useState("");
  const [name, setName] = useState("");
  const [items, setItems] = useState("");
  const [guestId, setGuestId] = useState("");

  const pageBg = useColorModeValue(COLORS.bgGrey, COLORS.darkModeBg);
  const textColor = useColorModeValue(COLORS.black, COLORS.white);
  const TransBg = useColorModeValue(COLORS.primary, COLORS.chartbg);
  const bgColor = useColorModeValue(COLORS.white, COLORS.green);

  const btTextColor = useColorModeValue(COLORS.white, COLORS.primary);
  const btBg = useColorModeValue(COLORS.primary, COLORS.chartbg);

  const cardBg = useColorModeValue(COLORS.lightGrey, COLORS.white);
  const appTextColor = useColorModeValue(COLORS.primary, COLORS.primary);

  // Example records (fetch actual records from the backend)
  const records = [
    {
      id: 1,
      type: "Errand Boy",
      name: "John Doe",
      items: "Groceries",
      code: "ABC123",
      date: "2025-06-24"
    },
    {
      id: 2,
      type: "Guest",
      name: "Jane Smith",
      items: "Luggage",
      code: "XYZ789",
      date: "2025-06-24"
    }
  ];

  // Example guest list (fetch actual guests from the backend)
  const guests = [
    { id: 1, name: "Guest 1" },
    { id: 2, name: "Guest 2" }
  ];

  const handleSubmit = () => {
    const payload =
      type === "errand-boy" ? { type, name, items } : { type, guestId, items };

    console.log("Submitted payload:", payload);

    // Clear form fields and close modal
    setType("");
    setName("");
    setItems("");
    setGuestId("");
    onClose();
  };

  return (
    <Stack spacing="5">
      <Box shadow="sm">
        <Container>
          <Header menu />
        </Container>
      </Box>

      <Stack pt="20px" spacing="6">
        <Container>
          <Flex justifyContent="center" alignItems="center" mb="20px">
            <Button
              bg={btBg}
              color={btTextColor}
              size="md"
              leftIcon={<MdAdd size={"2rem"} />}
              onClick={onOpen}
            >
              Add New Record
            </Button>
          </Flex>

          <Stack spacing="4">
            {records.map((record) => (
              <Box
                key={record.id}
                p="1rem"
                borderRadius="10px"
                boxShadow="sm"
                bg={cardBg}
                color={appTextColor}
                _hover={{
                  bg: `${bgColor}`,
                  "& *": {
                    color: TransBg
                  }
                }}
              >
                {/* Flex container for record.name on the left and record.type on the right */}
                <Flex justify="space-between" align="center" mb="2">
                  <Text fontSize="1.1rem" fontWeight="semibold" color="black">
                    {record.name}
                  </Text>
                  <Text>{record.type}</Text>
                </Flex>

                {/* Stack for the remaining details */}
                <Stack fontSize="0.8rem" spacing="1">
                  <Text>Items: {record.items}</Text>
                </Stack>

                <Flex justify="space-between" align="center" mb="2">
                  <Text>Code: {record.code}</Text>
                  <Text>{record.date}</Text>
                </Flex>
              </Box>
            ))}
          </Stack>
        </Container>
        <Footer />
      </Stack>

      {/* Modal for Adding New Gate Pass */}
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create New Gate Pass</ModalHeader>
          <ModalBody>
            <Stack spacing="4">
              <Select
                placeholder="Select Type"
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <option value="errand-boy">Errand Boy</option>
                <option value="guest">Guest</option>
              </Select>

              {type === "errand-boy" && (
                <Stack spacing="4">
                  <Input
                    placeholder="Errand Boy's Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <Input
                    placeholder="Items to take out"
                    value={items}
                    onChange={(e) => setItems(e.target.value)}
                  />
                </Stack>
              )}

              {type === "guest" && (
                <Stack spacing="4">
                  <Select
                    placeholder="Select Guest"
                    value={guestId}
                    onChange={(e) => setGuestId(e.target.value)}
                  >
                    {guests.map((guest) => (
                      <option key={guest.id} value={guest.id}>
                        {guest.name}
                      </option>
                    ))}
                  </Select>
                  <Input
                    placeholder="Items to take out"
                    value={items}
                    onChange={(e) => setItems(e.target.value)}
                  />
                </Stack>
              )}
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="teal"
              onClick={handleSubmit}
              isDisabled={!type || !items}
            >
              Submit
            </Button>
            <Button variant="ghost" onClick={onClose} ml="3">
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default GatePassRecords;
