import { errorNotifier, successNotifier } from '../../../components/NotificationHandler';
import http, { AUTH_ROUTES } from '../../../services/api';

export const deleteContact = async (userId, contactId, setRefresh) => {
  try {
    const {
      data: { result },
    } = await http.delete(AUTH_ROUTES.DELETE_CONTACT(userId, contactId));

    successNotifier(result?.message);
    setRefresh(true);
  } catch (e) {
    if (e?.response) {
      e.response?.data?.message ? errorNotifier(e.response?.data?.message) : errorNotifier(e.response?.data?.result?.message);
    } else {
      errorNotifier('Please Check your network connection!!!');
    }
  }
};
