import { Divider, Grid, GridItem, HStack, Stack, Text } from '@chakra-ui/react';
import { boldtext } from './inviteData';

const RandomSummary = ({ customVisitValues, selectedRandomDates }) => {


  return (
    <Stack fontWeight='500'>
      <Text fontSize='1.3rem' pb='5' fontWeight='500' textAlign={'center'}>
        Invitation summary
      </Text>
      <HStack>
        <Text w='110px'>Name: </Text>
        <Text {...boldtext}>{customVisitValues.name} </Text>
      </HStack>
      <Divider />
      <HStack>
        <Text w='110px'>Phone number: </Text>
        <Text {...boldtext}>{customVisitValues.phone} </Text>
      </HStack>
      <Divider />
      <HStack>
        <Text w='110px'>Days: </Text>
        {!selectedRandomDates.length ? (
          <Text>You have not Selected any day</Text>
        ) : (
          <Grid
            templateColumns='repeat(3, 1fr)'
            rowGap=' 10px'
            columnGap='0.5rem'
          >
            {selectedRandomDates.map((day, index) => (
              <GridItem
                {...boldtext}
                bg='bgColor'
                borderRadius='3px'
                px='5px'
                py='3px'
                key={index}
              >
                {day.calendarDate}
              </GridItem>
            ))}
          </Grid>
        )}
      </HStack>
      <Divider />
      <HStack>
        <Text w='110px'>Total days:</Text>
        <Text {...boldtext}>{selectedRandomDates.length}</Text>
      </HStack>
    </Stack>
  );
};

export default RandomSummary;
