import { Box, Center, Image, Stack, Text } from "@chakra-ui/react";
import Container from "../../../components/Container";
import Header from "../../../components/Header";
import { PROTECTED_PATHS } from "../../../app/constants";

const NotFound = ({ text, goto }) => {
  const { MARKET_PLACE } = PROTECTED_PATHS;

  return (
    <Stack>
      <Box shadow="sm">
        <Container>
          <Header goto={MARKET_PLACE} />
        </Container>
      </Box>
      <Container>
        <Center flexDirection="column" gap="2rem" h="80vh" w="100%">
          <Image src="/Empty.svg" alt="Empty" />
          <Text textAlign="center" color="primary" fontSize="1.5rem">
            {text || "Opps! No Items in this category"}
          </Text>
        </Center>
      </Container>
    </Stack>
  );
};

export default NotFound;

// import { Box, Center, Image, Slide, Text } from '@chakra-ui/react';
// import { PROTECTED_PATHS } from '../../../app/constants';
// import Container from '../../../components/Container';
// import Header from '../../../components/Header';

// const NotFound = ({ text, goto }) => {
//   const { MARKET_PLACE } = PROTECTED_PATHS;
//   return (
//     <Slide direction={'top'} in={true} style={{ zIndex: 10, h: '100vh' }}>
//       <Box shadow='sm'>
//         <Container>
//           <Header goto={goto || MARKET_PLACE} />
//         </Container>
//       </Box>
//       <Container h='90vh'>
//         <Center flexDirection='column' gap='2rem' h='80vh' w='100%'>
//           <Image src='/Empty.svg' alt='Empty' />
//           <Text textAlign='center' color='primary' fontSize='1.5rem'>
//             {text || 'Opps! No Items in this category'}
//           </Text>
//         </Center>
//       </Container>
//     </Slide>
//   );
// };

// export default NotFound;
