import { Box, Center, Input } from '@chakra-ui/react';
// import { useNavigate } from 'react-router-dom';
// import { PROTECTED_PATHS } from '../../../app/constants';
import Container from '../../../components/Container';
// import { PrimaryButton } from '../../../components/CustomButton';

export const ProcessingRequest = ({ children }) => {
  return (
    <Container>
      <Center
        w='100vw'
        position='fixed'
        top='0'
        left='0'
        bg='#FFFFFF'
        h='100vh'
        flexDirection='column'
        mx='auto'
        px='8'
      >
        <Box>
          <Input className='success' type='checkbox' id='check' />
          <label htmlFor='check' id='checker'>
            <div className='check-icon'></div>
          </label>
        </Box>
        <Box>{children}</Box>
        {/* <Text fontWeight='600' fontSize='1.4rem' pt='10px' textAlign='center'>
         {case}
        </Text>

        <Text py='15px' textAlign='center'>
          {message}
        </Text>

        <PrimaryButton
          mt='5'
          btnText='Got it'
          onClick={() => navigate(SPECIAL_GUEST_REQUEST)}
        /> */}
      </Center>
    </Container>
  );
};
