import { FormControl, FormLabel, Input, Stack, useColorModeValue } from "@chakra-ui/react";
import { inputBorder } from "../../../post advert/components/data";
import Select from "react-select";
import { COLORS } from "../../../../constants/colors";

export const BuyDataForm = ({
  dataPlans,
  setMobileNumber,
  mobileNumber,
  selectedOption,
  setSelectedOption,
}) => {
  const inputTextColor = useColorModeValue(COLORS.white, COLORS.grey);

  return (
    <FormControl>
      <Stack spacing="0">
        <FormLabel htmlFor="plan" m="0 !important" fontSize="14px">
          Select Plan
        </FormLabel>

        <Select
          bg={COLORS.lightBrown}
          _placeholder={{ opacity: 1, color: `${inputTextColor}` }}
          defaultValue={selectedOption}
          onChange={setSelectedOption}
          options={dataPlans}
        />
        <FormLabel
          htmlFor="phone-number"
          mb="0 !important"
          fontSize="14px"
          pt="5"
        >
          Phone Number
        </FormLabel>
        <Input
          {...inputBorder}
          bg={COLORS.lightBrown}
          _placeholder={{ opacity: 1, color: `${inputTextColor}` }}
          type="number"
          id="phone-number"
          value={mobileNumber}
          onChange={(e) => setMobileNumber(e.target.value)}
        />
        <FormLabel htmlFor="amount" mb="0" fontSize="14px" pt="5">
          Amount
        </FormLabel>
        <Input
          {...inputBorder}
          bg={COLORS.lightBrown}
          _placeholder={{ opacity: 1, color: `${inputTextColor}` }}
          readOnly
          defaultValue={
            selectedOption?.companyAmount + selectedOption.planAmount || ""
          }
          // onChange={() => null}
        />
      </Stack>
    </FormControl>
  );
};
