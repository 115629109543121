import http, { AUTH_ROUTES } from "../../../services/api";

export const getContractorList = async (
  userId,
  setLoading,
  setContractorList
) => {
  setLoading(true);
  try {
    const data = await http.get(AUTH_ROUTES.GET_CONTRACTOR_LIST(userId));
    console.log(data, "listttt")
    setContractorList(data?.data?.data);
    setLoading(false);
  } catch (error) {
    console.log("error", error.response);
    setLoading(false);
  }
};
