import {
  Box,
  Button,
  Flex,
  FormLabel,
  HStack,
  Image,
  Img,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { PROTECTED_PATHS } from '../../../app/constants';
import Container from '../../../components/Container';
import Header from '../../../components/Header';
import { AiOutlineSend } from 'react-icons/ai';
import { getChatMessages, getEstateInfo, sendChatMessage } from '../services/chatMessage';
import { useParams } from 'react-router-dom';
import { v4 } from 'uuid';
import FullPageLoader from '../../../components/FullPageLoader';
import { MdAttachFile } from 'react-icons/md';
import { UserProfile } from '../../../context/userProfileContext';
import { getIssueReportDetails } from '../services/getIssueReports';

import dayjs from 'dayjs';
const relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);

const { ISSUE_REPORTS_HISTORY } = PROTECTED_PATHS;

export const SendMessage = () => {
  const { userProfile } = UserProfile();
  const { _id } = userProfile;
  const { issuedID } = useParams();
  const [loading, setLoading] = useState(true);
  const [chatMessages, setChatMessages] = useState([]);
  const [chatImage, setChatImage] = useState({});
  const [typedMessage, setTypedMessage] = useState('');
  const [estateInfo, setEstateInfo] = useState({});
  const messageEndRef = useRef(null);
  const [refresh, setRefresh] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);
  const [reportDetails, setReportDetails] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    getEstateInfo(_id, setLoading, setEstateInfo);
    getIssueReportDetails(_id, issuedID, setReportDetails, setLoading);
  }, [issuedID, _id]);

  useEffect(() => {
    getChatMessages(issuedID, _id, setLoading, setChatMessages);
  }, [issuedID, _id, refresh]);

  const handleSendChatMessage = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('issueImage', chatImage.file || '');
    formData.append('issueId', issuedID);
    formData.append('message', typedMessage);
    formData.append('tenantId', _id);
    formData.append('managerId', estateInfo?.userId);
    formData.append('sender', 'tenants');

    sendChatMessage(formData, setBtnLoading, setRefresh).then(() => setTypedMessage(''));
    isOpen && onClose();
  };

  useEffect(() => {
    messageEndRef?.current?.scrollIntoView();
  }, [chatImage, chatMessages]);

  const handleImgUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = function (e) {
      setChatImage({ imgUrl: reader.result, file });
      onOpen();
    };
  };

  return loading ? (
    <FullPageLoader />
  ) : (
    <Stack>
      <Box shadow='xs'>
        <Container>
          <Header goto={ISSUE_REPORTS_HISTORY} />
        </Container>
      </Box>
      <Container>
        <HStack justify='space-between' py='2'>
          <Text fontSize='12px' py='2' px='4' borderRadius='full' border='1px solid #FEE8E7' bg='#FAF6FF'>
            {reportDetails?.issueType}
          </Text>
        </HStack>

        <Text borderBottom='1px solid #eee' pb='5' color='#614385' fontWeight='500' fontSize='1.2rem'>
          {reportDetails?.title}
        </Text>
        <Stack pt='5' spacing='10' h='60vh' overflow='scroll'>
          {/* <Text textAlign='center'>Today</Text> */}
          {!chatMessages.length ? (
            <VStack>
              <Image src='/Empty.svg' alt='Empty' />
              <Text>You have No Messages.</Text>
            </VStack>
          ) : (
            chatMessages.map((chat) => (
              <Flex key={v4()} pl={chat.sender !== 'tenants' && 5} pr={chat.sender === 'tenants' && 5}>
                {/* <Avatar order={chat.sender && 1} size='sm' name='Dan Abrahmov' src={profilePicture} /> */}
                <Stack w='100%' align={chat.sender === 'tenants' && 'flex-end'}>
                  {chat?.attachment ? <Img borderRadius='3px' w='65%' h='40vh' objectFit='cover' src={chat?.attachment} alt='...' /> : null}

                  {chat.message && (
                    <Text className={chat.sender !== 'tenants' ? 'speech-bubble' : 'tenant-speech-bubble'}>
                      {chat.message}
                      <Flex pt='2' justify='flex-end'>
                        <Text fontWeight='500' fontSize='12px'>
                          {dayjs().to(dayjs(chat?.createdAt))}
                        </Text>
                      </Flex>
                    </Text>
                  )}
                </Stack>
              </Flex>
            ))
          )}
          <div ref={messageEndRef} />
        </Stack>

        <HStack py='10'>
          <InputGroup>
            <Input
              autoFocus
              pl='10'
              _focus={{ border: 'none' }}
              placeholder=' Message'
              shadow='sm'
              value={typedMessage}
              onChange={(e) => setTypedMessage(e.target.value)}
            />
            <Input type='file' id='file' accept='image/png, image/gif, image/jpeg' display='none' onChange={handleImgUpload} />
            <FormLabel htmlFor='file'>
              <InputLeftElement cursor='pointer' children={<MdAttachFile />} />
            </FormLabel>
          </InputGroup>
          <Button
            isDisabled={!typedMessage || chatImage.file}
            _hover={{ bg: null }}
            isLoading={btnLoading}
            color='white'
            bg='primary'
            onClick={handleSendChatMessage}
          >
            <AiOutlineSend />
          </Button>
        </HStack>
      </Container>

      <Modal size='xs' isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Send media</ModalHeader>
          {/* <ModalCloseButton /> */}
          <ModalBody>
            <VStack spacing='10'>
              {chatImage.imgUrl && <Image w='100%' h='100%' src={chatImage.imgUrl} alt='...' />}
              <Input
                autoFocus
                border='2px solid #ddd'
                _focus={{ borderColor: '#ddd' }}
                placeholder='Message'
                shadow='sm'
                value={typedMessage}
                onChange={(e) => setTypedMessage(e.target.value)}
              />
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button variant='ghost' mr={3} onClick={onClose}>
              Close
            </Button>
            <Button isDisabled={!typedMessage || !chatImage.file} onClick={handleSendChatMessage} color='white' bg='primary'>
              Send
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};
