import { Box, Center, Img, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { v4 } from "uuid";
import { PROTECTED_PATHS } from "../../../app/constants";
import Container from "../../../components/Container";
import FullPageLoader from "../../../components/FullPageLoader";
import Header from "../../../components/Header";
import { AuthContext } from "../../../context/authContext";
import { getOffers } from "../service/offer";
import SearchBar from "./SearchBar";
import SingleOffer from "./SingleOffer";
import { COLORS } from "../../../constants/colors";

const AllOffers = () => {
  const appTextColor = useColorModeValue(COLORS.black, COLORS.white);
  const { userInfo } = useContext(AuthContext);
  const { userId } = userInfo;
  const [loading, setLoading] = useState(false);
  const [offers, setOffers] = useState([]);
  const { MARKET_PLACE } = PROTECTED_PATHS;
  const [input, setInput] = useState("");

  useEffect(() => {
    getOffers(userId, setLoading, setOffers);
  }, [userId]);

  return loading ? (
    <FullPageLoader />
  ) : (
    <Stack>
      <Box shadow="sm">
        <Container>
          <Header goto={MARKET_PLACE} />
        </Container>
      </Box>

      <Container>
        <Box py="5">
          <SearchBar input={input} setInput={setInput} />
        </Box>
        {offers?.length > 0 ? (
          <Stack spacing="5">
            {/* {offers?.offers?.map((offers, index) => (
            <SingleOffer key={v4()} {...offers} />
          ))} */}

            {offers?.offers
              ?.filter((item) => item.itemName.toLowerCase().includes(input))
              .map((offers) => (
                <SingleOffer key={v4()} {...offers} />
              ))}
          </Stack>
        ) : (
          <Center flexDirection="column" h="70vh" w="100%">
            <Img src="/empty.svg" alt="empty" />
            <Text
              py="5"
              fontWeight="500"
              textAlign="center"
              color={appTextColor}
            >
              No offers yet
            </Text>
          </Center>
        )}
      </Container>
    </Stack>
  );
};

export default AllOffers;
