import { PROTECTED_PATHS } from "../../../app/constants";
import {
  errorNotifier,
  successNotifier,
} from "../../../components/NotificationHandler";
import http, { AUTH_ROUTES } from "../../../services/api";

// export const loginTenant = async (payload, setLoading, setIsLoggedin, setNextPage) => {
export const loginTenant = async (
  payload,
  setLoading,
  setIsLoggedin,
  setNextPage,
  setData
) => {
  try {
    const { MY_ESTATE } = PROTECTED_PATHS;
    setLoading(true);
    const {
      data: { result },
    } = await http.post(AUTH_ROUTES.LOGIN, payload);

    const token = result?.data?.userData?.token;
    const userId = result?.data?.userData?.userId;
    const reset = result?.data?.tenant?.resetPassword;

    if (!result.success) return;

    localStorage.setItem("11p221#", token);

    setData(result);
    setLoading(false);
    // setNextPage(true)

    if (reset) {
      setIsLoggedin(true);
      localStorage.setItem("userId", userId);
      // navigate('/home');
      successNotifier(result?.message);
      window.location.href = MY_ESTATE;
    } else {
      setNextPage(true);
    }
    // setIsLoggedin(true);
    localStorage.setItem("old", true);
  } catch (e) {
    console.log(e?.message, "ERR2", e?.toJSON()?.message === "Network Error");
      setLoading(false);
  
    setIsLoggedin(false);
    if (e?.response) {
      e.response?.data?.message
        ? errorNotifier(e.response?.data?.message)
        : errorNotifier(e.response?.data?.result?.message);
    } else {
      errorNotifier("Please Check your network connection!!!");
    }
  }
};
