import {
  Box,
  Checkbox,
  HStack,
  Img,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  Grid,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Container from "../../../components/Container";
import Footer from "../../../components/footer";
import FullPageLoader from "../../../components/FullPageLoader";
import Header from "../../../components/Header";
import { AuthContext } from "../../../context/authContext";
import {
  getAdvertProductsByCategory,
  getSearchAdvertProductsInCategory,
} from "../service";
import OfferWithPrice from "./OfferWithPrice";
import { v4 } from "uuid";
import { BsSearch } from "react-icons/bs";
import filterIcon from "../../../assets/Layer 32.svg";
import useDebounce from "../../../components/hooks/useDebounce";
import NotFound from "./NotFound";
import { PROTECTED_PATHS } from "../../../app/constants";
import { COLORS } from "../../../constants/colors";

const CategoryProducts = () => {
  const { MARKET_PLACE } = PROTECTED_PATHS;
  const { userInfo } = useContext(AuthContext);
  const { userId } = userInfo;
  const { category } = useParams();
  const [loading, setLoading] = useState(false);
  const [allAdvertsInCategory, setAllAdvertsInCategory] = useState([]);
  const [searchKey, setSearchKey] = useState("itemName");
  const [searchValue, setSearchValue] = useState("");
  const convertedCategory = category.charAt().toUpperCase() + category.slice(1);

  const debouncedSearch = useDebounce(searchValue, 1000);
  console.log("CON", convertedCategory);
  useEffect(() => {
    getAdvertProductsByCategory(
      userId,
      setLoading,
      setAllAdvertsInCategory,
      convertedCategory
    );
  }, [userId, convertedCategory]);

  useEffect(() => {
    debouncedSearch &&
      getSearchAdvertProductsInCategory(
        userId,
        convertedCategory,
        searchKey,
        debouncedSearch,
        setAllAdvertsInCategory
      );
  }, [userId, convertedCategory, searchKey, debouncedSearch]);

  return (
    <>
      {loading && !allAdvertsInCategory?.length && <FullPageLoader />}

      {!loading && !allAdvertsInCategory?.length && <NotFound />}

      {!loading && allAdvertsInCategory?.length && (
        <Stack>
          <Box shadow="sm">
            <Container>
              <Header goto={MARKET_PLACE} />
            </Container>
          </Box>

          <Container>
            <Stack spacing={4}>
              <InputGroup
                rounded="full"
                border={`1px solid ${COLORS.green}`}
                mt={"30px"}
              >
                <InputLeftElement fontSize="1.2em" children={<BsSearch />} />
                <Input
                  type={searchKey === "price" ? "number" : "text"}
                  border="none"
                  placeholder={
                    searchKey === "itemName"
                      ? "search by name"
                      : "search by price"
                  }
                  value={searchValue}
                  // onChange={(e) => handleSearch(e.target.value)}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <InputRightElement>
                  <Menu closeOnSelect={false}>
                    <MenuButton cursor="pointer" as={Box}>
                      <Img src={filterIcon} color={COLORS.green} alt="filter" fontSize="1em" />
                    </MenuButton>
                    <MenuList w="100px" bg="gray.50">
                      <HStack>
                        <Text pb="2" pl="5%" fontWeight="600">
                          Search by:
                        </Text>
                        {/* <CloseButton /> */}
                      </HStack>
                      <MenuItem>
                        <Checkbox
                          isChecked={searchKey === "itemName"}
                          colorScheme={"blue"}
                          onChange={() => {
                            setSearchKey("itemName");
                            setSearchValue("");
                          }}
                          // onChange={() => handleSearchValue('itemName')}
                        >
                          Name
                        </Checkbox>
                      </MenuItem>
                      <MenuItem>
                        <Checkbox
                          isChecked={searchKey === "price"}
                          colorScheme={"blue"}
                          onChange={() => {
                            setSearchKey("price");
                            setSearchValue("");
                          }}
                          // onChange={() => handleSearchValue('price')}
                        >
                          Price
                        </Checkbox>
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </InputRightElement>
              </InputGroup>
            </Stack>
            <Stack pt={"50px"} pb={"100px"}>
              <Grid templateColumns="repeat(2, 1fr)" gap={8}>
                {allAdvertsInCategory?.map((advertInCategory) => (
                  <OfferWithPrice key={v4()} advert={advertInCategory} />
                ))}
              </Grid>
            </Stack>
          </Container>
          <Footer />
        </Stack>
      )}
    </>
  );
};

export default CategoryProducts;
