import { successNotifier, errorNotifier } from '../../../components/NotificationHandler';
import http, { AUTH_ROUTES } from '../../../services/api';

export const getTenantProfileSettings = async (userId, setIsLoading, setTenantProfileSettings) => {
  setIsLoading(true);
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_TENANT_PROFILE_SETTING(userId));

    setTenantProfileSettings(data);
  } catch (error) {
    console.log('kkkk', error.response);
  } finally {
    setIsLoading(false);
  }
};

export const editTenantProfile = async (userId, payload, setIsLoading, onClose) => {
  try {
    setIsLoading(true);
    const {
      data: {
        result: { data },
      },
    } = await http.put(AUTH_ROUTES.EDIT_TENANT_PROFILE(userId), payload);
    console.log(data);
    setIsLoading(false);
    successNotifier('Profile Successfully updated');
    onClose();
  } catch (error) {
    console.log(error.response);
    setIsLoading(false);
    if(error.response) {
      errorNotifier(error.resonse.result.message);
    } else {
      errorNotifier("Please Check your connection");
    }
  }
};

export const postTenantProfileSetting = async (payload, setIsLoading) => {
  setIsLoading(true);
  try {
    console.log('caller ayload', payload);
    const { data } = await http.post(AUTH_ROUTES.POST_TENANT_PROFILE_SETTING, payload);

    console.log('rrrr', data);
    successNotifier(data.result.message);
  } catch (err) {
    console.log(err.response);
  } finally {
    setIsLoading(false);
  }
};
