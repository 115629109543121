import {
  errorNotifier,
  // successNotifier,
} from '../../../components/NotificationHandler';

const props = ['name', 'tel'];
// const opts = {multiple: true};

export const pickContactFromPhone = async (setName, setPhone) => {
  try {
    const contacts = await navigator.contacts.select(props);
    if (!contacts.length) {
      errorNotifier('could not pick contact...Please try again');
      // Either no contacts were selected in the picker, or the picker could not be launched. Exposure of the API implies expected availability.
      return;
    }

    const contact = contacts[0];
    const contactNumber = contact.tel
      .filter((tel) => tel.length > 0)[0]
      .replace(/\s/g, '');
    const contactName = contact.name.filter((name) => name.length > 0)[0];
    setName(contactName);
    setPhone(contactNumber);

    //   handleResults(contacts);
  } catch (ex) {
    // Handle any errors here.
    // console.log('err', ex);
    // console.log('err', ex.response);
    // errorNotifier('Only supported on mobile Phones', ex);
  }
};

export const calculateDaysBetweenDates = (date1, date2) => {
  const oneDay = 24 * 60 * 60 * 1000;
  const date1ToJsTime = new Date(date1);
  const date2toJsTime = new Date(date2);
  const date1InMillis = date1ToJsTime.getTime();
  const date2InMillis = date2toJsTime.getTime();

  const days = Math.round(Math.abs(date2InMillis - date1InMillis) / oneDay);
  return days;
};
