import http, { AUTH_ROUTES } from '../../../services/api';

export const getUserProfile = async (userId, setIsLoading, setUserProfile) => {
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_USER_PROFILE(userId));

    setUserProfile(data?.user);
    setIsLoading(false);
  } catch (error) {
    console.log('lllll', error.response);
  } finally {
    setIsLoading(false);
  }
};
