import { PROTECTED_PATHS } from '../../../app/constants';
import { errorNotifier } from '../../../components/NotificationHandler';
import { timeout } from '../../../components/utils/timeout';

import http, { AUTH_ROUTES } from '../../../services/api';

export const purchaseAirtime = async (payload, setLoading, setSuccess, navigate) => {
  setLoading(true);
  const { MY_WALLET } = PROTECTED_PATHS;
  try {
    await http.post(AUTH_ROUTES.PURCHASE_AIRTIME, payload);

    setSuccess(true);
    timeout(() => navigate(MY_WALLET), 3000);
  } catch (e) {
    console.log(e.response);
    errorNotifier(e.response.data.result.data);
  } finally {
    setLoading(false);
  }
};
