import {
  Box,
  FormControl,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Select,
  Text,
  Textarea,
  HStack,
  Center,
  Input,
  useColorModeValue,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { BsAlarmFill } from "react-icons/bs";
import DatePicker from "react-multi-date-picker";
import { PROTECTED_PATHS } from "../../../app/constants";
import Container from "../../../components/Container";
import { PrimaryButton } from "../../../components/CustomButton";
import Header from "../../../components/Header";
import { ProcessingRequest } from "./ProcessingRequest";
import { inputBorder } from "../../post advert/components/data";
import dayjs from "dayjs";
import { useContext } from "react";
import { UserProfileContext } from "../../../context/userProfileContext";
import CustomeSpecialReqestModal from "./CustomeSpecialReqestModal";
import { PartyEventSummary } from "./PartyEventSummary";
import { v4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { getVenues } from "../service";
import FullPageLoader from "../../../components/FullPageLoader";
import { inputStyles } from "../../login/Login";
import { COLORS } from "../../../constants/colors";

// Import the ShareModal and ShareInviteLink components
import ShareModal from "../../../pages/invite-guest/components/ShareModal";
import ShareInviteLink from "../../../pages/invite-guest/components/ShareInviteLink";

const PartyEventRequestForm = () => {
  const cardBg = useColorModeValue(COLORS.primary, COLORS.white);
  const { userProfile } = useContext(UserProfileContext);
  const { email, _id, tenantCode } = userProfile;
  const { SPECIAL_GUEST_REQUEST } = PROTECTED_PATHS;
  const [success, setSuccess] = useState(false);
  const today = new Date();
  const [allowToOpen, setAllowToOpen] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [venues, setVenues] = useState([]);

  // State to handle the share modal
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [guestCode, setGuestCode] = useState(""); // Add guest code state

  useEffect(() => {
    getVenues(_id, setLoading, setVenues);
  }, [_id]);

  const initialValues = {
    email,
    userId: _id,
    tenantCode,
    numberOfGuests: 1,
    subType: "",
    address: "",
    dates: [],
    type: "event",
    description: "",
  };
  const [values, setValues] = useState(initialValues);

  const onChange = (e) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleNumberOfGuests = (number) => {
    setValues((prev) => ({ ...prev, numberOfGuests: number }));
  };

  const handleNumberOfDays = (date) => {
    const sortedDate = date.sort((a, b) => a.dayOfYear - b.dayOfYear);
    const formattedDates = sortedDate.map((d) => dayjs(d).format("YYYY-MM-DD"));
    setValues((prev) => ({ ...prev, dates: formattedDates }));
  };

  useEffect(() => {
    const { address, description, dates } = values;
    !address || !description || !dates.length
      ? setAllowToOpen(false)
      : setAllowToOpen(true);
  }, [values]);

  // Handlers for opening and closing the share modal
  //const onShareOpen = () => setIsShareModalOpen(true);
  //const onShareClose = () => setIsShareModalOpen(false);
  const onShareOpen = () => {
    console.log("Opening modal");
    setIsShareModalOpen(true);
  };
  
  const onShareClose = () => {
    console.log("Closing modal");
    setIsShareModalOpen(false);
  };
  

  const inputStyle = {
    bg: "white",
    rounded: "lg",
    color: "#262636",
  };

  return (
    <>
      {success && !loading && (
        <ProcessingRequest>
          <Text fontSize={"1.2rem"} textAlign="center" fontWeight="600">
            Request Sent successfully
          </Text>
          <PrimaryButton
            mt="10"
            btnText="Got it"
            onClick={() => {
              navigate(SPECIAL_GUEST_REQUEST);
              onShareOpen(); // Open the share modal after navigation
            }}
          />
          <button onClick={onShareOpen}>Open Share Modal</button>

        </ProcessingRequest>
      )}

      {!success && loading && <FullPageLoader />}

      {!success && !loading && (
        <Stack>
          <Box shadow="sm">
            <Container>
              <Header goto={SPECIAL_GUEST_REQUEST} />
            </Container>
          </Box>
          <Container>
            <FormControl minH="80vh">
              <Stack mb="20" spacing="5">
                <FormLabel color={cardBg}>
                  Title of Event
                  <Input {...inputStyle} name="subType" onChange={onChange} />
                </FormLabel>
                <HStack justify="space-between">
                  <FormLabel color={cardBg} pt="5px">
                    Number of guests
                    <NumberInput
                      {...inputStyle}
                      w="130px"
                      min={1}
                      value={values?.numberOfGuests}
                      onChange={handleNumberOfGuests}
                    >
                      <NumberInputField textAlign="center" placeholder="10" />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </FormLabel>
                  <Box>
                    <FormLabel m="0" color={cardBg}>
                      Date
                    </FormLabel>
                    <Center
                      {...inputStyle}
                      className="special-request-date-picker-wrapper"
                      position="relative"
                    >
                      <DatePicker
                        multiple="true"
                        placeholder={dayjs(today).format("DD-MMM-YYYY")}
                        minDate={today}
                        onChange={handleNumberOfDays}
                      />
                      <Box pos="absolute" right="15px">
                        <BsAlarmFill size="20px" />
                      </Box>
                    </Center>
                  </Box>
                </HStack>

                <FormLabel color={cardBg}>
                  Venue for Party
                  <Select
                    placeholder="Select the venue"
                    {...inputStyle}
                    color={COLORS.green}
                    value={values?.address}
                    name="address"
                    onChange={onChange}
                  >
                    {venues?.map((venue) => (
                      <option key={v4()}>{venue?.name}</option>
                    ))}
                  </Select>
                </FormLabel>
                <FormLabel color={cardBg}>
                  Reason/description for invite
                  <Textarea
                    {...inputStyle}
                    placeholder="Type here..."
                    resize="none"
                    rows="5"
                    name="description"
                    onChange={onChange}
                  />
                </FormLabel>
              </Stack>

              <CustomeSpecialReqestModal
                allowToOpen={allowToOpen}
                btnTitle={<PrimaryButton btnText="Send Request" />}
              >
                <PartyEventSummary values={values} setSuccess={setSuccess} />
              </CustomeSpecialReqestModal>
            </FormControl>
          </Container>
        </Stack>
      )}

      {/* Reuse ShareModal component */}
      <ShareModal
        size="xs"
        isOpen={isShareModalOpen}
        onOpen={onShareOpen}
        onClose={onShareClose}
      >
        <ShareInviteLink onClose={onShareClose} guestCode={guestCode} />
      </ShareModal>
    </>
  );
};

export default PartyEventRequestForm;

// import {
//   Box,
//   FormControl,
//   FormLabel,
//   NumberDecrementStepper,
//   NumberIncrementStepper,
//   NumberInput,
//   NumberInputField,
//   NumberInputStepper,
//   Stack,
//   Select,
//   Text,
//   Textarea,
//   HStack,
//   Center,
//   Input,
// } from '@chakra-ui/react';
// import { useEffect, useState } from 'react';
// import { BsAlarmFill } from 'react-icons/bs';
// import DatePicker from 'react-multi-date-picker';
// import { PROTECTED_PATHS } from '../../../app/constants';
// import Container from '../../../components/Container';
// import { PrimaryButton } from '../../../components/CustomButton';
// import Header from '../../../components/Header';
// import { ProcessingRequest } from './ProcessingRequest';
// import { inputBorder } from '../../post advert/components/data';
// import dayjs from 'dayjs';
// import { useContext } from 'react';
// import { UserProfileContext } from '../../../context/userProfileContext';
// import CustomeSpecialReqestModal from './CustomeSpecialReqestModal';
// import { PartyEventSummary } from './PartyEventSummary';
// import { v4 } from 'uuid';
// import { useNavigate } from 'react-router-dom';
// import { getVenues } from '../service';
// import FullPageLoader from '../../../components/FullPageLoader';

// const PartyEventRequestForm = () => {
//   const { userProfile } = useContext(UserProfileContext);
//   const { email, _id, tenantCode } = userProfile;
//   const { SPECIAL_GUEST_REQUEST } = PROTECTED_PATHS;
//   const [success, setSuccess] = useState(false);
//   const today = new Date();
//   const [allowToOpen, setAllowToOpen] = useState(false);
//   const navigate = useNavigate();
//   const [loading, setLoading] = useState(false);
//   const [venues, setVenues] = useState([]);

//   useEffect(() => {
//     getVenues(_id, setLoading, setVenues);
//   }, [_id]);

//   const initialValues = {
//     email,
//     userId: _id,
//     tenantCode,
//     numberOfGuests: 1,
//     subType: '',
//     address: '',
//     dates: [],
//     type: 'event',
//     description: '',
//   };
//   const [values, setValues] = useState(initialValues);

//   const onChange = (e) => {
//     setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
//   };

//   const handleNumberOfGuests = (number) => {
//     setValues((prev) => ({ ...prev, numberOfGuests: number }));
//   };

//   const handleNumberOfDays = (date) => {
//     const sortedDate = date.sort((a, b) => a.dayOfYear - b.dayOfYear);

//     const formatedDates = sortedDate.map((d) => dayjs(d).format('YYYY-MM-DD'));

//     setValues((prev) => ({ ...prev, dates: formatedDates }));
//   };

//   useEffect(() => {
//     const { address, description, dates } = values;

//     !address || !description || !dates.length
//       ? setAllowToOpen(false)
//       : setAllowToOpen(true);
//   }, [values]);

//   console.log('values', values);

//   return (
//     <>
//       {success && !loading && (
//         <ProcessingRequest>
//           <Text fontSize={'1.2rem'} textAlign='center' fontWeight='600'>
//             Request Sent successfully, kindly await the admin Approval
//           </Text>
//           <PrimaryButton
//             mt='10'
//             btnText='Got it'
//             onClick={() => navigate(SPECIAL_GUEST_REQUEST)}
//           />
//         </ProcessingRequest>
//       )}

//       {!success && loading && <FullPageLoader />}

//       {!success && !loading && (
//         <Stack>
//           <Box shadow='sm'>
//             <Container>
//               <Header goto={SPECIAL_GUEST_REQUEST} />
//             </Container>
//           </Box>
//           <Container>
//             <FormControl minH='80vh'>
//               <Stack mb='20' spacing='5'>
//                 <Text
//                   fontSize='1.3rem'
//                   fontWeight='600'
//                   py='4'
//                   textAlign='center'
//                 >
//                   Make a request from the estate management for your party/event
//                 </Text>
//                 <FormLabel>
//                   Title of Event
//                   <Input {...inputBorder} name='subType' onChange={onChange} />
//                 </FormLabel>
//                 <HStack justify='space-between'>
//                   <FormLabel>
//                     Total number of guest
//                     <NumberInput
//                       {...inputBorder}
//                       w='100px'
//                       min={1}
//                       value={values?.numberOfGuests}
//                       onChange={handleNumberOfGuests}
//                     >
//                       <NumberInputField textAlign='center' placeholder='10' />
//                       <NumberInputStepper>
//                         <NumberIncrementStepper />
//                         <NumberDecrementStepper />
//                       </NumberInputStepper>
//                     </NumberInput>
//                   </FormLabel>
//                   <Box>
//                     <FormLabel m='0'>Date</FormLabel>
//                     <Center
//                       {...inputBorder}
//                       className='special-request-date-picker-wrapper'
//                       position='relative'
//                     >
//                       <DatePicker
//                         multiple='true'
//                         placeholder={dayjs(today).format('DD-MMM-YYYY')}
//                         minDate={today}
//                         onChange={handleNumberOfDays}
//                       />
//                       <Box pos='absolute' right='15px'>
//                         <BsAlarmFill size='20px' />
//                       </Box>
//                     </Center>
//                   </Box>
//                 </HStack>

//                 <FormLabel>
//                   Venue for Party
//                   <Select
//                     placeholder='Select the venue'
//                     {...inputBorder}
//                     value={values?.address}
//                     name='address'
//                     onChange={onChange}
//                   >
//                     {venues?.map((venue) => (
//                       <option key={v4()}>{venue?.name}</option>
//                     ))}
//                   </Select>
//                 </FormLabel>
//                 <FormLabel>
//                   Reason/desription for invite
//                   <Textarea
//                     {...inputBorder}
//                     placeholder='Type here...'
//                     resize='none'
//                     rows='5'
//                     name='description'
//                     onChange={onChange}
//                   />
//                 </FormLabel>
//               </Stack>

//               <CustomeSpecialReqestModal
//                 allowToOpen={allowToOpen}
//                 btnTitle={<PrimaryButton btnText='Make Special Request' />}
//               >
//                 <PartyEventSummary values={values} setSuccess={setSuccess} />
//               </CustomeSpecialReqestModal>
//             </FormControl>
//           </Container>
//         </Stack>
//       )}
//     </>
//   );
// };

// export default PartyEventRequestForm;
