import {
  Box,
  Divider,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { BiDotsVerticalRounded } from "react-icons/bi";
import dayjs from "dayjs";
import { COLORS } from "../../../constants/colors";
import { DeleteModal } from "../../../components/DeleteModal";
import { DeletePartyModal } from "./DeletePartyModal";

export const PartyDetailsModal = ({props, forceUpdate}) => {
  const cardBg = useColorModeValue(COLORS.lightGrey, COLORS.white);
  const whiteCol = useColorModeValue(COLORS.primary, COLORS.lightGrey);
  const detailsCardBg = useColorModeValue(COLORS.white, COLORS.darkGrey);
      const menuColor = useColorModeValue(COLORS.black, COLORS.white);

  const { isOpen, onOpen, onClose } = useDisclosure();
  console.log(props, "ooo");

  return (
    <Menu>
      <MenuButton>
        <BiDotsVerticalRounded size="1.5rem" />
      </MenuButton>
      <MenuList>
        <Box>
          <MenuItem onClick={onOpen} color={menuColor}>
            View Details
          </MenuItem>
          <Modal size="sm" isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent>
              <ModalBody borderRadius=".8rem">
                <Box my="30px">
                  <Flex align="center">
                    <Stack pl="10px">
                      <Text color={cardBg} fontSize="lg">
                        {props.name}
                      </Text>
                      <Text color={whiteCol} fontSize="md">
                        {props.phone}
                      </Text>
                      <Flex>
                        <Text color="#FEE8E7" fontSize="x-small">
                          Created:
                        </Text>
                        <Text color="#B7B6B6" fontSize="x-small" pl="5px">
                          {dayjs(props.createdAt).format("DD/MMM/YYYY")}
                        </Text>
                        <Text color="#FEE8E7" fontSize="x-small" pl="5px">
                          Updated
                        </Text>
                        <Text color="#B7B6B6" fontSize="x-small" pl="5px">
                          {dayjs(props.updateAt).format("DD/MMM/YYYY")}
                        </Text>
                      </Flex>
                      <Divider orientation="horizontal" color="#FAF6FF" />
                    </Stack>
                  </Flex>
                  <Divider />
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap="1rem"
                    bg={detailsCardBg}
                    p="5"
                    borderRadius="lg"
                    my="40px"
                    color={cardBg}
                  >
                    <Flex width="100%" justify="space-between">
                      <Text fontSize="sm">Number of Guests</Text>
                      <Text color={whiteCol} fontSize="md">
                        {props?.props?.numberOfGuests}
                      </Text>
                    </Flex>
                    <Flex width="100%" justify="space-between">
                      <Text fontSize="sm">Date</Text>
                      <Text color={whiteCol} fontSize="md">
                        {dayjs(props?.props?.dates[0]).format("DD/MM/YYYY")}
                      </Text>
                    </Flex>
                    <Flex width="100%" justify="space-between">
                      <Text fontSize="sm">Address</Text>
                      <Text color={whiteCol} fontSize="md" maxW={"60%"}>
                        {props?.props?.address}
                      </Text>
                    </Flex>
                    <Flex width="100%" justify="space-between">
                      <Text fontSize="sm">Description</Text>
                      <Text color={whiteCol} fontSize="md" maxW={"60%"}>
                        {props?.props?.description}
                      </Text>
                    </Flex>

                    <Flex justify="space-between" align="center">
                      <Text fontSize="sm">Guest Code</Text>
                      <Text color={whiteCol} fontSize="md">
                        {props?.props?.inviteCode || "--"}
                      </Text>
                    </Flex>
                    {/* <Flex
                      justify="space-between"
                      align="center"
                      color={whiteCol}
                    >
                      <Text>One Time Visit</Text>
                      {props.oneTimeVisit ? (
                        <Text>Yes</Text>
                      ) : (
                        <Text color={whiteCol}>No</Text>
                      )}
                    </Flex>

                    <Flex
                      justify="space-between"
                      align="center"
                      color={whiteCol}
                    >
                      <Text>Allow Company</Text>
                      {props.allowCompany ? (
                        <Text>Yes</Text>
                      ) : (
                        <Text color={whiteCol}>No</Text>
                      )}
                      </Flex>*/}
                  </Box>
                </Box>
              </ModalBody>
            </ModalContent>
          </Modal>
        </Box>
        <DeletePartyModal
          props={props}
          forceUpdate={forceUpdate}
          onClose={onClose}
        />
      </MenuList>
    </Menu>
  );
};
