import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const Chat = () => {
  const location = useLocation();
  const externalLink = location.state?.externalLink;

  useEffect(() => {
    if (externalLink) {
      window.location.href = externalLink;
    }
  }, [externalLink]);

  return null;
};

export default Chat;
