import { PROTECTED_PATHS } from '../../../app/constants';
import { errorNotifier } from '../../../components/NotificationHandler';
import { timeout } from '../../../components/utils/timeout';
import http, { AUTH_ROUTES } from '../../../services/api';

export const getOffers = async (userId, setLoading, setOffers) => {
  setLoading(true);
  try {
    const {
      data: { result },
    } = await http.get(AUTH_ROUTES.GET_OFFERS(userId));

    setOffers(result?.data?.offers);
  } catch (err) {
    console.log(err.response);
  } finally {
    setLoading(false);
  }
};

// GET_SINGLE_OFFER_DETAILS
export const getSingleOfferDetails = async (userId, offerId, setLoading, setSingleOfferDetails) => {
  setLoading(true);

  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_SINGLE_OFFER_DETAILS(userId, offerId));

    setSingleOfferDetails(data?.offers);
  } catch (e) {
    console.log(e.reponse);
  } finally {
    setLoading(false);
  }
};

export const joinOffer = async (userId, offerId, setLoading, setSuccess, navigate) => {
  const { MARKET_PLACE } = PROTECTED_PATHS;
  setLoading(true);
  try {
    await http.post(AUTH_ROUTES.JOIN_OFFER(userId, offerId), { userId });

    setSuccess(true);
    timeout(() => navigate(MARKET_PLACE), 2000);
  } catch (error) {
    errorNotifier(error?.response?.data.result.message);
  } finally {
    setLoading(false);
  }
};
