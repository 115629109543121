import { Box, Img, Text, useColorModeValue } from "@chakra-ui/react";
import emergency from "../../assets/Group 62.svg";
import CustomModal from "../../components/CustomModal";
import EmergencyModal from "./components/EmergencyModal";
import { COLORS } from "../../constants/colors";

const Emergency = () => {
  return (
    <Box>
      <CustomModal buttonProps={{ ...btnProps }} btnTitle={<EmergencyIcon />}>
        <EmergencyModal />
      </CustomModal>
    </Box>
  );
};

export default Emergency;

const btnProps = { bg: "transparent", p: "1" };

const EmergencyIcon = () => {
  const emergencyBg = useColorModeValue(COLORS.primary, COLORS.white);

  return (
    <Box mb="1.5rem">
      <Img
        pos="relative"
        bottom="-4"
        left="1"
        src={emergency}
        alt="emergency"
      />

      <Text
        m="0 !important"
        fontSize="10px"
        color={emergencyBg}
        position="absolute"
      >
        Emergency
      </Text>
    </Box>
  );
};
