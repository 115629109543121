import { Flex, HStack, Box, Text, useColorModeValue } from "@chakra-ui/react";
import { v4 as id } from "uuid";
import { NavLink } from "react-router-dom";
import Container from "../Container";
import { footerNav } from "./components/footerLinks";
import { useLocation } from "react-router-dom";
import { COLORS } from "../../constants/colors";

const Footer = () => {
  const { pathname } = useLocation();
  const pageBg = useColorModeValue(COLORS.bgGrey, COLORS.darkModeBg);
  const appTextColor = useColorModeValue(COLORS.primary, COLORS.lightGrey);

  return (
    <Box
      pos="fixed"
      bottom="0"
      bg={pageBg}
      overflowX="hidden"
      color={appTextColor}
    >
      <Container py="2">
        <HStack
          py="6px"
          w="100%"
          justify="space-around"
          className="footer-wrapper"
        >
          {footerNav.map((nav) => (
            <Flex direction="column" justify="center" align="center" key={id()}>
              <NavLink
                className="bottom-nav-link"
                to={nav?.to ? nav.to : "my-estate"}
              >
                <Box>{pathname === nav.to ? nav.activeIcon : nav.icon}</Box>
              </NavLink>

              <Text>{nav.title}</Text>
            </Flex>
          ))}
        </HStack>
      </Container>
    </Box>
  );
};

export default Footer;
