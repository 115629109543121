import http, { AUTH_ROUTES } from '../../../services/api';

export const getGuests = async (userId, setLoading, setGuests) => {
  setLoading(true);
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_GUESTS({ userId }));

    setGuests(data.guests);
    setLoading(false);
  } catch (error) {
    setLoading(false);
  }
};

export const getContactList = async (userId, setLoading, setContactList) => {
  setLoading(true);
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_CONTACT_LIST(userId));

    setContactList(data.contacts);
    setLoading(false);
  } catch (error) {
    setLoading(false);
  }
};
