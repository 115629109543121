import { Box } from '@chakra-ui/react';

const Container = ({ children, ...props }) => {
  return (
    <Box maxW='460px' px={['5%', '10%']} w='100vw' mx='auto' {...props}>
      {children}
    </Box>
  );
};

export default Container;
