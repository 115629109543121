import { Grid, Img } from "@chakra-ui/react";

const SplashScreen = () => {
  return (
    <Grid w="100vw" h="100vh" placeContent="center">
      <Img src="/adesanya.png" alt="Abraham Adesanya" />
    </Grid>
  );
};

export default SplashScreen;
