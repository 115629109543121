import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftAddon,
  Stack,
  Text,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";
import { GiTakeMyMoney } from "react-icons/gi";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/authContext";
import {
  getEstateDue,
  getEstateInfo,
  initializePayment,
  verifyEstateDuePayment,
} from "./service";
import { UserProfileContext } from "../../context/userProfileContext";
import { infoNotifier } from "../../components/NotificationHandler";
import dayjs from "dayjs";
import FullPageLoader from "../../components/FullPageLoader";
import { PROTECTED_PATHS } from "../../app/constants";
import { dueStyles, monthsAheadStyles } from "./components/greet";
import Container from "../../components/Container";
import Header from "../../components/Header";
import { Success } from "../../components/Success";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "../../components/CustomButton";
import { COLORS } from "../../constants/colors";

export const EstateDue = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { userProfile } = useContext(UserProfileContext);
  const { tenantName, email } = userProfile;
  const { userInfo } = useContext(AuthContext);
  const { userId } = userInfo;
  const [estateDue, setEstateDue] = useState([]);
  const [estateInfo, setEstateInfo] = useState([]);
  const [monthsAhead, setMonthsAhead] = useState(1);
  const [paymentData, setPaymentData] = useState({});
  const [paymentModal, setPaymentModal] = useState(false);
  const [transactionCharge, setTransactionCharge] = useState(0);
  const [success] = useState(false);
  const navigate = useNavigate();

  const { MY_WALLET } = PROTECTED_PATHS;

  const nextDueDate = estateDue[0]?.endDate;
  const getEstateDueAmount = estateInfo?.estateDue * monthsAhead;

  useEffect(() => {
    // const rate = 0.015;
    const fixedCharge = 100;
    const charges = 0.015 * getEstateDueAmount;
    if (getEstateDueAmount < 2000) {
      setTransactionCharge(charges);
      return;
    } else if (charges >= 2000) {
      setTransactionCharge(2000 + fixedCharge);
      return;
    } else {
      setTransactionCharge(charges + fixedCharge);
    }
  }, [getEstateDueAmount]);

  useEffect(() => {
    getEstateDue(userId, setIsLoading, setEstateDue);
    getEstateInfo(userId, setIsLoading, setEstateInfo);
  }, [userId]);

  const myDate = new Date();
  const currentHour = myDate?.getHours();

  let msg;

  if (currentHour < 12) msg = "Good Morning";
  else if (currentHour >= 12 && currentHour <= 17) msg = "Good Afternoon";
  else if (currentHour >= 17 && currentHour <= 24) msg = "Good Evening";

  const payEstateDue = () => {
    const payload = {
      amount: getEstateDueAmount + transactionCharge,
      tenantName,
      email,
      userId,
      duration: monthsAhead,
    };

    if (!payload.email) {
      infoNotifier("please set your profile and add your Email first ");
      return;
    }

    initializePayment(payload, setIsLoading, setPaymentData, setPaymentModal);
  };

  const verifyPayments = () => {
    const payload = { userId, reference: paymentData?.reference };

    verifyEstateDuePayment(payload, navigate);
  };

  return (
    <>
      {isLoading && !success && <FullPageLoader />}

      {!isLoading && success && <Success />}

      {!isLoading && !success && (
        <Box maxW="460px" mx="auto" w="100vw" h="100vh">
          <Box shadow="xs">
            <Container>
              <Header goto={MY_WALLET} />
            </Container>
          </Box>

          <Stack spacing="5" className="estate-due-card" bg={COLORS.green}>
            <Text color="#FFFFFF" fontWeight="light" fontSize="lg">
              {msg}
            </Text>

            <Text
              color="#FFFFFF"
              m="0 !important"
              fontWeight={"600"}
              fontSize="3xl"
            >
              {tenantName}
            </Text>

            <Flex {...dueStyles}>
              <Text color="#3F4860">Estate due date</Text>
              <Text color="#3F4860">
                {dayjs(nextDueDate)?.format("DD-MMMM-YYYY")}
              </Text>
            </Flex>
            <Flex {...dueStyles}>
              <Text>Estate due/Month</Text>
              <Text fontWeight="500">&#8358; {estateInfo?.estateDue}</Text>
            </Flex>
          </Stack>

          <Stack spacing="5" m="20px" pt="4">
            <Flex {...monthsAheadStyles} bg={COLORS.green}>
              <Text fontSize="small" color="white">
                Pay months ahead
              </Text>

              <NumberInput
                value={monthsAhead}
                size="sm"
                max={12}
                min={1}
                onChange={(_, getMonthAheadInInteger) =>
                  setMonthsAhead(getMonthAheadInInteger)
                }
                clampValueOnBlur={false}
                inputMode={"numeric"}
                bg="gray.100"
                fontSize="1.2rem"
                fontWeight="bold"
                textAlign="center"
                w="5rem"
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </Flex>
            <Flex {...monthsAheadStyles} bg={COLORS.green}>
              <Text fontSize="small" color="white">
                Amount
              </Text>

              <Stack w="130px" size="2xs" variant="outline">
                <InputGroup size="sm">
                  <InputLeftAddon
                    size="2xs"
                    children={<GiTakeMyMoney color="#FFFFFF" size="1.5em" />}
                    bg="#F98B88"
                  />
                  <Input
                    type="number"
                    placeholder="3000"
                    color="white"
                    bg={"white"}
                    fontSize={"18px"}
                    readOnly
                    value={
                      monthsAhead <= 1
                        ? estateInfo?.estateDue
                        : getEstateDueAmount
                    }
                  />
                </InputGroup>
              </Stack>
            </Flex>

            <Box pt="5vh">
              <PrimaryButton
                bg="primaryDark"
                btnText="Pay Estate Due"
                isLoading={isLoading}
                onClick={payEstateDue}
              />
            </Box>
          </Stack>

          <Modal
            isOpen={paymentModal}
            onClose={verifyPayments}
            isCentered
            bg="red"
            size="sm"
          >
            <ModalOverlay />
            <ModalContent bg={"white"}>
              <ModalHeader>Payment checkout</ModalHeader>
              <ModalCloseButton />
              <Stack pb="4" pt="2" pl="7%" fontWeight="500">
                <HStack fontWeight={600} fontSize="12px">
                  <Text pr="2">Estate Due: &#8358; {(getEstateDueAmount)?.toLocaleString()}</Text>
                  <Text>Paystack Charge: &#8358; {(transactionCharge)?.toLocaleString()} </Text>
                </HStack>
                <Text fontWeight={600} fontSize="12px">
                  Total: &#8358; {(transactionCharge + getEstateDueAmount)?.toLocaleString()}
                </Text>
              </Stack>
              <ModalBody p="0">
                <iframe
                  src={paymentData?.authorization_url}
                  width="100%"
                  height="450"
                  title="payment checkout"
                ></iframe>
              </ModalBody>
            </ModalContent>
          </Modal>
        </Box>
      )}
    </>
  );
};
