import {
  Box,
  HStack,
  Img,
  Stack,
  Text,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { useState } from "react";
import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PROTECTED_PATHS } from "../../../app/constants";
import Container from "../../../components/Container";
import Footer from "../../../components/footer";
import FullPageLoader from "../../../components/FullPageLoader";
import Header from "../../../components/Header";
import { dynamicPathhandler } from "../../../components/utils/dynamicPathHandler";
import { AuthContext } from "../../../context/authContext";
import { getIssueReportDetails } from "../services/getIssueReports";
import { COLORS } from "../../../constants/colors";

const ViewReportDetails = () => {
  const {
    userInfo: { userId },
  } = useContext(AuthContext);
  const cardBg = useColorModeValue(COLORS.primary, COLORS.white);
  const { reportId } = useParams();
  const [reportDeatils, setReportDeatils] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { ISSUE_REPORT, SEND_MESSAGE_TO_ADMIN } = PROTECTED_PATHS;
  const messageParent = dynamicPathhandler(SEND_MESSAGE_TO_ADMIN);

  useEffect(() => {
    getIssueReportDetails(userId, reportId, setReportDeatils, setLoading);
  }, [userId, reportId]);

  return loading ? (
    <FullPageLoader />
  ) : (
    <Box minH="100vh" pb="10vh">
      <Box shadow="sm">
        <Container>
          <Header goto={ISSUE_REPORT} />
        </Container>
      </Box>

      <Container>
        <Stack mt="20px" spacing="4">
          <HStack justify="space-between">
            <Text color={cardBg} fontSize={"18px"} fontWeight="600">
              {reportDeatils.issueType}
            </Text>
            <Text
              p={"5px "}
              bg="white"
              border={`1px solid ${COLORS.green}`}
              textTransform="uppercase"
              rounded="2xl"
              color={COLORS.green}
              fontSize={"16px"}
              fontWeight="600"
            >
              {reportDeatils.status}
            </Text>
          </HStack>

          <Text pt="30px" fontSize="18px" color={cardBg}>
            {reportDeatils.description}
          </Text>

          <VStack borderRadius="5px" mt="30px" spacing={8}>
            {reportDeatils?.media?.slice(0, 3).map((media) => (
              <Img
                minW="100%"
                maxW="100%"
                h="250px"
                objectFit="cover"
                borderRadius="5px"
                src={media}
                alt={reportDeatils.issueType}
              />
            ))}
          </VStack>
        </Stack>
      </Container>
      <Footer />
    </Box>
  );
};

export default ViewReportDetails;
// import { Box, Flex, HStack, Img, Stack, Text, VStack, useColorModeValue } from '@chakra-ui/react';
// import { useState } from 'react';
// import { useContext, useEffect } from 'react';
// import { useNavigate, useParams } from 'react-router-dom';
// import { PROTECTED_PATHS } from '../../../app/constants';
// import Container from '../../../components/Container';
// import { PrimaryButton } from '../../../components/CustomButton';
// import Footer from '../../../components/footer';
// import FullPageLoader from '../../../components/FullPageLoader';
// import Header from '../../../components/Header';
// import { dynamicPathhandler } from '../../../components/utils/dynamicPathHandler';
// import { AuthContext } from '../../../context/authContext';
// import { getIssueReportDetails } from '../services/getIssueReports';
// import { COLORS } from '../../../constants/colors';

// const ViewReportDetails = () => {
//   const {
//     userInfo: { userId },
//   } = useContext(AuthContext);
//     const cardBg = useColorModeValue(COLORS.primary, COLORS.white);
//   const { reportId } = useParams();
//   const [reportDeatils, setReportDeatils] = useState({});
//   const [loading, setLoading] = useState(false);
//   const navigate = useNavigate();
//   const { ISSUE_REPORTS_HISTORY, SEND_MESSAGE_TO_ADMIN } = PROTECTED_PATHS;
//   const messageParent = dynamicPathhandler(SEND_MESSAGE_TO_ADMIN);

//   useEffect(() => {
//     getIssueReportDetails(userId, reportId, setReportDeatils, setLoading);
//   }, [userId, reportId]);

//   return loading ? (
//     <FullPageLoader />
//   ) : (
//     <Box minH='100vh' pb='10vh'>
//       <Box shadow='sm'>
//         <Container>
//           <Header menu />
//         </Container>
//       </Box>

//       <Container>
//         <Stack mt='5' spacing='4'>
//           <HStack justify='space-between'>
//             <Text border='1px solid #FC7067' borderRadius='20px' bg='#FAF6FF' px='2' w='180px' py='1' fontSize={'16px'} fontWeight='600'>
//               {reportDeatils.issueType}
//             </Text>
//             <PrimaryButton onClick={() => navigate(`${messageParent}/${reportDeatils._id}`)} size='sm' btnText='Send message' w='120px' />
//           </HStack>
//           <Text borderBottom='1px solid #F4F0F0' fontWeight='600' fontSize='1.2rem'>
//             {reportDeatils.title}
//           </Text>

//           <Text py='3'> {reportDeatils.description}</Text>

//           <VStack borderRadius='5px' spacing='8'>
//             {reportDeatils?.media?.slice(0, 3).map((media) => (
//               <Img minW='80%' maxW='100%' h='200px' objectFit='cover' borderRadius='5px' src={media} alt={reportDeatils.issueType} />
//             ))}
//           </VStack>
//         </Stack>
//         <Flex justify='center' pt='8vh'>
//           <PrimaryButton w='80%' btnText='Go back to report history' onClick={() => navigate(ISSUE_REPORTS_HISTORY)} />
//         </Flex>
//       </Container>
//       <Footer />
//     </Box>
//   );
// };

// export default ViewReportDetails;
