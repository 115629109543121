import { Box, Flex, Stack } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PROTECTED_PATHS } from '../../../app/constants';
import Container from '../../../components/Container';
import { PrimaryButton } from '../../../components/CustomButton';
import Header from '../../../components/Header';
import ErrandTabs from './ErrandTabs';
import ErrandCard from './ErrandCard';
import { getErrands } from '../services/getErrands';
import { AuthContext } from '../../../context/authContext';
import FullPageLoader from '../../../components/FullPageLoader';
import { UserProfileContext } from '../../../context/userProfileContext';

const ErrandHome = () => {
  const navigate = useNavigate();
  const { userProfile } = useContext(UserProfileContext);
  const { currentErrandPrice } = userProfile;
  const { SELECTED_ERRANDS } = PROTECTED_PATHS;
  const [errands, setErrands] = useState([]);
  const [loading, setLoading] = useState(false);
  const {
    userInfo: { userId },
  } = useContext(AuthContext);

  useEffect(() => {
    getErrands(userId, setErrands, setLoading);
  }, [userId]);

  return loading ? (
    <FullPageLoader />
  ) : (
    <Stack>
      <Box shadow='sm'>
        <Container>
          <Header menu />
        </Container>
      </Box>
      <Container minH='calc(100vh - 100px)'>
        <ErrandCard currentErrandPrice={currentErrandPrice} />
        <Box minH='35vh'>
          <ErrandTabs errands={errands} />
        </Box>
        <Flex justify='center'>
          <PrimaryButton my='8' w='80%' maxW='320px' btnText='Send errand' onClick={() => navigate(SELECTED_ERRANDS)} />
        </Flex>
      </Container>
    </Stack>
  );
};

export default ErrandHome;
