import accessControl from '../../../assets/Group 697.svg';
import marketPlace from '../../../assets/Group 735.svg';
import errandBoy from '../../../assets/Group 734.svg';

export const images = [accessControl, marketPlace, errandBoy];

export const heading = ['Access Control', 'Buy & Sell', 'All in One'];

export const message = [
  'Invite guests without clearing them at the gate all the time',
  'Buy items you need and sell items to your community easily',
  'All in one solution for your community needs',
];
