import { PROTECTED_PATHS } from '../../../app/constants';
import { BiWallet } from 'react-icons/bi';
import { RiCameraLensLine } from 'react-icons/ri';
import { HiHome, HiOutlineHome } from 'react-icons/hi';
// import { AiFillShopping } from 'react-icons/ai';
import { MdStoreMallDirectory, MdOutlineStore, MdCamera } from 'react-icons/md';
import { FaWallet } from 'react-icons/fa';

const { MY_ESTATE, MARKET_PLACE, MY_WALLET, ISSUE_REPORT } = PROTECTED_PATHS;

export const footerNav = [
  {
    to: MY_ESTATE,
    icon: <HiOutlineHome size={25} />,
    activeIcon: <HiHome size={25} />,
    title: 'Estate',
  },
  // {
  //   to: MY_ERRANDS,
  //   icon: <BiShoppingBag size={25} />,
  //   activeIcon: <AiFillShopping size={25} />,
  //   title: 'Errands',
  // },
  {
    to: MARKET_PLACE,
    icon: <MdOutlineStore size={25} />,
    activeIcon: <MdStoreMallDirectory size={25} />,
    title: 'Market',
  },
  {
    to: MY_WALLET,
    icon: <BiWallet size={25} />,
    activeIcon: <FaWallet size={25} />,
    title: 'Bills',
  },
  {
    to: ISSUE_REPORT,
    icon: <RiCameraLensLine size={25} />,
    activeIcon: <MdCamera size={25} />,
    title: 'Reports',
  },
];
