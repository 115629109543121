import { Box, Flex, Img, SlideFade, Stack, Text } from '@chakra-ui/react';
import { useState } from 'react';
import Container from '../../../components/Container';
import { PrimaryButton, SecondaryButton } from '../../../components/CustomButton';
import { useNavigate } from 'react-router-dom';
import Dots from './Dots';
import { heading, images, message } from './introScreenData';
import { PUBLIC_PATHS } from '../../../app/constants';
import { useEffect } from 'react';

const AfterSplashScreen = () => {
  const { LOGIN } = PUBLIC_PATHS;
  const navigate = useNavigate();
  const [screen, setScreen] = useState(1);

  const handleNext = () => {
    if (screen >= 3) {
      navigate(LOGIN);
      return;
    }
    setScreen((prev) => prev + 1);
  };

  useEffect(() => {}, [screen]);

  return (
    <SlideFade in={true} offsetX='5000px'>
      <Flex h='100vh' direction='column' jusify='center' bg='gray.50'>
        <Box w='100%' maxW='400px' h={['100%']} pt='5'>
          <Img w='100%' h={['100%']} src={images[screen - 1]} alt='photo' />
        </Box>

        <Stack bg='#fff' py={['7', '10']} justifySelf='flex-end' textAlign='center'>
          <Container px='5%'>
            <Dots screen={screen} />
            <Text pt='5' fontWeight='600' fontSize='1.3rem'>
              {heading[screen - 1]}
            </Text>

            <Text py='2' fontWeight='500'>
              {message[screen - 1]}
            </Text>
            <Flex align='center' direction='column'>
              <PrimaryButton my='5' maxW='380px' btnText={screen < 3 ? 'Next' : 'Get Started'} onClick={handleNext} />
              <SecondaryButton btnText='Skip' color='#111' _hover='none' w='80px' bg='none' onClick={() => navigate(LOGIN)} />
            </Flex>
          </Container>
        </Stack>
      </Flex>
    </SlideFade>
  );
};

export default AfterSplashScreen;
