import React, { useState } from 'react';
import { timeout } from '../../components/utils/timeout';
import AfterSplashScreen from './components/AfterSplashScreen';
import SplashScreen from './components/SplashScreen';

const InTroScreen = () => {
  const [afterSplashScreen, setAfterSplashScreen] = useState(false);

  timeout(() => setAfterSplashScreen(true), 2000);

  return <>{!afterSplashScreen ? <SplashScreen /> : <AfterSplashScreen />}</>;
};

export default InTroScreen;
