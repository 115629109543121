import { Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { COLORS } from "../constants/colors";

const HeadSeemore = ({ to, head, seemore, color }) => {
  const cardBg = useColorModeValue(COLORS.primary, COLORS.white);
  const bgColor = useColorModeValue(COLORS.primary, COLORS.green);

  return (
    <Flex justify="space-between" color="#3C1A5B" align="center" pb="1" >
      <Text fontSize="16px" color={cardBg}>
        {head}
      </Text>
      {seemore && (
        <Link to={to}>
          <Text
            _hover={{ decoration: "underline" }}
            // color={bgColor}
            color={cardBg}
            fontSize="12px"
            cursor="pointer"
          >
            See more
          </Text>
        </Link>
      )}
    </Flex>
  );
};

export default HeadSeemore;
