import {
  Box,
  Flex,
  Switch,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
// import { BsBellFill } from "react-icons/bs";
import { PrimaryButton } from "../../../components/CustomButton";
import FullPageLoader from "../../../components/FullPageLoader";
import { UserProfile } from "../../../context/userProfileContext";
import { getTenantProfileSettings, postTenantProfileSetting } from "../service";
import { COLORS } from "../../../constants/colors";

const Notifications = () => {
  const cardBg = useColorModeValue(COLORS.primary, COLORS.white);

  const { userProfile } = UserProfile();
  const { _id, tenantCode } = userProfile;
  const [isLoading, setIsLoading] = useState(false);
  const [tenantProfileSettings, setTenantProfileSettings] = useState({});
  const [invite, setInvite] = useState(tenantProfileSettings?.invite);
  const [errand, setErrand] = useState(tenantProfileSettings?.errand ?? false);
  const [wallet, setWallet] = useState(tenantProfileSettings?.wallet);
  const [market, setMarket] = useState(tenantProfileSettings?.market);
  const [advert, setAdvert] = useState(tenantProfileSettings?.advert);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    getTenantProfileSettings(_id, setIsLoading, setTenantProfileSettings);
  }, [_id, refresh]);

  useEffect(() => {
    const { invite, errand, wallet, market, advert } = tenantProfileSettings;
    setInvite(invite ?? false);
    setErrand(errand ?? false);
    setWallet(wallet ?? false);
    setMarket(market ?? false);
    setAdvert(advert ?? false);
  }, [tenantProfileSettings]);

  const handleSave = () => {
    const payload = {
      userId: _id,
      tenantCode,
      invite,
      errand,
      market,
      wallet,
      advert,
    };

    postTenantProfileSetting(payload, setIsLoading).then(() =>
      setRefresh(!refresh)
    );
  };
  return isLoading ? (
    <FullPageLoader />
  ) : (
    <Box mt="15px" color={cardBg}>
      <Text fontWeight="bold" fontSize="20px">
        Notifications
      </Text>
      <Flex py="5px" justify="space-between" align="center">
        <Text pt="10px">Invite</Text>
        <Switch
          size="md"
          name="invite"
          id="invite"
          onChange={() => {
            setInvite((invite) => !invite);       
          }}
          defaultChecked={tenantProfileSettings?.invite}
          value={tenantProfileSettings?.invite}
        />
      </Flex>

      <Flex py="1" justify="space-between" align="center">
        <Text pt="10px">E wallet</Text>
        <Switch
          size="md"
          name="wallet"
          onChange={() => setWallet(!wallet)}
          defaultChecked={tenantProfileSettings.wallet}
          value={tenantProfileSettings.wallet}
        />
      </Flex>

      <Flex py="1" justify="space-between" align="center">
        <Text pt="10px">Market Place</Text>
        <Switch
          size="md"
          name="market"
          onChange={() => setMarket(!market)}
          defaultChecked={tenantProfileSettings.market}
          value={tenantProfileSettings.market}
        />
      </Flex>

      <Flex py="5px" justify="space-between" align="center">
        <Text pt="10px">Advert</Text>
        <Switch
          size="md"
          name="advert"
          onChange={() => setAdvert(!advert)}
          defaultChecked={tenantProfileSettings.advert}
          value={tenantProfileSettings.advert}
        />
      </Flex>
      <Flex pt="30px">
        <PrimaryButton btnText="Edit Profile" onClick={handleSave} />
      </Flex>
    </Box>
  );
};

export default Notifications;
