export const emergencyType = (type) => {
  switch (type) {
    case 'Health':
      return 'Health';
    case 'Theft':
      return 'Theft';
    case 'Fire':
      return 'Fire';

    default:
      return 'Others';
  }
};
