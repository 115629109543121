import { Box, Img, Stack, Text, SlideFade } from '@chakra-ui/react';
import React from 'react';
import { PROTECTED_PATHS } from '../../../../../app/constants';
import Container from '../../../../../components/Container';
import Header from '../../../../../components/Header';
import BillPicker from '../../BillPicker';
import dstv from '../../../../../assets/dstv.png';
import gotv from '../../../../../assets/gotv.svg';
import startimes from '../../../../../assets/startimes.png';
import { COLORS } from '../../../../../constants/colors';

const CableTv = () => {
  const { DSTV_PAYMENT, MY_WALLET, GOTV_PAYMENT, STARTIMES_PAYMENT } =
    PROTECTED_PATHS;

  return (
    <SlideFade in={true} offsetX="-2000px">
      <Box>
        <Box shadow="sm">
          <Container>
            <Header goto={MY_WALLET} />
          </Container>
        </Box>
        <Container py="10">
          <Text fontWeight="500" fontSize="1.1rem" color={COLORS.green}>
            Select Provider
          </Text>
          <Stack spacing="5">
            <BillPicker p="4" to={DSTV_PAYMENT}>
              <Img src={dstv} alt="dstv" />
            </BillPicker>

            <BillPicker p="4" to={GOTV_PAYMENT}>
              <Img src={gotv} alt="dstv" />
            </BillPicker>

            <BillPicker p="2" to={STARTIMES_PAYMENT}>
              <Img w="120px" src={startimes} alt="dstv" />
            </BillPicker>
          </Stack>
        </Container>
      </Box>
    </SlideFade>
  );
};

export default CableTv;
