import { Flex, Spinner, useColorModeValue } from '@chakra-ui/react';
import { COLORS } from '../constants/colors';

export default function FullPageLoader({ bg, h }) {

  const pageBg = useColorModeValue(COLORS.bgGrey, COLORS.darkModeBg);
  const appTextColor = useColorModeValue(COLORS.primary, COLORS.lightGrey);
  return (
    <Flex
      bg={bg ? bg : pageBg}
      justifyContent="center"
      alignItems="center"
      height={h ? h : "100vh"}
      width="100vw"
    >
      <Spinner
        color={appTextColor}
        w="50px"
        h="50px"
        speed="0.65s"
        thickness="5px"
      />
    </Flex>
  );
}
