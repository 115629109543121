import { Box, Img, Stack, Text, SlideFade, SimpleGrid } from '@chakra-ui/react';
import React from 'react';
import Container from '../../../../../components/Container';
import Header from '../../../../../components/Header';
import BillPicker from '../../BillPicker';
import { PROTECTED_PATHS } from '../../../../../app/constants';
import { useContext, useEffect, useState } from 'react';
import { getElectricityPlans } from '../../../service/electicity';
import { AuthContext } from '../../../../../context/authContext';
import FullPageLoader from '../../../../../components/FullPageLoader';

const PowerAndUtility = () => {
  const { MY_WALLET } = PROTECTED_PATHS;
  const { userInfo } = useContext(AuthContext);
  const { userId } = userInfo;
  const [loading, setLoading] = useState(false);
  const [electicityPlans, setelecticityPlans] = useState([]);

  useEffect(() => {
    getElectricityPlans(userId, setLoading, setelecticityPlans);
  }, [userId]);

  return loading ? (
    <FullPageLoader />
  ) : (
    <SlideFade in={true} offsetX='-2000px'>
      <Stack>
        <Box shadow='sm'>
          <Container>
            <Header goto={MY_WALLET} />
          </Container>
        </Box>
        <Container py='10'>
          <Text fontWeight='600' fontSize='1.2rem'>
            Select Provider
          </Text>
          <SimpleGrid columns={2} spacing='12'>
            {electicityPlans?.map((plan) => (
              <BillPicker key={plan?.plan_id} px='2' py='1' to={`${plan?.disco_name}/${plan?.plan_id}`}>
                <Stack align='center'>
                  <Text fontWeight='600'>{plan?.disco_name}</Text>
                  <Img h='50px' src={plan?.image} alt={plan?.disco_name} />
                </Stack>
              </BillPicker>
            ))}
          </SimpleGrid>
        </Container>
      </Stack>
    </SlideFade>
  );
};

export default PowerAndUtility;
