import { createContext, useState, useEffect, useContext } from 'react';
import { getUserProfile } from '../pages/settings/service/getUserProfile';
import { AuthContext } from './authContext';

export const UserProfileContext = createContext({});

const UserProfileContextProvider = ({ children }) => {
  const {
    userInfo: { userId },
  } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [userProfile, setUserProfile] = useState({});
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    getUserProfile(userId, setIsLoading, setUserProfile);
  }, [userId, refresh]);

  return <UserProfileContext.Provider value={{ userProfile, isLoading, setRefresh }}>{children}</UserProfileContext.Provider>;
};

export default UserProfileContextProvider;

export const UserProfile = () => {
  return useContext(UserProfileContext);
};
