import { Box, Flex, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import dayjs from "dayjs";
import { Popover } from "../../../components/Popover";
import { COLORS } from "../../../constants/colors";

const SingleGuest = (props) => {
  const { name, endDate, allowCompany } = props;

  const cardBg = useColorModeValue(COLORS.lightGrey, COLORS.white);
  const appTextColor = useColorModeValue(COLORS.primary, COLORS.primary);

  return (
    <Box
      p="1rem"
      borderRadius="10px"
      boxShadow="sm"
      bg={cardBg}
      color={appTextColor}
      mb="2rem"
    >
      <Flex justify="space-between" align="center">
        {/*<Avatar name='Kent Dodds' src='https://bit.ly/kent-c-dodds' />*/}

        <Stack fontSize="0.8rem">
          <Text fontSize="1.1rem" fontWeight="semibold" color={COLORS.black}>
            {name}
          </Text>
          <Text>Last day of visit {dayjs(endDate).format("DD/MMM/YYYY")}</Text>
          <Text m="0 !important">
            {allowCompany ? "With Company" : "Without Company"}
          </Text>
        </Stack>
        <Box alignSelf={"flex-end"}>
          <Popover {...props} />
        </Box>
      </Flex>
    </Box>
  );
};

export default SingleGuest;
