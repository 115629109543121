import {
  Box,
  Center,
  Flex,
  Text,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { COLORS } from "../../../constants/colors";
import dayjs from "dayjs";

export const Transactions = ({ data }) => {
  console.log(data, "trans");
  const cardBg = useColorModeValue(COLORS.lightGrey, COLORS.white);
  const transBg = useColorModeValue(COLORS.green, COLORS.chartbg);
  const bgColor = useColorModeValue(COLORS.primary, COLORS.green);
  const textColor = useColorModeValue(COLORS.primary, COLORS.white);

  return (
    <Box>
      {data && data.length > 0 ? (
        data.slice(0, 3).map((datum) => (
          <>
            <Box bg={transBg} p="15px" rounded={"lg"} mb={"20px"}>
              <Flex justify="space-between" mt="0px">
                <Text color={cardBg}>
                  {datum?.paymentFor
                    ? datum.paymentFor.charAt(0).toUpperCase() +
                      datum.paymentFor.slice(1)
                    : ""}
                </Text>
                <Text color={cardBg}>
                  {datum?.status
                    ? datum.status.charAt(0).toUpperCase() +
                      datum.status.slice(1).toLowerCase()
                    : ""}
                </Text>
              </Flex>
              <Flex justify={"space-between"}>
                <Text color={COLORS.lightGrey}>
                  {dayjs(datum?.updatedAt).format(" h:mm A MMM D, YYYY")}
                </Text>
                <Text color={cardBg}>
                  {" "}
                  &#8358; {datum?.amountSent?.toLocaleString()}
                </Text>
              </Flex>
            </Box>
          </>
        ))
      ) : (
        <Center>
          <VStack>
            <Text as="h2" py="5" fontSize="18px" color={cardBg}>
              You have no transactions yet
            </Text>
          </VStack>
        </Center>
      )}
    </Box>
  );
};
