import {
  Box,
  Button,
  Circle,
  Flex,
  Img,
  Stack,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import alarm from "../../../assets/Group 62.svg";
import { PrimaryButton } from "../../../components/CustomButton";
import { createEmergency } from "../service/createEmergency";
import { emergencyType } from "./emergencyType";
import { useReactMediaRecorder } from "react-media-recorder";
import CustomModal from "../../../components/CustomModal";
import { BsMic } from "react-icons/bs";
import { infoNotifier } from "../../../components/NotificationHandler";
import { UserProfile } from "../../../context/userProfileContext";

const EmergencyModal = ({ onClose }) => {
  const { userProfile } = UserProfile();
  const { _id, tenantCode } = userProfile;
  const {
    status,
    startRecording,
    // stopRecording,
    mediaBlobUrl,
  } = useReactMediaRecorder({
    video: false,
    audio: true,
    echoCancellation: true,
  });

  const [loading, setLoading] = useState(false);
  const [enemrgecyTypeCreated, setenemrgecyTypeCreated] = useState("");
  const [description, setDescription] = useState("")
  const type = {
    health: "Health",
    theft: "Theft",
    fire: "Fire",
    others: "Others",
  };
  const handleCreate = (type) => {
    setenemrgecyTypeCreated(emergencyType(type));
    const payload = { tenantCode, userId: _id, type, description};

    createEmergency(payload, setLoading, onClose);
  };

  const handleStopRecording = () => {
    return infoNotifier("coming soon");
    // stopRecording();
    // // setRecorded(true);
    // console.log('mediaBlobUrl', mediaBlobUrl);
    // const audioFile = new File([mediaBlobUrl], 'voice.wav', { type: 'audio/wav' });
    // console.log('audioFile', audioFile);
    // const payload = { tenantCode, userId, type, description: 'Emergency' };
    // createEmergency(payload, setLoading, onClose);
  };

  return (
    <Stack>
      <Box mx="auto" py="1rem">
        <Img src={alarm} alt="alarm" />
      </Box>
      <Stack
        justify="center"
        align="center"
        direction="column"
        mx="auto"
        spacing="5"
      >
        <PrimaryButton
          isLoading={enemrgecyTypeCreated === "Health" && loading}
          onClick={() => handleCreate(type.health)}
          btnText="Health"
          w="70%"
        />
        <PrimaryButton
          isLoading={enemrgecyTypeCreated === "Theft" && loading}
          onClick={() => handleCreate(type.theft)}
          btnText="Theft"
          w="70%"
        />
        <PrimaryButton
          isLoading={enemrgecyTypeCreated === "Fire" && loading}
          onClick={() => handleCreate(type.fire)}
          btnText="Fire"
          w="70%"
        />

        <CustomModal
          buttonProps={{ w: "70%", bg: "secondary" }}
          btnTitle="Others"
        >
          <VStack px="5" pos="relative" pb="12">
            {mediaBlobUrl && <audio src={mediaBlobUrl} controls loop />}
            <Textarea
              _focus={{ border: "outline" }}
              placeholder="Type here..."
              border="2px solid #3C1A5B !important"
              rows="8"
              overflow="auto"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </VStack>
          <Flex justify="center" pt="5">
            <Button
              _focus={{ outline: "none", bg: "#614385", color: "#FFFFFF" }}
              size="sm"
              bg="#614385"
              color="#FFFFFF"
              px="3rem"
              onClick={() => handleCreate(type.others)}
              textAlign="center"
              isLoading={enemrgecyTypeCreated === "Others" && loading}
            >
              Submit
            </Button>
            {/* <Button
              _focus={{ outline: "none" }}
              size="sm"
              variant="ghost"
              onClick={handleStopRecording}
              textAlign="center"
            >
              Press to {status === "idle" ? "record" : "stop"}
            </Button> */}
          </Flex>
        </CustomModal>
      </Stack>
    </Stack>
  );
};

export default EmergencyModal;
