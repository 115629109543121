import {
  Box,
  Button,
  Img,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { v4 } from "uuid";
import newsImage from "../../../assets/boyImage.jpg";
import { COLORS } from "../../../constants/colors";
import "../../../App.css";
import DOMPurify from "dompurify"; // Add DOMPurify to sanitize HTML content

const SingleNewsCard = ({ content, image, title }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const appTextColor = useColorModeValue(COLORS.white, COLORS.grey);
  const cardBg = useColorModeValue(COLORS.primary, COLORS.white);
  const heroBg = useColorModeValue(COLORS.black, COLORS.primary);

  // Sanitize the content to prevent XSS attacks
  const sanitizedContent = DOMPurify.sanitize(content);

  return (
    <>
      <Box
        onClick={onOpen}
        shadow="md"
        p="10px"
        rounded="lg"
        cursor={"pointer"}
        bg="#FFFFFF"
        h="150px"
        className="news-card-container"
      >
        <Img
          objectFit="cover"
          rounded="lg"
          src={image[0] || newsImage}
          w="100%"
          h="80px"
          alt="News"
        />
        <Box pt="10px" bg={"white !important"} noOfLines={2}>
          <Text fontWeight={"semibold"} color={heroBg}>
            {title}
          </Text>
        </Box>
      </Box>

      <Modal
        size="sm"
        motionPreset="slideInBottom"
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center" color={cardBg}>
            {title}
          </ModalHeader>
          <ModalBody>
            <Stack py="2" spacing="5">
              {image.map((i) => (
                <Img
                  key={v4()}
                  src={i}
                  h="180px"
                  objectFit="cover"
                  alt={title}
                />
              ))}
              {/* Render the sanitized HTML content */}
              <Box
                color={cardBg}
                className="announcement-content"
                dangerouslySetInnerHTML={{ __html: sanitizedContent }}
              />
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button size="sm" variant="outline" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SingleNewsCard;

// import {
//   Box,
//   Button,
//   Img,
//   Modal,
//   ModalBody,
//   ModalContent,
//   ModalFooter,
//   ModalHeader,
//   ModalOverlay,
//   Stack,
//   Text,
//   useColorModeValue,
//   useDisclosure,
// } from "@chakra-ui/react";
// import { v4 } from "uuid";
// import newsImage from "../../../assets/boyImage.jpg";
// import { COLORS } from "../../../constants/colors";

// const SingleNewsCard = ({ content, image, title }) => {
//   const { isOpen, onOpen, onClose } = useDisclosure();
//   const appTextColor = useColorModeValue(COLORS.white, COLORS.grey);
//   const cardBg = useColorModeValue(COLORS.primary, COLORS.white);
//   const heroBg = useColorModeValue(COLORS.black, COLORS.primary);

//   return (
//     <>
//       <Box
//         onClick={onOpen}
//         shadow="md"
//         p="10px"
//         rounded="lg"
//         cursor={"pointer"}
//         // bg="#cdcdcd"
//         bg="#FFFFFF"
//         h="150px"
//         // borderRadius='5px'
//         // overflow={'hidden'}
//         // w='90%'
//         // pos='relative'
//         className="news-card-container"
//       >
//         <Img
//           objectFit="cover"
//           rounded="lg"
//           src={image[0] || newsImage}
//           w="100%"
//           h="80px"
//           alt="News"
//         />
//         <Box pt="10px" bg={"white !important"} noOfLines={2}>
//           <Text fontWeight={"semibold"} color={heroBg}>
//             {title}
//           </Text>
//           {/*<Text fontWeight={500}>
//             {content}
//   </Text>*/}
//         </Box>
//       </Box>

//       <Modal
//         size="sm"
//         motionPreset="slideInBottom"
//         isOpen={isOpen}
//         onClose={onClose}
//       >
//         <ModalOverlay />
//         <ModalContent>
//           <ModalHeader textAlign="center" color={cardBg}>
//             {title}
//           </ModalHeader>
//           <ModalBody>
//             <Stack py="2" spacing="5">
//               {image.map((i) => (
//                 <Img
//                   key={v4()}
//                   src={i}
//                   h="180px"
//                   objectFit="cover"
//                   alt={title}
//                 />
//               ))}
//               {/*<Text color={cardBg}>{content}</Text>*/}
//               <Text
//                 color={cardBg}
//                 dangerouslySetInnerHTML={{ __html: content }}
//               />
//             </Stack>
//           </ModalBody>

//           <ModalFooter>
//             <Button size="sm" variant="outline" onClick={onClose}>
//               Close
//             </Button>
//           </ModalFooter>
//         </ModalContent>
//       </Modal>
//     </>
//   );
// };

// export default SingleNewsCard;
