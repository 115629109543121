import { errorNotifier } from '../../../components/NotificationHandler';
import http, { AUTH_ROUTES } from '../../../services/api';

export const getChatMessages = async (issueID, userId, setLoading, setChatMessages) => {
  //   setLoading(true);
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_CHAT_MESSAGE(issueID, userId));

    setChatMessages(data?.issueChats);
  } catch (e) {
  } finally {
    setLoading(false);
  }
};

export const sendChatMessage = async (payload, setBtnLoading, setRefresh, setTypedMessage) => {
  setBtnLoading(true);
  try {
    await http.post(AUTH_ROUTES.SEND_CHAT_MESSAGE, payload);
    setRefresh({});
  } catch (e) {
    errorNotifier();
  } finally {
    setBtnLoading(false);
  }
};

export const getEstateInfo = async (userId, setLoading, setEstateInfo) => {
  setLoading(true);
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_ESTATE_INFO(userId));

    setEstateInfo(data);
    setLoading(false);
  } catch (error) {
    errorNotifier(error.response.data.message);
    setLoading(false);
  }
};
