import {
  errorNotifier,
  successNotifier,
} from '../../../components/NotificationHandler';
import { timeout } from '../../../components/utils/timeout';
import http, { AUTH_ROUTES } from '../../../services/api';

export const getSpecialRequests = async (
  userId,
  setLoading,
  setPartyEvents
) => {
  setLoading(true);
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.GET_PARTY_EVENTS(userId));

    setPartyEvents(data?.requests);
  } catch (e) {
    console.log(e.response);
  } finally {
    setLoading(false);
  }
};

export const getSinglePartyEvent = async (
  userId,
  eventId,
  setLoading,
  setPartyEvent
) => {
  setLoading(true);
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.GET_SINGLE_PARTY_EVENT(userId, eventId));

    setPartyEvent(data?.requests[0]);
  } catch (e) {
    console.log(e.response);
  } finally {
    setLoading(false);
  }
};

export const editPartyEvent = async (
  userId,
  requestId,
  setLoading,
  payload
) => {
  setLoading(true);
  console.log('payload', payload);
  try {
    const { data } = await http.put(
      AUTH_ROUTES.EDIT_PARTY_REQUEST(userId, requestId),
      payload
    );

    console.log('data', data);
    successNotifier(data?.message);
  } catch (e) {
    console.log(e.response);
    errorNotifier(e.response.data.message);
  } finally {
    setLoading(false);
  }
};

export const makePartyEventRequest = async (
  payload,
  setLoading,
  setSuccess
) => {
  setLoading(true);

  try {
    const { data } = await http.post(AUTH_ROUTES.PAY_SPECIAL_REQUEST, payload);
    console.log('contrator', data);
    successNotifier(data?.message);
    timeout(() => setSuccess(true), 1000);
  } catch (e) {
    console.log(e.response);
    errorNotifier(e.response.data.message);
    setSuccess(false);
  } finally {
    setLoading(false);
  }
};

export const addGuest = async (
  userId,
  eventId,
  payload,
  setRefresh,
  setBntLoading
) => {
  setBntLoading(true);
  try {
    const { data } = await http.put(
      AUTH_ROUTES.ADD_MORE_GUEST(userId, eventId),
      payload
    );

    console.log('add mor giest', data);
    successNotifier(data?.message);
    setRefresh({});
  } catch (e) {
    console.log(e.response);
    errorNotifier();
  } finally {
    setBntLoading(false);
    // setRefresh({});
  }
};

export const deleteGuestFromEventList = async (
  payload,
  setLoading,
  userId,
  eventId
) => {
  setLoading(true);
  try {
    const { data } = await http.put(
      AUTH_ROUTES.DELETE_GUEST_FROM_EVENT_LIST(userId, eventId),
      payload
    );
    console.log("data", data);
    successNotifier("guest removed from list");
  } catch (err) {
    console.log(err.response);
  } finally {
    setLoading(false);
  }
};
