import {
  Box,
  Center,
  FormControl,
  FormLabel,
  HStack,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Stack,
  Text,
  Textarea,
  SlideFade,
  Flex,
  useColorModeValue,
} from "@chakra-ui/react";
import { BsAlarmFill } from "react-icons/bs";
import DatePicker from "react-multi-date-picker";
import { PROTECTED_PATHS } from "../../../app/constants";
import Container from "../../../components/Container";
import { PrimaryButton } from "../../../components/CustomButton";
import Header from "../../../components/Header";
import { ConfirmContractorArtisanPayment } from "./ConfirmContractorArtisanPayment";
import { contractorArtisanType } from "./data";
import { v4 } from "uuid";
import { transition } from "../../..";
import { useContext, useEffect, useState } from "react";
import { PaymentMethod } from "./PaymentMethod";
import dayjs from "dayjs";
import CustomeSpecialReqestModal from "./CustomeSpecialReqestModal";
import { UserProfileContext } from "../../../context/userProfileContext";
import { getPricesForSpecialRequest } from "../service";
import { COLORS } from "../../../constants/colors";
const { SPECIAL_GUEST_REQUEST } = PROTECTED_PATHS;

const ContractorArtisan = () => {
  const today = new Date();
  const { userProfile } = useContext(UserProfileContext);
  const { email, _id, tenantCode } = userProfile;
  const [allowToOpen, setallowToOpen] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(false);
  const [numberOfPeople, setNumberOfPeople] = useState(1);
  const [requestFees, setRequestFees] = useState({});
  const estateFee = requestFees?.contractorFee || 100;
  const cardBg = useColorModeValue(COLORS.primary, COLORS.white);

  const inputStyle = {
    bg: "white",
    rounded: "lg",
    color: "#262636",
  };
  const initialValues = {
    email,
    userId: _id,
    tenantCode,
    subType: "",
    contactPerson: "",
    contactPersonPhone: "",
    dates: [],
    description: "",
    numberOfGuests: 1,
    estateFee,
  };

  const [values, setValues] = useState(initialValues);

  useEffect(() => {
    getPricesForSpecialRequest(_id, setRequestFees);
  }, [_id]);

  const onChange = (e) => {
    setValues((prev) => ({
      ...prev,
      type: "contractor",
      [e.target.name]: e.target.value,
    }));
  };

  const handleNumberOfPeople = (number) => {
    setNumberOfPeople(number);
    setValues((prev) => ({ ...prev, numberOfGuests: number }));
  };

  const handleNumberOfDays = (date) => {
    const sortedDate = date.sort((a, b) => a.dayOfYear - b.dayOfYear);
    const formatedDates = sortedDate.map((d) => dayjs(d).format("YYYY-MM-DD"));
    setValues((prev) => ({ ...prev, dates: formatedDates }));
  };

  useEffect(() => {
    const { contactPerson, contactPersonPhone, description, subType, dates } =
      values;
    !contactPerson ||
    !contactPersonPhone ||
    !description ||
    !subType ||
    !dates.length
      ? setallowToOpen(false)
      : setallowToOpen(true);
  }, [values]);
console.log(values, "valll")
  return (
    <SlideFade in={true} offsetX="-1000px" transition={{ ...transition }}>
      {paymentMethod && (
        <PaymentMethod
          values={values}
          setValues={setValues}
          estateFee={estateFee}
          userProfile={userProfile}
        />
      )}

      {!paymentMethod && (
        <Stack>
          <Box shadow="sm">
            <Container>
              <Header goto={SPECIAL_GUEST_REQUEST} />
            </Container>
          </Box>

          <Container>
            <FormControl mb="50px" fontSize="12px">
              <Stack pt="15px" pb="50px" spacing="15px">
                <FormLabel color={cardBg}>
                  Choose type of contractor/Artisan
                  <Select
                    {...inputStyle}
                    placeholder="Select Contractor"
                    color={COLORS.green}
                    name="subType"
                    value={values.subType}
                    onChange={onChange}
                    mt="5px"
                  >
                    {contractorArtisanType.map((type) => (
                      <option key={v4()}>{type}</option>
                    ))}
                  </Select>
                </FormLabel>

                <FormLabel color={cardBg}>
                  Name of contact person
                  <Input
                    {...inputStyle}
                    name="contactPerson"
                    onChange={onChange}
                    mt="5px"
                  />
                </FormLabel>
                <FormLabel color={cardBg}>
                  Phone number of contact person
                  <Input
                    {...inputStyle}
                    name="contactPersonPhone"
                    onChange={onChange}
                    mt="5px"
                  />
                </FormLabel>
                <HStack justify="space-between">
                  <FormLabel fontSize="13px" color={cardBg}>
                    Total number of people <br />
                    coming in
                    <NumberInput
                      w="100px"
                      min={0}
                      value={numberOfPeople}
                      onChange={handleNumberOfPeople}
                      mt="5px"
                    >
                      <NumberInputField textAlign="center" {...inputStyle} />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </FormLabel>
                  <Box>
                    <FormLabel m="0" color={cardBg}>
                      Number of days
                    </FormLabel>
                    <Center
                      {...inputStyle}
                      className="special-request-date-picker-wrapper"
                      position="relative"
                    >
                      <DatePicker
                        placeholder={dayjs(today).format("DD-MMM-YYYY")}
                        multiple="true"
                        minDate={today}
                        onChange={handleNumberOfDays}
                      />
                      <Box pos="absolute" pointerEvents="none" right="15px">
                        <BsAlarmFill size="20px" />
                      </Box>
                    </Center>
                  </Box>
                </HStack>

                <HStack justify="space-between" py="3">
                  <Box>
                    <Text color={cardBg}>Estate fee for</Text>
                    <Text fontWeight="600" color={cardBg}>
                      {numberOfPeople}{" "}
                      {numberOfPeople <= 1 ? "Person" : "People"} for{" "}
                      {values.dates.length}{" "}
                      {values.dates.length <= 1 ? "day" : "days"}
                    </Text>
                  </Box>
                  <Box>
                    <Text color={cardBg}>Total</Text>
                    <Text fontWeight="600" color={cardBg}>
                      &#8358; {estateFee * values.dates.length * numberOfPeople}
                    </Text>
                  </Box>
                </HStack>
                <FormLabel>
                  <Flex justify="space-between">
                    <Text color={cardBg} mb="5px">
                      Reasons for invite
                    </Text>{" "}
                    <HStack fontSize={"10px"}>
                      <Text color={cardBg}>per contractor</Text>
                      <Text fontWeight="600" color={cardBg}>
                        &#8358; {estateFee}
                      </Text>
                    </HStack>
                  </Flex>

                  <Textarea
                    {...inputStyle}
                    rows="5"
                    resize="none"
                    placeholder="Type here..."
                    _placeholder={{ opacity: 1, color: "black" }}
                    name="description"
                    onChange={onChange}
                  />
                </FormLabel>
              </Stack>

              <CustomeSpecialReqestModal
                allowToOpen={allowToOpen}
                btnTitle={<PrimaryButton btnText="Send Request" />}
              >
                <ConfirmContractorArtisanPayment
                  estateFee={estateFee * values.dates.length * numberOfPeople}
                  values={values}
                  setValues={setValues}
                  setPaymentMethod={() => setPaymentMethod(true)}
                />
              </CustomeSpecialReqestModal>
            </FormControl>
          </Container>
        </Stack>
      )}
    </SlideFade>
  );
};

export default ContractorArtisan;
