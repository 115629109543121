import React, { useEffect, useState } from "react";
import {
  Box,
  Stack,
  ScaleFade,
  Text,
  useColorModeValue,
  SimpleGrid,
  Flex
} from "@chakra-ui/react";
import Container from "../../components/Container";
import Header from "../../components/Header";
import Footer from "../../components/footer";
import FullPageLoader from "../../components/FullPageLoader";
import { COLORS } from "../../constants/colors";
import { fetchTopArtisans } from "./service";
import { fetchSkillTypes } from "./service";
import { useNavigate } from "react-router-dom";
import TopArtisans from "../../components/TopArtisans";
import { PROTECTED_PATHS } from "../../app/constants";
import { dynamicPathhandler } from "../../components/utils/dynamicPathHandler";
import { FaTools, FaHammer, FaUserTie } from "react-icons/fa";
import { MdOutlineCarRepair, MdPerson } from "react-icons/md";

const ArtisanPage = () => {
  const skillTypeIcons = {
    Radionic: <FaTools />,
    Welder: <FaHammer />,
    "AC Repairer": <MdOutlineCarRepair />,
    "Fridge and Freezers": <MdOutlineCarRepair />,
    "Hair Dressers": <MdPerson />,
    "Pure Water Supply": <FaUserTie />,
    Carpenter: <FaHammer />,
    "Casual Home Assistant": <MdPerson />,
    "Baby Sitter": <MdPerson />,
    Driver: <MdOutlineCarRepair />,
    Pop: <FaHammer />,
    Bricklayer: <FaHammer />,
    "Aluminium Works": <FaTools />
  };

  const pageBg = useColorModeValue(COLORS.bgGrey, COLORS.darkModeBg);
  const cardBg = useColorModeValue(COLORS.primary, COLORS.white);
  const appTextColor = useColorModeValue(COLORS.white, COLORS.grey);
  const { TOP_ARTISANS } = PROTECTED_PATHS;

  const { ARTISANS_SKILL_TYPE } = PROTECTED_PATHS;
  const parentPath = dynamicPathhandler(ARTISANS_SKILL_TYPE);

  const [loading, setLoading] = useState(false);
  const [skillTypes, setSkillTypes] = useState([]);
  const [topArtisans, setTopArtisans] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchTopArtisans(setLoading, setTopArtisans);
    fetchSkillTypes(setLoading, setSkillTypes);
  }, []);

  const handleSkillTypeClick = (skillType) => {
    navigate(`${parentPath}/${skillType}`);
  };

  return loading ? (
    <FullPageLoader />
  ) : (
    <ScaleFade initialScale={0.3} in={true}>
      <Box bg={pageBg} minH="100vh">
        <Box shadow="sm">
          <Container>
            <Header menu />
          </Container>
        </Box>

        <Stack pt="20px">
          <Container>
            {/* Use TopArtisans Component */}
            <TopArtisans
              topArtisans={topArtisans}
              navigateToAll={() => navigate(TOP_ARTISANS)}
            />
          </Container>

          <Container pos="relative">
            <Stack spacing="5">
              <Box>
                <Text fontSize="1rem" color={cardBg} mb="10px">
                  Artisan Skill Categories
                </Text>

                {skillTypes.length > 0 ? (
                  <SimpleGrid columns={[2, 3, 4]} spacing="10px" mt="10px">
                    {skillTypes.map((skill, index) => (
                      <Box
                        key={index}
                        bg={"#fff"}
                        borderRadius="10px"
                        p="10px"
                        shadow="md"
                        textAlign="center"
                        cursor="pointer"
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        onClick={() => handleSkillTypeClick(skill)}
                      >
                        <Box fontSize="4xl" mb="5px" color={COLORS.green}>
                          {skillTypeIcons[skill] || <FaTools />}{" "}
                          {/* Default icon */}
                        </Box>
                        <Text fontWeight="semibold" color={COLORS.green}>
                          {skill}
                        </Text>
                      </Box>
                    ))}
                  </SimpleGrid>
                ) : (
                  <Flex
                    rounded="lg"
                    bg={cardBg}
                    justify="center"
                    align="center"
                    h="100px"
                  >
                    <Text
                      textAlign="center"
                      fontSize="18px"
                      color={appTextColor}
                    >
                      No skill categories available
                    </Text>
                  </Flex>
                )}
              </Box>
            </Stack>
          </Container>
          <Footer />
        </Stack>
      </Box>
    </ScaleFade>
  );
};

export default ArtisanPage;
