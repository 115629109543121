// import {
//   errorNotifier,
//   successNotifier,
// } from '../../../components/NotificationHandler';
import http, { AUTH_ROUTES } from '../../../services/api';

export const getNotifications = async (userId, setLoading, setNotifications) => {
  try {
    setLoading(true);
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_NOTIFICATIONS(userId));

    setNotifications(data.notifications);
  } catch (error) {
    console.log(error.response);
  } finally {
    setLoading(false);
  }
};
