import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Box,
  useDisclosure,
  Flex,
  Circle,
  Stack,
  MenuItem,
  Button,
  useColorModeValue,
} from "@chakra-ui/react";
import { deleteGuest } from "../pages/my-estate/service";
import { COLORS } from "../constants/colors";

export const DeleteModal = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const appTextColor = useColorModeValue(COLORS.lightGrey, COLORS.white);
      const menuColor = useColorModeValue(COLORS.black, COLORS.white);


  const handleDelete = (guest) => {
    const { userId, _id } = guest;
    deleteGuest(userId, _id, onClose).then(props.setRefresh({}));
  };

  return (
    <Box>
      <MenuItem onClick={onOpen} color={menuColor}>
        Delete
      </MenuItem>

      <Modal size="xs" isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalBody textAlign={"center"}>
            <Circle
              mx="auto"
              align="center"
              my="5"
              size="30px"
              bg="#FFF"
              color="white"
              border="1px solid #707070"
            />
            <Stack>
              <Text fontSize="lg" px="auto" fontWeight="light" color="#7A7A7A">
                Are you sure?
              </Text>

              <Text fontSize="sm" color="#7A7A7A">
                This action cannot be reversed
              </Text>

              <Flex justify={"center"} gap="2rem" fontSize="md" pb="5">
                <Button color="#B7B6B6" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  color="#FC7067"
                  pl="5"
                  onClick={() => handleDelete(props)}
                >
                  delete
                </Button>
              </Flex>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
