import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { infoNotifier } from '../../../../components/NotificationHandler';
import { validatePhone } from '../../../../components/utils/vaildatePhoneNumber';
import { UserProfileContext } from '../../../../context/userProfileContext';
import { purchaseAirtime } from '../../service/airtime';

const useAirtimePurchase = () => {
  const { userProfile } = useContext(UserProfileContext);
  const { _id, tenantName } = userProfile;
  const [success, setSuccess] = useState(false);
  const [amount, setAmount] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleAirtimePurshase = (network) => {
    const payload = {
      userId: _id,
      tenantName,
      network,
      amount: Number(amount) + 0.05 * Number(amount),
      airtime_type: 'VTU',
      mobileNumber,
    };

    if (payload.mobileNumber.length !== 11) {
      infoNotifier('Invalid Phone number must be 11digits');
      return;
    }

    if (!validatePhone(payload.mobileNumber)) return infoNotifier('Invalid Phone number');

    if (!payload.amount) {
      infoNotifier('Enter the amount of Airtime you want to buy');
      return;
    }

    purchaseAirtime(payload, setLoading, setSuccess, navigate);
  };

  return { handleAirtimePurshase, amount, mobileNumber, loading, success, setMobileNumber, setAmount };
};

export default useAirtimePurchase;
