import {
  Stack,
  Box,
  Text,
  SlideFade,
  useColorModeValue,
} from "@chakra-ui/react";
import { PROTECTED_PATHS } from "../../app/constants";
import Container from "../../components/Container";
import Footer from "../../components/footer";
import Header from "../../components/Header";
import { RequestTypeCard } from "./components/RequestTypeCard";
import { TbBriefcase } from "react-icons/tb";
import { AiOutlineCar } from "react-icons/ai";
import { BsPeople } from "react-icons/bs";
import { transition } from "../..";
import { COLORS } from "../../constants/colors";

const SpecialGuestRequest = () => {
  const { PARTY_EVENT, VEHICLES, CONTRACTOR_ARTISAN, CONTRACTOR_LIST, VEHICLE_LIST, EVENT_LIST } = PROTECTED_PATHS;
  const cardBg = useColorModeValue(COLORS.primary, COLORS.white);

  return (
    <SlideFade in={true} offsetX="-1000px" transition={{ ...transition }}>
      <Stack>
        <Box shadow="sm">
          <Container>
            <Header menu />
          </Container>
        </Box>
        <Container>
          <Stack spacing="30px" pb="15vh">
            <Text pt="50px" fontSize="1.2rem" color={cardBg}>
              Choose Type Special Request
            </Text>
            <RequestTypeCard
              to={CONTRACTOR_LIST}
              icon={<TbBriefcase size={20} />}
              heading="Contractor/Artisan"
              text="Invite skilled professionals like contractors and artisans of all kinds to your estate."
            />
            <RequestTypeCard
              to={VEHICLE_LIST}
              icon={<AiOutlineCar size={20} />}
              heading="Vehicles"
              text="Get clearance for vehicles coming into the estate from our estate managers."
            />
            <RequestTypeCard
              to={EVENT_LIST}
              icon={<BsPeople size={20} />}
              heading="Party/Event"
              text="Request approval from your estate managers for your Events and Parties.."
            />
          </Stack>
        </Container>
        <Footer />
      </Stack>
    </SlideFade>
  );
};

export default SpecialGuestRequest;
