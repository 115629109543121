import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Box,
  useDisclosure,
  Stack,
  Button,
  Avatar,
  AvatarBadge,
  useColorModeValue,
} from "@chakra-ui/react";
import { Input } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { editTenantProfile } from "../service";
import { PrimaryButton } from "../../../components/CustomButton";
import { FiCamera } from "react-icons/fi";
import { UserProfile } from "../../../context/userProfileContext";
import { COLORS } from "../../../constants/colors";

export const EditProfile = () => {
  const pageBg = useColorModeValue(COLORS.bgGrey, COLORS.darkModeBg);
  const cardBg = useColorModeValue(COLORS.primary, COLORS.white);
  const appTextColor = useColorModeValue(COLORS.white, COLORS.grey);
  const bgColor = useColorModeValue(COLORS.primary, COLORS.green);

  const { userProfile, setRefresh } = UserProfile();
  const { _id } = userProfile;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [initialValues, setInitialValues] = useState({});
  const [imageFile, setImageFile] = useState({});
  const [profilePicture, setProfilePicture] = useState("");
  const [formValues, setFormValues] = useState(initialValues);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const { tenantName, email, tenantPhone } = userProfile;
    setInitialValues({ tenantName, tenantPhone, email });
  }, [userProfile]);

  const changeHandler = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setFormValues(initialValues);
  }, [initialValues]);

  const handleImgUpload = (event) => {
    // Assuming only image
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = function (e) {
      setProfilePicture({ imgUrl: reader.result, file: event.target.files[0] });
    };
  };

  const { tenantName, email, tenantPhone } = formValues;
  const handleUpdateProfile = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("tenantName", tenantName);
    formData.append("email", email);
    formData.append("tenantPhone", tenantPhone);
    formData.append("profileImage", profilePicture.file || imageFile);

    editTenantProfile(_id, formData, setIsLoading, onClose).then(() =>
      setRefresh({})
    );
  };

  useEffect(() => {
    if (!userProfile.profilePicture) return;
    // covert image url to file
    const url = userProfile.profilePicture;
    const fileName = url.substring(url.lastIndexOf("/") + 1);

    fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new File([blob], `${fileName}`, {
            type: blob.type,
          })
      )
      .then((file) => {
        setImageFile(file);
      });
  }, [userProfile.profilePicture]);

  const inputStyle = {
    bg: "white",
    rounded: "lg",
    color: "#262636",
  };

  return (
    <Box>
      <Button
        onClick={onOpen}
        bg={bgColor}
        size="sm"
        color={cardBg}
        _hover={{ backgroundColor: `${bgColor}` }}
      >
        Edit Profile
      </Button>

      <Modal size="sm" isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalBody py="40px" bg="black">
            <label htmlFor="profile">
              <Avatar
                src={
                  profilePicture
                    ? profilePicture.imgUrl
                    : userProfile.profilePicture
                }
                size="xl"
                name={userProfile?.tenantName}
              >
                <AvatarBadge boxSize="0.9em" bg="#95c0fa" border="4px">
                  {" "}
                  <FiCamera />{" "}
                </AvatarBadge>
                <Input
                  onChange={handleImgUpload}
                  type="file"
                  id="profile"
                  display="none"
                />
              </Avatar>
            </label>

            <Stack spacing={8} borderColor="#F98B88" py="40px">
              <Input
                {...inputStyle}
                name="tenantName"
                placeholder="Enter your full name"
                // border="1px solid rgb(7,47,100) !important"
                border="1px solid #1D8C23 !important"
                onChange={changeHandler}
                value={tenantName}
              />
              <Input
                {...inputStyle}
                type="email"
                name="email"
                placeholder="Email Address"
                border="1px solid #1D8C23 !important"
                onChange={changeHandler}
                value={email}
              />
              <Input
                {...inputStyle}
                name="tenantPhone"
                placeholder="Phone Number"
                border="1px solid #1D8C23 !important"
                onChange={changeHandler}
                value={tenantPhone}
              />
              <Box py="5">
                <PrimaryButton
                  isLoading={isLoading}
                  // isDisabled={isDisabled}
                  width="100%"
                  height="40px"
                  color="#FFFFFF"
                  fontWeight="semibold"
                  btnText="Update Profile"
                  onClick={handleUpdateProfile}
                />
              </Box>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
