import { PROTECTED_PATHS } from "../../../app/constants";
import {
  errorNotifier,
  successNotifier,
} from "../../../components/NotificationHandler";
import { timeout } from "../../../components/utils/timeout";
import http, { AUTH_ROUTES } from "../../../services/api";

const { POLLS, VOTE } = PROTECTED_PATHS;

export const vote = async (
  setLoading,
  payload,
  setSuccessFullVote,
  setRefresh,
  navigate
) => {
  try {
    await http.put(AUTH_ROUTES.VOTE_AN_OPTION, payload);
    // setSuccessFullVote(true);
    successNotifier("Your vote was successful");
    setRefresh({});
    setLoading(false);

    // timeout(() => navigate(VOTE), 2000);
  } catch (e) {
    console.log("e", e.response);
    errorNotifier(e.response.data.result.message);
  } finally {
    setLoading(false);
  }
};

export const getPolls = async (setLoading, userId, setPolls) => {
  setLoading(true);
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_POLLS(userId));

    setPolls(data?.userPolls);
  } catch (e) {
    console.log("e", e.response);
  } finally {
    setLoading(false);
  }
};
export const getSinglePoll = async (setLoading, userId, pollId, setPoll) => {
  setLoading(true);
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_SINGLE_POLL(userId, pollId));

    setPoll(data?.userPolls[0]);
  } catch (e) {
    console.log("e", e.response);
  } finally {
    setLoading(false);
  }
};
