import SingleGuest from "../../my-estate/components/singleGuest";

const GuestList = ({ searchTerm, guests, displayedGuests, setRefresh }) => {
  return (
    <>
      {!searchTerm &&
        displayedGuests?.map((guest) => (
          <SingleGuest key={guest?._id} {...guest} setRefresh={setRefresh} />
        ))}

      {searchTerm &&
        !Number(searchTerm) &&
        guests
          ?.filter((contacts) =>
            searchTerm
              ? contacts?.name
                  .toLowerCase()
                  .includes(searchTerm.toLocaleLowerCase())
              : contacts
          )
          ?.map((guest) => (
            <SingleGuest key={guest?._id} {...guest} setRefresh={setRefresh} />
          ))}

      {searchTerm &&
        !isNaN(searchTerm) &&
        guests
          ?.filter((contact) => {
            const phoneNumber = contact.phone.split("").slice(4);
            const phoneWithZero = ["0", ...phoneNumber];

            return searchTerm
              ? phoneWithZero.join("").includes(searchTerm)
              : contact;
          })
          ?.map((guest) => (
            <SingleGuest key={guest?._id} {...guest} setRefresh={setRefresh} />
          ))}
    </>
  );
};

export default GuestList;
