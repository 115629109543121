import {
  Avatar,
  Box,
  Center,
  Flex,
  Img,
  ScaleFade,
  SlideFade,
  Text,
  useColorModeValue,
  Stack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Container from "../../components/Container";
import Header from "../../components/Header";
import Footer from "../../components/footer";
import FullPageLoader from "../../components/FullPageLoader";
import { COLORS } from "../../constants/colors";
import { transition } from "../..";

const Video = () => {
  const [loading, setLoading] = useState(true);
  const pageBg = useColorModeValue(COLORS.bgGrey, COLORS.darkModeBg);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    },4000);
    return () => clearTimeout(timer);
  }, []);
  return loading ? (
    <FullPageLoader />
  ) : (
    <SlideFade in={true} offsetY="500px" {...transition}>
      <Box>
        <Box shadow="sm" mb={8}>
          <Container>
            <Header menu />
          </Container>
        </Box>
        <Flex justify="center" align="center" height="calc(100vh - 100px)" minHeight="80vh"  py={4}>
          <Container>
            <Box>
              <Text fontSize="lg" textAlign="center" px={4} mb="10px">
                Use the playlist controls below to navigate between videos. You
                can scroll through the available videos in the list.
              </Text>

              <iframe
                width="100%"
                height="500"
                src={`https://www.youtube.com/embed/videoseries?list=PLiPHQO1Q8o_-Nt_q0sL7rwjzTIMBjJQIV`}
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
                title="YouTube Playlist"
              ></iframe>
            </Box>
          </Container>
        </Flex>

        <Footer />
      </Box>
    </SlideFade>
  );
};

export default Video;
