import { HStack, Img, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { RiSafe2Line } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { PROTECTED_PATHS } from '../../../app/constants';
import dots from '../../../assets/Group 131.svg';
import { PrimaryButton } from '../../../components/CustomButton';
// import CustomModal from '../../../components/CustomModal';
// import ConfirmPaymentModal from './ConfirmPaymentModal';

const ErrandCard = ({ currentErrandPrice }) => {
  const { MY_ERRANDS } = PROTECTED_PATHS;
  const navigate = useNavigate();
  return (
    <Stack
      bg='radial-gradient(circle, rgba(78,0,156,0.9612219887955182) 35%, rgba(35,0,74,1) 100%)'
      borderRadius='5px'
      h='200px'
      w='100%'
      my='5'
      px='8'
      py='5'
      justify='space-between'
      color='#ddd'
    >
      <HStack justify='space-between'>
        <Img src={dots} alt='dots' />
        <RiSafe2Line size={40} />
      </HStack>
      <HStack justify='space-between'>
        <Stack>
          <Text>Errand balance</Text>
          <Text
            m='0 !important'
            fontSize='2rem'
            fontWeight='500'
            lineHeight='1'
          >
            {currentErrandPrice}
            <Text as='span' fontSize='13px'>
              points
            </Text>
          </Text>
        </Stack>
        {/* <CustomModal btnTitle='Buy More' buttonProps={{ bg: 'primary' }}>
          <ConfirmPaymentModal />
        </CustomModal> */}
        <PrimaryButton
          onClick={() => navigate(MY_ERRANDS)}
          btnText='Buy More'
          bg='primary'
          w='120px'
        />
      </HStack>
    </Stack>
  );
};

export default ErrandCard;
