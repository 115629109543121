import http, { AUTH_ROUTES } from '../../../services/api';

export const getAdvertProducts = async (userId, setLoading, setAdvertProducts) => {
  setLoading(true);
  const params = { userId };

  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_ADVERT_PRODUCTS(params));
console.log(data, "top cat")
    setAdvertProducts(data.adverts);
  } catch (error) {
    console.log(error.response);
  } finally {
    setLoading(false);
  }
};

export const getSingleAdvertProduct = async (userId, productId, setLoading, setSingleTopProductDetails) => {
  setLoading(true);

  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_SINGLE_ADVERT_PRODUCT(userId, productId));

    setSingleTopProductDetails(data?.adverts[0]);
  } catch (e) {
    console.log(e.reponse);
  } finally {
    setLoading(false);
  }
};
