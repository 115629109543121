import {
  Box,
  Flex,
  SlideFade,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";
import { PROTECTED_PATHS } from "../../../app/constants";
import Container from "../../../components/Container";
import Header from "../../../components/Header";
import { AiFillPlusCircle } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import FullPageLoader from "../../../components/FullPageLoader";
import { useContext } from "react";
import { AuthContext } from "../../../context/authContext";
import { getSpecialRequests } from "../service/partyAndEvents";
import { transition } from "../../..";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { addBtnStyles } from "../../issue report";
import { COLORS } from "../../../constants/colors";

const { SPECIAL_GUEST_REQUEST, PARTY_EVENT_REQUEST, EDIT_REQUEST, ADD_GUEST } =
  PROTECTED_PATHS;

const PartyEvent = () => {
  const cardBg = useColorModeValue(COLORS.lightGrey, COLORS.white);
  const bgColor = useColorModeValue(COLORS.primary, COLORS.green);
  const { userInfo } = useContext(AuthContext);
  const { userId } = userInfo;
  const [loading, setLaoding] = useState(false);
  const [partyEvents, setPartyEvents] = useState([]);

  useEffect(() => {
    getSpecialRequests(userId, setLaoding, setPartyEvents);
  }, [userId]);

  return loading ? (
    <FullPageLoader />
  ) : (
    <SlideFade in={true} offsetX="-1000px" transition={{ ...transition }}>
      <Stack>
        <Box shadow="sm">
          <Container>
            <Header goto={SPECIAL_GUEST_REQUEST} />
          </Container>
        </Box>

        <Box className="special-request-plus">
          <Link to={PARTY_EVENT_REQUEST}>
            <Flex {...addBtnStyles}>
              <BsFillPlusCircleFill color={bgColor} size={"2.5rem"} />
            </Flex>
          </Link>
        </Box>

        {/*<Box className="special-request-plus">
          <Link to={PARTY_EVENT_REQUEST}>
            <AiFillPlusCircle size="50" />
          </Link>
  </Box>*/}
      </Stack>
    </SlideFade>
  );
};

export default PartyEvent;

const selectedTabStyles = {
  fontWeight: 600,
  borderBottom: "3px solid #FC7067",
};

// import {
//   Box,
//   Flex,
//   FormControl,
//   FormLabel,
//   Select,
//   SlideFade,
//   Stack,
//   Text,
//   Textarea,
//   useColorModeValue,
// } from "@chakra-ui/react";
// import { v4 } from "uuid";
// import { PROTECTED_PATHS } from "../../../app/constants";
// import Container from "../../../components/Container";
// import { PrimaryButton } from "../../../components/CustomButton";
// import Header from "../../../components/Header";
// // import { requestHeading } from "./data";
// // import { AiFillPlusCircle } from "react-icons/ai";
// import { Link, useNavigate } from "react-router-dom";
// import { useState, useEffect } from "react";
// import FullPageLoader from "../../../components/FullPageLoader";
// import { useContext } from "react";
// import { AuthContext } from "../../../context/authContext";
// import { getSpecialRequests } from "../service/partyAndEvents";
// import { dynamicPathhandler } from "../../../components/utils/dynamicPathHandler";
// import { transition } from "../../..";
// // import { Options } from "./Options";
// // import DatePicker from "react-multi-date-picker";
// // import dayjs from "dayjs";
// import { COLORS } from "../../../constants/colors";
// import CustomeSpecialReqestModal from "./CustomeSpecialReqestModal";

// const { SPECIAL_GUEST_REQUEST, PARTY_EVENT_REQUEST, EDIT_REQUEST, ADD_GUEST } =
//   PROTECTED_PATHS;

// const PartyEvent = () => {
//   const { userInfo } = useContext(AuthContext);
//   const { userId } = userInfo;
//   // const navigate = useNavigate();
//   const [loading, setLaoding] = useState(false);
//   const [partyEvents, setPartyEvents] = useState([]);
//   // const parentPath = dynamicPathhandler(EDIT_REQUEST);
//   // const addGuestParentPath = dynamicPathhandler(ADD_GUEST);
//   const cardBg = useColorModeValue(COLORS.primary, COLORS.white);

//   const inputStyle = {
//     bg: "white",
//     rounded: "lg",
//     color: "#262636",
//   };

//   useEffect(() => {
//     getSpecialRequests(userId, setLaoding, setPartyEvents);
//   }, [userId]);

//   const pendingEvents = partyEvents?.filter(
//     (partyEvent) => partyEvent.requestStatus === "pending"
//   );
//   const approvedEvents = partyEvents?.filter(
//     (partyEvent) => partyEvent.requestStatus === "approved"
//   );
//   const rejectedEvents = partyEvents?.filter(
//     (partyEvent) => partyEvent.requestStatus === "rejected"
//   );

//   return loading ? (
//     <FullPageLoader />
//   ) : (
//     <SlideFade in={true} offsetX="-1000px" transition={{ ...transition }}>
//       <Stack>
//         <Box shadow="sm">
//           <Container>
//             <Header goto={SPECIAL_GUEST_REQUEST} />
//           </Container>
//         </Box>

//         <Container>
//           <FormControl mb="50px" fontSize="12px">
//             <Text color={cardBg} fontSize={"18px"}>
//               Make a request from the estate management for your event
//             </Text>
//             <Flex pt="30px" pb="15px" w={"100%"} justify={"space-between"}>
//               <FormLabel color={cardBg} w={"50%"}>
//                 Number of Guests
//                 <Select
//                   {...inputStyle}
//                   name="subType"
//                   color={COLORS.green}
//                   placeholder="Select"
//                   mt="5px"
//                 >
//                   <option>option</option>
//                 </Select>
//               </FormLabel>
//               <FormLabel color={cardBg} w={"50%"}>
//                 Date
//                 <Select
//                   {...inputStyle}
//                   name="subType"
//                   color={COLORS.green}
//                   placeholder="Select"
//                   mt="5px"
//                 >
//                   <option>option</option>
//                 </Select>
//               </FormLabel>
//             </Flex>
//             <Stack pb="50px" spacing="15px">
//               <FormLabel color={cardBg}>
//                 Venue for Event
//                 <Select
//                   {...inputStyle}
//                   name="subType"
//                   color={COLORS.green}
//                   placeholder="Estate Hall"
//                   mt="5px"
//                 >
//                   <option>option</option>
//                 </Select>
//               </FormLabel>

//               <Text color={cardBg} fontSize={"16px"}>
//                 Reason/Description for Event
//               </Text>
//               <Textarea
//                 {...inputStyle}
//                 mt={"2px !important"}
//                 rows="6"
//                 resize="none"
//                 name="description"
//                 placeholder="Type here..."
//                 _placeholder={{ opacity: 1, color: "black" }}
//               />
//             </Stack>
//             <CustomeSpecialReqestModal
//               // allowToOpen={allowToOpen}
//               btnTitle={<PrimaryButton btnText="Send Request" />}
//             ></CustomeSpecialReqestModal>
//           </FormControl>
//         </Container>
//       </Stack>
//     </SlideFade>
//   );
// };

// export default PartyEvent;

// import { Box, HStack, SlideFade, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
// import { v4 } from 'uuid';
// import { PROTECTED_PATHS } from '../../../app/constants';
// import Container from '../../../components/Container';
// import { PrimaryButton } from '../../../components/CustomButton';
// import Header from '../../../components/Header';
// import { requestHeading } from './data';
// import { AiFillPlusCircle } from 'react-icons/ai';
// import { Link, useNavigate } from 'react-router-dom';
// import { useState, useEffect } from 'react';
// import FullPageLoader from '../../../components/FullPageLoader';
// import { useContext } from 'react';
// import { AuthContext } from '../../../context/authContext';
// import { getSpecialRequests } from '../service/partyAndEvents';
// import { dynamicPathhandler } from '../../../components/utils/dynamicPathHandler';
// import { transition } from '../../..';
// import { Options } from './Options';

// const { SPECIAL_GUEST_REQUEST, PARTY_EVENT_REQUEST, EDIT_REQUEST, ADD_GUEST } = PROTECTED_PATHS;

// const PartyEvent = () => {
//   const { userInfo } = useContext(AuthContext);
//   const { userId } = userInfo;
//   const navigate = useNavigate();
//   const [loading, setLaoding] = useState(false);
//   const [partyEvents, setPartyEvents] = useState([]);
//   const parentPath = dynamicPathhandler(EDIT_REQUEST);
//   const addGuestParentPath = dynamicPathhandler(ADD_GUEST);

//   useEffect(() => {
//     getSpecialRequests(userId, setLaoding, setPartyEvents);
//   }, [userId]);

//   const pendingEvents = partyEvents?.filter((partyEvent) => partyEvent.requestStatus === 'pending');
//   const approvedEvents = partyEvents?.filter((partyEvent) => partyEvent.requestStatus === 'approved');
//   const rejectedEvents = partyEvents?.filter((partyEvent) => partyEvent.requestStatus === 'rejected');

//   return loading ? (
//     <FullPageLoader />
//   ) : (
//     <SlideFade in={true} offsetX='-1000px' transition={{ ...transition }}>
//       <Stack>
//         <Box shadow='sm'>
//           <Container>
//             <Header goto={SPECIAL_GUEST_REQUEST} />
//           </Container>
//         </Box>

//         <Box>
//           <Tabs isFitted p='5'>
//             <TabList mb='1em'>
//               {requestHeading?.map((tab) => (
//                 <Tab mx='5px' _selected={{ ...selectedTabStyles }} fontWeight={500} _focus={{ border: 'none' }} key={v4()}>
//                   {tab}
//                 </Tab>
//               ))}
//             </TabList>
//             <TabPanels bg='gray.100'>
//               <TabPanel>
//                 {pendingEvents?.map((partyEvent, index) => (
//                   <HStack justify='space-between' py='3' key={v4()}>
//                     <HStack fontSize='1rem'>
//                       <Text mr='2'>{index + 1}</Text>
//                       <Text maxW='190px' isTruncated>
//                         {partyEvent?.subType}
//                       </Text>
//                     </HStack>
//                   </HStack>
//                 ))}
//               </TabPanel>
//               <TabPanel>
//                 {approvedEvents?.map((partyEvent, index) => (
//                   <HStack justify='space-between' py='3' key={v4()}>
//                     <HStack fontSize='1rem' align='start'>
//                       <Text fontSize='1rem' mr='2'>
//                         {index + 1}
//                       </Text>
//                       <Box>
//                         <Text maxW='190px' isTruncated>
//                           {partyEvent?.subType}
//                         </Text>
//                         <Text fontWeight='600' fontSize='12px'>{`${partyEvent?.guestList?.length} of ${partyEvent?.numberOfGuests} guests`}</Text>
//                       </Box>
//                     </HStack>
//                     <HStack>
//                       <PrimaryButton
//                         h='30px'
//                         w='90px'
//                         btnText='Add guest'
//                         fontSize='14px'
//                         onClick={() => navigate(`${addGuestParentPath}/${partyEvent?._id}`)}
//                       />

//                       <Options inviteCode={partyEvent?.inviteCode} path={`${addGuestParentPath}/${partyEvent?._id}`} />
//                     </HStack>
//                   </HStack>
//                 ))}
//               </TabPanel>
//               <TabPanel>
//                 {rejectedEvents?.map((partyEvent, index) => (
//                   <HStack justify='space-between' py='3' key={v4()}>
//                     <HStack fontSize='1rem'>
//                       <Text fontSize='1rem' mr='2'>
//                         {index + 1}
//                       </Text>
//                       <Text maxW='190px' isTruncated>
//                         {partyEvent?.subType}
//                       </Text>
//                     </HStack>
//                     <PrimaryButton
//                       h='30px'
//                       w='70px'
//                       btnText='Re-apply'
//                       fontSize='14px'
//                       onClick={() => navigate(`${parentPath}/${partyEvent?._id}`)}
//                     />
//                   </HStack>
//                 ))}
//               </TabPanel>
//             </TabPanels>
//           </Tabs>
//           <Box className='special-request-plus'>
//             <Link to={PARTY_EVENT_REQUEST}>
//               <AiFillPlusCircle size='50' />
//             </Link>
//           </Box>
//         </Box>
//       </Stack>
//     </SlideFade>
//   );
// };

// export default PartyEvent;

// const selectedTabStyles = {
//   fontWeight: 600,
//   borderBottom: '3px solid #FC7067',
// };
