import { timeout } from '../../../components/utils/timeout';
import http, { AUTH_ROUTES } from '../../../services/api';

export const subscribeToErrand = async (setLoading, payload, onClose, setSuccess) => {
  setLoading(true);

  try {
    await http.post(AUTH_ROUTES.SUBSCRIBE_TO_ERRAND, payload);

    setSuccess(true);
    timeout(() => setSuccess(false), 2000);
    onClose();
  } catch (e) {
    console.log(e.response);
  } finally {
    setLoading(false);
  }
};
