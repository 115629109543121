import { Box, HStack, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import Emergency from "../pages/emergency";
import CustomDrawer from "./CustomDrawer";
import Sidebar from "./nav/Sidebar";
import { HiMenuAlt1 } from "react-icons/hi";
import { getComponentTitle } from "./nav/GetComponentTitle";
import { useLocation } from "react-router-dom";
import { PROTECTED_PATHS } from "../app/constants";
import { BsArrowLeft } from "react-icons/bs";
import { Link } from "react-router-dom";
import { COLORS } from "../constants/colors";

const Header = ({ menu, goto }) => {
  const appTextColor = useColorModeValue(COLORS.primary, COLORS.white);
  const { pathname } = useLocation();
  const { MY_ESTATE } = PROTECTED_PATHS;

  return (
    <HStack justify="space-between" py="4" align="center" color={appTextColor}>
      {menu ? (
        <CustomDrawer btn={<HiMenuAlt1 size="1.5rem" />}>
          <Sidebar />
        </CustomDrawer>
      ) : (
        <Box>
          <Link to={goto || MY_ESTATE}>
            <BsArrowLeft size={25} />
          </Link>
        </Box>
      )}

      <Text fontSize="lg" fontWeight="bold" color={appTextColor}>
        {getComponentTitle(pathname)}
      </Text>
      <Emergency />
    </HStack>
  );
};

export default Header;
