import { HStack, Stack, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { calculateDaysBetweenDates } from '../service/addFromContact';
import { boldtext } from './inviteData';

const   RangeSummary = ({ customVisitValues }) => {
  return (
    <Stack>
      <Text fontSize='1.3rem' pb='4' fontWeight='500' textAlign={'center'}>
        Invitation summary
      </Text>
      <HStack>
        <Text w='110px'>Name: </Text>
        <Text {...boldtext}>{customVisitValues.name} </Text>
      </HStack>
      <HStack>
        <Text w='110px'>Phone number: </Text>
        <Text {...boldtext}>{customVisitValues.phone} </Text>
      </HStack>
      <HStack>
        <Text w='110px'>Allow Company: </Text>
        <Text {...boldtext}>
          {customVisitValues.allowCompany ? 'Yes' : 'No'}
        </Text>
      </HStack>
      <HStack>
        <Text w='180px'>Period of visit:</Text>
        <Text {...boldtext} fontSize='small'>
          {`Between ${dayjs(customVisitValues.startDate).format(
            'dddd'
          )} ${dayjs(customVisitValues.startDate).format(
            'DD MMMM YYYY'
          )} and  ${dayjs(customVisitValues.endDate).format('dddd')} ${dayjs(
            customVisitValues.endDate
          ).format('DD MMMM YYYY')}`}
        </Text>
      </HStack>
      {/* <HStack> */}
      {/* <Text w='110px'>Number of days:</Text>
        <Text {...boldtext}>
          {`${calculateDaysBetweenDates(
            customVisitValues.startDate,
            customVisitValues.endDate
          )} day(s)`}
        </Text> */}
      {/* </HStack> */}
      <Text fontSize='15px' pt='3' textAlign='center' fontWeight='600'>
        You have Selected a total of{' '}
        {`${calculateDaysBetweenDates(
          customVisitValues.startDate,
          customVisitValues.endDate
        )} day(s)`}{' '}
      </Text>
      <Text fontWeight='500' fontSize='14px' py='2' textAlign='center'>
        Note that your guest will access your estate every day during the
        allowed date range
      </Text>
    </Stack>
  );
};

export default RangeSummary;
