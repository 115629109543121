import { PROTECTED_PATHS } from "../../../app/constants";
import {
  errorNotifier,
  successNotifier,
} from "../../../components/NotificationHandler";
import { timeout } from "../../../components/utils/timeout";
import http, { AUTH_ROUTES } from "../../../services/api";

export const reportIssue = async (
  payload,
  setLoading,
  navigate,
  setSuccess
) => {
  const { ISSUE_REPORTS_HISTORY } = PROTECTED_PATHS;

  setLoading(true);
  try {
    const {
      data: { result },
    } = await http.post(AUTH_ROUTES.REPORT_ISSUE, payload);

    successNotifier(result?.message);
    setSuccess(true);
    timeout(() => navigate(ISSUE_REPORTS_HISTORY), 3000);
  } catch (e) {
    console.log(e?.response);
    errorNotifier(e.response?.data?.errors[0].msg);
  } finally {
    setLoading(false);
  }
};

export const deleteIssue = async (userId, issueId) => {
  try {
    const { data } = await http.delete(
      AUTH_ROUTES.DELETE_ISSUE(userId, issueId)
    );
    successNotifier(data.result.message);
  } catch (e) {
    errorNotifier(e.response.data.result.message);
  }
};
