import { successNotifier, errorNotifier } from '../../../components/NotificationHandler';
import http, { AUTH_ROUTES } from '../../../services/api';

export const createEmergency = async (payload, setLoading, onClose) => {
  try {
    setLoading(true);
    const {
      data: { result },
    } = await http.post(AUTH_ROUTES.CREATE_EMERGENCY, payload);

    setLoading(false);
    successNotifier(result.message);
    onClose();
  } catch (e) {
    errorNotifier(e.response.data.message);
    setLoading(false);
  }
};
