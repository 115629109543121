import { PROTECTED_PATHS } from '../../../app/constants';
import {
  errorNotifier,
  successNotifier,
} from '../../../components/NotificationHandler';
import { timeout } from '../../../components/utils/timeout';
import http, { AUTH_ROUTES } from '../../../services/api';

const { SHOP } = PROTECTED_PATHS;

export const getArchievedProducts = async (
  userId,
  setLoading,
  setArchievedProducts
) => {
  setLoading(true);
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_USER_ARCHIEVED_PRODUCTS(userId));

    setArchievedProducts(data?.adverts);
  } catch (e) {
    console.log(e.response);
    setArchievedProducts([]);
  } finally {
    setLoading(false);
  }
};

export const getUserActiveProducts = async (
  userId,
  setLoading,
  setUserActiveProducts
) => {
  setLoading(true);
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_USER_ACTIVE_PRODUCTS(userId));

    setUserActiveProducts(data?.adverts);
  } catch (e) {
    console.log(e.response);
    setUserActiveProducts([]);
  } finally {
    setLoading(false);
  }
};

export const getSingleActiveProduct = async (
  userId,
  setLoading,
  setSingleActiveProduct,
  productId
) => {
  setLoading(true);
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(
      AUTH_ROUTES.GET_SINGLE_USER_ACTIVE_PRODUCT(userId, productId)
    );

    setSingleActiveProduct(data?.adverts[0]);
  } catch (e) {
    console.log(e.response);
    setSingleActiveProduct([]);
  } finally {
    setLoading(false);
  }
};

export const getSingleArchieveProduct = async (
  userId,
  setLoading,
  setSingleActiveProduct,
  productId
) => {
  setLoading(true);
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(
      AUTH_ROUTES.GET_SINGLE_ARCHIEVED_PRODUCT(userId, productId)
    );

    setSingleActiveProduct(data?.adverts[0]);
  } catch (e) {
    console.log('arc', e.response);
    setSingleActiveProduct([]);
  } finally {
    setLoading(false);
  }
};

export const archieveProduct = async (payload, setLoading, navigate) => {
  setLoading(true);

  try {
    const {
      data: { result },
    } = await http.put(
      AUTH_ROUTES.ARCHIEVE_PRODUCT(payload.productId),
      payload
    );

    successNotifier(result.message);
    timeout(() => navigate(SHOP), 500);
  } catch (e) {
    console.log(e.response);
    errorNotifier(e.response.data);
  } finally {
    setLoading(false);
  }
};

export const deleteUserProduct = async (payload, setLoading, navigate) => {
  setLoading(true);
  try {
    const {
      data: { result },
    } = await http.delete(
      AUTH_ROUTES.DELETE_USER_PRODUCT(payload.userId, payload.productId)
    );

    successNotifier(result.message);
    timeout(() => navigate(SHOP), 100);
  } catch (e) {
    console.log(e.response);
    errorNotifier(e.response);
  } finally {
    setLoading(false);
  }
};

export const resellProduct = async (payload, setBtnLoading, navigate) => {
  setBtnLoading(true);
  try {
    const { data } = await http.post(AUTH_ROUTES.RESELL_PRODUCT, payload);
    console.log('resell', data);
    successNotifier(
      'Product is reactivated and can now be seen in the markeplace'
    );

    timeout(() => navigate(SHOP), 500);
  } catch (err) {
    console.log(err.response);
    errorNotifier();
  } finally {
    setBtnLoading(false);
  }
};
export const editBeforeResell = async (
  payload,
  setBtnLoading,
  navigate,
  setSuccess
) => {
  setBtnLoading(true);
  try {
    const { data } = await http.post(AUTH_ROUTES.RESELL_PRODUCT, payload);
    console.log('resell', data);
    successNotifier(
      'Product is reactivated and can now be seen in the markeplace'
    );
    // successNotifier(data.result.message);
    setSuccess(true);
    timeout(() => navigate(SHOP), 2000);
  } catch (err) {
    console.log(err.response);
    errorNotifier();
  } finally {
    setBtnLoading(false);
  }
};
