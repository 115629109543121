import { Box, Grid, GridItem, Img, Stack, Text, SlideFade, useColorModeValue } from '@chakra-ui/react';
import Container from '../../../components/Container';
import Header from '../../../components/Header';
import Footer from '../../../components/footer';
import { PrimaryButton } from '../../../components/CustomButton';
import { Success } from '../../../components/Success';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { reportIssue } from '../services/reportIssue';
import { AuthContext } from '../../../context/authContext';
import axios from 'axios';
import { UserProfile } from '../../../context/userProfileContext';
import { COLORS } from '../../../constants/colors';

const OneIssue = ({ media, description, issueType, title }) => {
    const cardBg = useColorModeValue(COLORS.primary, COLORS.white);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  // const { userInfo } = useContext(AuthContext);
  // const { userId, tenantCode } = userInfo;
  const { userProfile } = UserProfile();
  const { _id, tenantCode } = userProfile;

  let img = [];
  const payload = {
    userId: _id,
    tenantCode,
    issueType,
    title,
    description,
    media: img,
  };

  const handleSendReport = (files) => {
    // Push all the axios request promise into a single array
    const uploaders = files.map((file) => {
      // Initial FormData
      const formData = new FormData();
      formData.append('file', file.file);

      formData.append('upload_preset', process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET);

      return axios
        .post(`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`, formData, {
          headers: { 'X-Requested-With': 'XMLHttpRequest' },
        })
        .then((response) => {
          const data = response.data;
          const fileURL = data.secure_url; // You should store this URL for future references in your app

          img.push(fileURL);
        });
    });

    // Once all the files are uploaded
    axios.all(uploaders).then(() => {
      // ... perform after upload is successful operation
      reportIssue(payload, setLoading, navigate, setSuccess);
    });
  };

  return success ? (
    <Success />
  ) : (
    <SlideFade in={true} offsetX="-2000px">
      <Box>
        <Box shadow="sm">
          <Container>
            <Header menu />
          </Container>
        </Box>

        <Container>
          <Stack mt="5" spacing="4">

            <Text
              fontSize="1.2rem"
              color={cardBg}
            >
              {title}
            </Text>

            <Text py="3" color={cardBg} fontSize="18px">
              {description}
            </Text>
            <Grid templateColumns="repeat(1, 1fr)" gap="1rem">
              {media?.map((pic, index) => (
                <GridItem key={index} h="250px" m="2" bg="gray.100">
                  <Img
                    mx="auto"
                    h="100%"
                    w="100%"
                    objectFit="cover"
                    borderRadius="5px"
                    src={pic.imgUrl}
                    alt="issue image"
                  />
                </GridItem>
              ))}
            </Grid>
            <Box pb="17vh" pt="7vh" mx="10%">
              <PrimaryButton
                onClick={() => handleSendReport(media)}
                btnText="Send report"
                isLoading={loading}
              />
            </Box>
          </Stack>
        </Container>
        <Footer />
      </Box>
    </SlideFade>
  );
};

export default OneIssue;
