import { Box, Image, Text } from '@chakra-ui/react';
import React from 'react';
import newsImage from '../../../assets/boyImage.jpg';

const SingleEvents = () => {
  return (
    <Box maxW='500px' w={['75%']}>
      <Image
        h={['180px']}
        borderRadius='5px'
        src={newsImage}
        alt='news-image'
      />
      <Text fontWeight='500'>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit.
      </Text>
      <Text fontSize='12px'>an hour ago</Text>
    </Box>
  );
};

export default SingleEvents;
