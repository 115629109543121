import { PROTECTED_PATHS } from '../../../app/constants';
import { errorNotifier } from '../../../components/NotificationHandler';
import { timeout } from '../../../components/utils/timeout';
import http, { AUTH_ROUTES } from '../../../services/api';

export const getCablePlans = async (userId, setLoading, setCablePlans) => {
  setLoading(true);
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.GET_CABLE_PLANS(userId));

    setCablePlans(data?.cablePlans);
  } catch (e) {
    console.log(e.response);
  } finally {
    setLoading(false);
  }
};

export const payCableSubscription = async (payload, setLoading, setSuccess, navigate) => {
  const { MY_WALLET } = PROTECTED_PATHS;
  setLoading(true);

  try {
    await http.post(AUTH_ROUTES.PAY_CABLE_SUBSCRIPTION, payload);

    setSuccess(true);
    timeout(() => navigate(MY_WALLET));
  } catch (e) {
    console.log(e.response);
    errorNotifier(e.response.data.result.data);
  } finally {
    setLoading(false);
  }
};
