export const requestHeading = [
  'Pending Request',
  'Approved Request',
  'Disapprove Request',
];

export const contractorArtisanType = [
  'Bricklayer',
  'Carpenter',
  'Welder',
  'Plumber',
];

export const vehicleType = ['Maruwa', 'Car', 'Truck', 'Motorcyclye'];
