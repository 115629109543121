import { HStack, Img, Stack, Text } from '@chakra-ui/react';
import newsImage from '../../../assets/boyImage.jpg';

const SingleNews = () => {
  return (
    <HStack my='1' bg='#fff' shadow='md' borderRadius='5px'>
      <Img
        w={['120px']}
        maxW='120px'
        h={['100px']}
        borderRadius='5px'
        src={newsImage}
        alt='news-image'
      />
      <Stack fontWeight='bold' color='#7A7A7A'>
        <Text>Lorem ipsum dolor</Text>
        <Text m='0 !important'>sit amet, conse</Text>
        <Text fontSize='12px' m='0 !important'>
          Lorem ipsum dolor sit amet.
        </Text>
        <Text fontWeight='normal' fontSize='12px'>
          7 hour ago
        </Text>
      </Stack>
    </HStack>
  );
};

export default SingleNews;
