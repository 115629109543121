import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  SlideFade,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { COLORS } from "../../../constants/colors";
import dayjs from "dayjs";
import { Popover } from "../../../components/Popover";
import Container from "../../../components/Container";
import { transition } from "../../..";
import Header from "../../../components/Header";
import { PROTECTED_PATHS } from "../../../app/constants";
import { BsFillPlusCircleFill, BsSearch } from "react-icons/bs";
import { Link } from "react-router-dom";
import { addBtnStyles } from "../../issue report";
import { getSpecialRequests } from "../service/partyAndEvents";
import { AuthContext } from "../../../context/authContext";
import { PartyDetailsModal } from "./PartyDetailsModal";

export const EventList = () => {
  const cardBg = useColorModeValue(COLORS.lightGrey, COLORS.white);
  const bgColor = useColorModeValue(COLORS.primary, COLORS.green);
  const appTextColor = useColorModeValue(COLORS.primary, COLORS.primary);
  const { SPECIAL_GUEST_REQUEST, PARTY_EVENT_REQUEST } = PROTECTED_PATHS;
  const [partyEvents, setPartyEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const { userInfo } = useContext(AuthContext);
  const { userId } = userInfo;
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    getSpecialRequests(userId, setLoading, setPartyEvents);
  }, [userId, ignored]);

  return (
    <SlideFade in={true} offsetX="-1000px" transition={{ ...transition }}>
      <Stack>
        <Box shadow="sm">
          <Container>
            <Header goto={SPECIAL_GUEST_REQUEST} />
          </Container>
        </Box>
        <Container>
          <InputGroup my={"50px"}>
            <InputLeftElement fontSize="1.2em" children={<BsSearch />} />
            <Input
              border="none"
              borderRadius="30px !important"
              placeholder="Search Event"
              _placeholder={{ opacity: 1, color: `${appTextColor}` }}
              color={cardBg}
              bg={COLORS.lightBrown}
            />
          </InputGroup>
          {partyEvents?.map((events) => (
            <>
              <Box
                key={events?._id}
                p="1rem"
                borderRadius="10px"
                boxShadow="sm"
                bg={cardBg}
                color={appTextColor}
                mb="20px"
              >
                <Flex justify="space-between" align="center">
                  <Stack fontSize="0.8rem" lineHeight={1}>
                    <Text
                      fontSize="1.1rem"
                      fontWeight="semibold"
                      color={COLORS.black}
                    >
                      {events?.type || "--"}
                    </Text>
                    <Text>
                      Day of event{" "}
                      {dayjs(events?.dates[0] || "--").format("DD/MMM/YYYY")}
                    </Text>
                    <Text>{events?.address || "--"}</Text>
                  </Stack>
                  <Box alignSelf={"flex-start"}>
                    <PartyDetailsModal
                      props={events}
                      forceUpdate={forceUpdate}
                    />
                  </Box>
                </Flex>
              </Box>
            </>
          ))}

          <Link to={PARTY_EVENT_REQUEST}>
            <Flex {...addBtnStyles}>
              <BsFillPlusCircleFill color={bgColor} size={"2.5rem"} />
            </Flex>
          </Link>
        </Container>
      </Stack>
    </SlideFade>
  );
};
