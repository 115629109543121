import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Select,
  SlideFade,
  Stack,
  Text,
  Textarea,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { transition } from "../../..";
import Container from "../../../components/Container";
import { PrimaryButton } from "../../../components/CustomButton";
import Header from "../../../components/Header";
import { COLORS } from "../../../constants/colors";
import { PROTECTED_PATHS } from "../../../app/constants";
export const CreatePolls = () => {
  const cardBg = useColorModeValue(COLORS.lightGrey, COLORS.white);
  const bgColor = useColorModeValue(COLORS.primary, COLORS.green);
  const appTextColor = useColorModeValue(COLORS.primary, COLORS.primary);
  const { POLLS } = PROTECTED_PATHS;

  const inputStyle = {
    bg: "white",
    rounded: "lg",
    color: "#262636",
  };

  return (
    <>
      <SlideFade in={true} offsetX="-1000px" transition={{ ...transition }}>
        <Stack>
          <Box shadow="sm">
            <Container>
              <Header goto={POLLS} />
            </Container>
          </Box>
          <Container>
            <FormControl minH="80vh">
              <Stack mb="50px" spacing="15px">
                <FormLabel color={cardBg} w="100%">
                  Ask your questions
                  <Textarea
                    {...inputStyle}
                    name="description"
                    placeholder="Type here..."
                    _placeholder={{ opacity: 1, color: "black" }}
                    resize="none"
                    rows="5"
                    my="8px"
                  />
                </FormLabel>
                <FormLabel>
                  <Select {...inputStyle}>
                    <option>Choose Category</option>
                  </Select>
                </FormLabel>
                <Box color={appTextColor} mt={"100px"}>
                  <Text color={cardBg} my="20px">
                    Choose your options
                  </Text>
                  <Button bg={cardBg} w={"100%"} mb="20px">
                    Option 1
                  </Button>
                  <Button bg={cardBg} w={"100%"} mb="20px">
                    Option 2
                  </Button>
                  <Button bg={cardBg} w={"100%"}>
                    Option 3
                  </Button>
                </Box>
              </Stack>
              <PrimaryButton
                // onClick={() => setSuccess(t
                //   onClick={}
                btnText="Ask Anonymously"
              />
            </FormControl>
          </Container>
        </Stack>
      </SlideFade>
    </>
  );
};
