import { Flex } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { billOptionsStyle } from './data';

const BillPicker = ({ to, children, ...props }) => {
  const navigate = useNavigate();
  return (
    <Flex {...billOptionsStyle} onClick={() => navigate(to)} {...props}>
      {children}
    </Flex>
  );
};

export default BillPicker;
