import { isValidElement, Children, cloneElement } from "react";
import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Box,
  useColorModeValue,
  Button,
} from "@chakra-ui/react";
import { infoNotifier } from "../../../components/NotificationHandler";
import { COLORS } from "../../../constants/colors";

export default function ConfirmCardPayment({
  children,
  size,
  btnTitle,
  buttonProps,
  addCloseModalBtn,
  overlayBG,
  allowToOpen,
}) {
  const cardBg = useColorModeValue(COLORS.primary, COLORS.white);
  const { onOpen, isOpen, onClose } = useDisclosure();
  const childrenWithProps = Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a TS error too.
    if (isValidElement(child)) {
      return cloneElement(child, { onClose });
    }

    return child;
  });

  return (
    <>
      <Button
        onClick={() =>
          allowToOpen ? onOpen() : infoNotifier("fill all empty fields")
        }
        cursor="pointer"
        {...buttonProps}
      >
        {btnTitle}
      </Button>

      <ChakraModal
        size={size || "xs"}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        motionPreset="slideInBottom"
      >
        <ModalOverlay bg={overlayBG} />
        <ModalContent py={5} borderRadius="10px">
          {addCloseModalBtn && <ModalCloseButton />}
          <ModalBody py={{ base: 4, md: 4, lg: 6 }}>
            {childrenWithProps}
          </ModalBody>
        </ModalContent>
      </ChakraModal>
    </>
  );
}
