import {
  Box,
  Img,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";
import { BsSearch } from "react-icons/bs";
import filterIcon from "../../../assets/Layer 32.svg";
import { COLORS } from "../../../constants/colors";

const SearchBar = ({ to, input, setInput }) => {
  const appTextColor = useColorModeValue(COLORS.black, COLORS.black);

  return (
    <Stack spacing={4}>
      <InputGroup rounded="50px">
        <InputLeftElement fontSize="1.2em" children={<BsSearch />} />
        <Input
          value={input}
          onChange={(e) => setInput(e.target.value)}
          border="none"
          rounded="50px"
          bg="white"
          placeholder="Search..."
          _placeholder={{ opacity: 1, color: `${appTextColor}` }}
        />
        {/*<InputRightElement>
          <Filter />
  </InputRightElement>*/}
      </InputGroup>
    </Stack>
  );
};

export default SearchBar;

const Filter = () => {
  return (
    <Menu>
      <MenuButton cursor="pointer" as={Box}>
        <Img src={filterIcon} alt="filter" fontSize="1.2em" />
      </MenuButton>
      <MenuList>
        <MenuItem>Download</MenuItem>
        <MenuItem>Create a Copy</MenuItem>
        <MenuItem>Mark as Draft</MenuItem>
        <MenuItem>Delete</MenuItem>
        <MenuItem>Attend a Workshop</MenuItem>
      </MenuList>
    </Menu>
  );
};
