import http, { AUTH_ROUTES } from "../../../services/api";
import {
  errorNotifier,
  successNotifier,
} from "../../../components/NotificationHandler";
import { timeout } from "../../../components/utils/timeout";

export const paySpecialRequest = async (
  payload,
  setLoading,
  setSuccess,
  setPayStackModalOpen,
  setInitializationResponse
) => {
  setLoading(true);

  try {
    const { data } = await http.post(AUTH_ROUTES.PAY_SPECIAL_REQUEST, payload);
    // successNotifier(data?.message);
    setInitializationResponse(data?.data);
    setPayStackModalOpen(true);
  } catch (e) {
    console.log(e.response);
    errorNotifier(e.response.data.message);
    setSuccess(false);
  } finally {
    setLoading(false);
  }
};

export const verifiySpecialRequestPayment = async (
  payload,
  setSuccess,
  setLoading,
  setVerifyResponse,
  setPayStackModalOpen
) => {
  setLoading(true);
  try {
    const {
      data: { result },
    } = await http.post(AUTH_ROUTES.VERIFY_SPECIAL_REQUEST, payload);

    timeout(() => setSuccess(true), 1000);
    successNotifier(result?.message);
    setVerifyResponse(result?.data);
  } catch (e) {
    // console.log(e.response);
    setPayStackModalOpen(false);
    errorNotifier(e.respons.data.result.message);
  } finally {
    setLoading(false);
  }
};

export const getVenues = async (userId, setLoading, setVeues) => {
  setLoading(true);

  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_VENUE(userId));

    setVeues(data?.venues);
  } catch (e) {
    console.log(e.respons);
  } finally {
    setLoading(false);
  }
};

export const getPricesForSpecialRequest = async (userId, setRequestFees) => {
  // setLoading(true);
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_SPECIAL_REQUEST_PRICES(userId));

    setRequestFees(data);
  } catch (e) {
    console.log(e.response);
  }
};

export const deleteSpecialRequest = async (
  payload,
  setLoading,
  userId,
  requestId,
  
) => {
  setLoading(true);
  try {
    const { data } = await http.delete(
      AUTH_ROUTES.DELETE_SPECIAL_REQUEST(userId, requestId),
      payload
    );
    console.log("data", data);
    successNotifier("Guest deleted successfully");
    // setRefresh({});
  } catch (err) {
    console.log(err.response);
  } finally {
    setLoading(false);
  }
};
