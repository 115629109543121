import http, { AUTH_ROUTES } from "../../../services/api";

export const getMarketplaceBanner = async (userId, setLoading, setBanner) => {
  setLoading(true);
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_MARKET_BANNER(userId));

    setBanner(data?.banners);
  } catch (error) {
  } finally {
    setLoading(false);
  }
};

export const getCategories = async (userId, setLoading, setCategories) => {
  setLoading(true);
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_CATEGORIES(userId));

    setCategories(data?.categories);
    console.log("***", data);
  } catch (err) {
    console.log(err.response, "LLLL");
  } finally {
    setLoading(false);
  }
};
export const getCategoryList = async (
  userId,
  category,
  setLoading,
  setCategoryList
) => {
  setLoading(true);
  const params = { userId, category };

  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_CATEGORY_LIST(params));

    console.log(data, ">>>>>>>>>");
    setCategoryList(data?.adverts);
  } catch (err) {
    console.log(err.response, "<<<<<<<<<<");
  } finally {
    setLoading(false);
  }
};

export const getSearchAdvertProductsInCategory = async (
  userId,
  category,
  searchKey,
  searchValue,
  setAllAdvertsInCategory
) => {
  try {
    const params = { userId, category, searchKey, searchValue };

    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.SEARCH_AND_FILTER_ADVERT_PRODUCTS(params));

    setAllAdvertsInCategory(data?.adverts);
  } catch (err) {
    console.log(err.response);
    setAllAdvertsInCategory([]);
  }
};

export const getAdvertProductsByCategory = async (
  userId,
  setLoading,
  setAllAdvertsInCategory,
  category
) => {
  setLoading(true);
  const params = { userId, category };

  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_ADVERT_PRODUCTS_BY_CATEGORY(params));
    console.log(data, ">>>>>>>");

    setAllAdvertsInCategory(data.adverts);
  } catch (error) {
    console.log(error.response, ">>>>>>>");
  } finally {
    setLoading(false);
  }
};
