import { Center, Text } from '@chakra-ui/react';
import { cardStyles } from './data';
import { GiPowerLightning } from 'react-icons/gi';
import { AiOutlineAntCloud } from 'react-icons/ai';
import { GiPhone } from 'react-icons/gi';

export const PayBills = () => {
  return (
    <Center {...cardStyles}>
      {/* <Img w='50px' h='50px' src={naira} alt='naira' /> */}
      <GiPowerLightning size='40px' />
      <Text pt='2' fontSize='12px'>
        Pay Bill
      </Text>
    </Center>
  );
};

export const BuyData = () => {
  return (
    <Center {...cardStyles}>
      {/* <Img w='50px' h='50px' src={naira} alt='naira' /> */}
      <AiOutlineAntCloud size='40px' />
      <Text pt='2' fontSize='12px'>
        Buy Data
      </Text>
    </Center>
  );
};

export const BuyAirtime = () => {
  return (
    <Center {...cardStyles}>
      {/* <Img w='50px' h='50px' src={naira} alt='naira' /> */}

      <GiPhone size='40px' />
      <Text pt='2' fontSize='12px'>
        Buy Airtime
      </Text>
    </Center>
  );
};
