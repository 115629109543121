import {
  Avatar,
  Box,
  Center,
  HStack,
  Image,
  SlideFade,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { RiCloseFill } from 'react-icons/ri';
import { useParams } from 'react-router-dom';
import { v4 } from 'uuid';
import { transition } from '../../..';
import { PROTECTED_PATHS } from '../../../app/constants';
import CautionAlertDialog from '../../../components/CautionAlertDialog';
import Container from '../../../components/Container';
import FullPageLoader from '../../../components/FullPageLoader';
import Header from '../../../components/Header';
import { dynamicPathhandler } from '../../../components/utils/dynamicPathHandler';
import { useAuth } from '../../../context/authContext';
import {
  deleteGuestFromEventList,
  getSinglePartyEvent,
} from '../service/partyAndEvents';

export const GuestList = () => {
  const { userInfo } = useAuth();
  const { userId } = userInfo;
  const { ADD_GUEST } = PROTECTED_PATHS;
  const { eventId } = useParams();
  const [loading, setLoading] = useState(false);
  const [event, setEvent] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const addGuestParentPath = dynamicPathhandler(ADD_GUEST);
  const [refresh, setRefresh] = useState({});

  useEffect(() => {
    getSinglePartyEvent(userId, eventId, setLoading, setEvent);
  }, [eventId, userId, refresh]);

  const handleDeleteGuest = (guest) => {
    const payload = {
      eventId,
      userId,
      guestId: guest._id,
      guestNumber: guest.phone,
    };

    deleteGuestFromEventList(payload, setBtnLoading).then(() => setRefresh({}));
  };

  return loading ? (
    <FullPageLoader />
  ) : (
    <SlideFade in={true} offsetX='-1000px' transition={{ ...transition }}>
      <Stack>
        <Box shadow='sm'>
          <Container>
            <Header goto={`${addGuestParentPath}/${eventId}`} />
          </Container>
        </Box>
        <Container>
          {event?.guestList?.length > 0 ? (
            event?.guestList?.map((guest) => (
              <HStack gap='0.5rem' justify='space-around' my='5' key={v4()}>
                <Avatar name={guest?.name} size='lg' alt='contact' />
                <Stack flex='0.9'>
                  <Text fontSize='1rem' fontWeight='600'>
                    {guest?.name}
                  </Text>
                  <Text fontSize='13px'>{guest?.phone}</Text>
                </Stack>
                <CautionAlertDialog
                  onContinue={() => handleDeleteGuest(guest)}
                  yesLoading={btnLoading}
                  size='xs'
                  buttonProps={{
                    border: '1px solid #ddd',
                    bg: '#eee',
                    p: '0',
                    h: '25px',
                  }}
                  button={<RiCloseFill color='#e36666' />}
                >
                  <Text fontSize='1rem'>
                    Are you sure you want to delete this Guest fron your list?
                  </Text>
                </CautionAlertDialog>
              </HStack>
            ))
          ) : (
            <Center flexDirection='column' gap='2rem' h='80vh' w='100%'>
              <Image src='/Empty.svg' alt='Empty' />
              <Text textAlign='center' color='primary' fontSize='1.5rem'>
                {'Opps! No Items in this category'}
              </Text>
            </Center>
          )}
        </Container>
      </Stack>
    </SlideFade>
  );
};
