import {
  Avatar,
  Box,
  Flex,
  Stack,
  Text,
  Tooltip,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { EditProfile } from "./components/EditProfile";
import { UserProfile } from "../../context/userProfileContext";
import Container from "../../components/Container";
import Header from "../../components/Header";
import Notifications from "./components/Notifications";
import { MdDarkMode, MdOutlineLightMode } from "react-icons/md";
import { useState } from "react";
import { COLORS } from "../../constants/colors";

const Settings = () => {
  const [mode, setMode] = useState("light");
  const { toggleColorMode } = useColorMode();
  const cardBg = useColorModeValue(COLORS.primary, COLORS.bgGrey);
  const appTextColor = useColorModeValue(COLORS.white, COLORS.black);
  const modeColor = useColorModeValue(COLORS.grey, COLORS.white);
  const { userProfile } = UserProfile();
  const { tenantName, email } = userProfile;

  console.log(userProfile, "profile")

  return (
    <>
      <Box shadow="sm">
        <Container>
          <Header />
        </Container>
      </Box>
      <Container>
        <Box my="40px" mx="5px">
          <Flex
            bg={cardBg}
            align="center"
            gap="2em"
            p="30px 20px"
            borderRadius="10px"
          >
            <Avatar
              src={userProfile?.profilePicture}
              size="xl"
              name={tenantName}
            />
            <Stack color={appTextColor}>
              {/* <Text fontSize='1.5rem'>{userProfile?.tenantName}</Text> */}
              <Tooltip label={tenantName}>
                <Text
                  fontSize={["18px", "20px"]}
                  fontWeight="bold"
                  isTruncated
                  maxWidth="170px"
                >
                  {tenantName}
                </Text>
              </Tooltip>
              <Tooltip label={email}>
                <Text fontSize={["15px", "16px"]} isTruncated maxWidth="170px">
                  {email}
                </Text>
              </Tooltip>
              <EditProfile />
            </Stack>
          </Flex>
          <Flex
            width="100%"
            align="center"
            justify="space-between"
            mt="1rem"
            bg="inherit"
            color={modeColor}
          >
            <Text fontWeight={"semibold"}>Display</Text>
            {mode === "dark" ? (
              <MdOutlineLightMode
                size={24}
                cursor="pointer"
                onClick={() => {
                  toggleColorMode();
                  setMode("light");
                }}
              />
            ) : (
              <MdDarkMode
                size={24}
                cursor="pointer"
                onClick={() => {
                  toggleColorMode();
                  setMode("dark");
                }}
              />
            )}
          </Flex>
          <Notifications />

          {/* <MoreSettings /> */}
        </Box>
      </Container>
    </>
  );
};

export default Settings;
