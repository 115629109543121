import { Box, Img, Stack, VStack, Text, Grid, Flex } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { v4 } from "uuid";
import { PROTECTED_PATHS } from "../../../app/constants";
import Container from "../../../components/Container";
import FullPageLoader from "../../../components/FullPageLoader";
import Header from "../../../components/Header";
import { AuthContext } from "../../../context/authContext";
import SearchBar from "./SearchBar";
import { Link, useNavigate } from "react-router-dom";
import { getCategories } from "../service";
import { COLORS } from "../../../constants/colors";

const SpecificCategory = () => {
  const { userInfo } = useContext(AuthContext);
  const { userId } = userInfo;
  const [loading, setLoading] = useState(false);
  const { MARKET_PLACE, SAME_CATEGORY, ITEM_DESCRIPTION } = PROTECTED_PATHS;
  const [input, setInput] = useState("");
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();
  // const parentPath = dynamicPathhandler(CATEGORY);

  useEffect(() => {
    getCategories(userId, setLoading, setCategories);
  }, [userId]);

  return loading ? (
    <FullPageLoader />
  ) : (
    <Stack>
      <Box shadow="sm">
        <Container>
          <Header goto={SAME_CATEGORY} />
        </Container>
      </Box>

      <Container>
        <Box py="20px" mb="50px">
          <SearchBar input={input} setInput={setInput} />
        </Box>
        <Grid templateColumns="repeat(2, 1fr)" gap={8}>
          {categories?.map((category, index) => (
            <>
              <VStack
                bg="#fff"
                shadow="md"
                rounded="2xl"
                p={"5px"}
                cursor="pointer"
                w="100%"
                key={index}
                onClick={() => navigate(ITEM_DESCRIPTION)}
              >
                <Img
                  w={["150px"]}
                  maxW="150px"
                  h={["150px"]}
                  objectFit="cover"
                  rounded="lg"
                  pt="10px"
                  src={category?.image}
                  alt={category?.categoryName}
                />
                <Flex justify="start">
                  <Box>
                    <Text
                      textAlign="start"
                      fontSize="18px"
                      fontWeight="600"
                      color={COLORS.green}
                      ml="10px"
                    >
                      N {"24,000"}
                    </Text>
                    <Text
                      p="10px"
                      pt="0px"
                      fontSize="16px"
                      fontWeight="600"
                      color="#7A7A7A"
                    >
                      {"Macbook pro 2016 8gb intel core i5 SSD"}
                    </Text>
                  </Box>
                </Flex>
              </VStack>
            </>
          ))}
        </Grid>
      </Container>
    </Stack>
  );
};

export default SpecificCategory;
