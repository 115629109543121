import {
  Box,
  Flex,
  SlideFade,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Container from "../../../components/Container";
import Header from "../../../components/Header";
import { useAuth } from "../../../context/authContext";
import { getSinglePoll, vote } from "../service";
import FullPageLoader from "../../../components/FullPageLoader";
import { v4 } from "uuid";
import CautionAlertDialog from "../../../components/CautionAlertDialog";
import { Success } from "../../../components/Success";
import { infoNotifier } from "../../../components/NotificationHandler";
import { COLORS } from "../../../constants/colors";
import { PROTECTED_PATHS } from "../../../app/constants";

const { POLLS } = PROTECTED_PATHS;

export const Vote = () => {
  const cardBg = useColorModeValue(COLORS.primary, COLORS.bgGrey);
  const appTextColor = useColorModeValue(COLORS.white, COLORS.black);
  const optionBg = useColorModeValue(COLORS.primary, COLORS.white);
  const { userInfo } = useAuth();
  const { userId } = userInfo;
  const { voteID } = useParams();
  const [poll, setPoll] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [successFullVote, setSuccessFullVote] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getSinglePoll(setLoading, userId, voteID, setPoll);
  }, [refresh, userId, voteID]);

  const handleVote = (option) => {
    if (poll.hasVoted) return infoNotifier("You Already Voted on this poll");
    const payload = { pollId: voteID, userId, response: option };
    vote(setLoading, payload, setSuccessFullVote, setRefresh, navigate);
  };

  const calculatePercentage = (numA, numB) => (numA / numB) * 100;
  const voteCountsArray = Object?.values(poll?.votes || {}) || [];
  const totalVoteCounts = voteCountsArray?.reduce(
    (accumulator, currentValue) => {
      return accumulator + currentValue;
    },
    0
  );

  return (
    <>
      {!loading && successFullVote && <Success />}

      {loading && !successFullVote && <FullPageLoader />}

      {!loading && !successFullVote && (
        <SlideFade in={true} offsetX="2000px">
          <Stack bg={appTextColor}>
            <Box shadow="sm">
              <Container>
                <Header goto={POLLS} />
              </Container>
            </Box>
            <Container className="poll">
              <Text py="2" fontWeight={400} fontSize={"1.5rem"} color={cardBg}>
                {poll?.data?.title}
              </Text>
              <Text
                pt="3"
                pb="1"
                fontSize={"1rem"}
                fontWeight="600"
                color={cardBg}
              >
                {poll?.data?.description}
              </Text>

              {poll?.data?.answerOptions?.map((option) => (
                <Flex
                  key={v4()}
                  my="3"
                  bg={poll?.data?.resultVisibility ? "blue.100" : "gray.200"}
                  borderRadius="5px"
                  pos="relative"
                >
                  <CautionAlertDialog
                    onContinue={() => handleVote(option)}
                    size="xs"
                    buttonProps={{
                      justifyContent: "flex-start",
                      color: appTextColor,
                      bg: poll?.choice === option ? cardBg : optionBg,
                      w: "100%",
                    }}
                    button={option}
                  >
                    Your Chosen Option is {option}?
                  </CautionAlertDialog>
                  {/* Show percentage next to option after vote */}
                  {poll.hasVoted && (
                    <Text
                      ml="auto"
                      px="2"
                      fontWeight="600"
                      alignSelf="center"
                      color={cardBg}
                    >
                      {poll?.votes &&
                        `${calculatePercentage(
                          poll?.votes[option],
                          totalVoteCounts
                        ).toFixed(2)}%`}
                    </Text>
                  )}
                </Flex>
              ))}

              {/* Display percentages after vote */}
              {poll.hasVoted && (
                <Text fontSize="1rem" textAlign="center" py="5" color={cardBg}>
                  How It was voted
                </Text>
              )}
            </Container>
          </Stack>
        </SlideFade>
      )}
    </>
  );
};

// import {
//   Box,
//   color,
//   Flex,
//   SlideFade,
//   Stack,
//   Text,
//   useColorModeValue,
// } from "@chakra-ui/react";
// import { useEffect, useState } from "react";
// import { useNavigate, useParams } from "react-router-dom";
// import { PROTECTED_PATHS } from "../../../app/constants";
// import Container from "../../../components/Container";
// import Header from "../../../components/Header";
// import { useAuth } from "../../../context/authContext";
// import { getSinglePoll, vote } from "../service";
// import FullPageLoader from "../../../components/FullPageLoader";
// import { v4 } from "uuid";
// import CautionAlertDialog from "../../../components/CautionAlertDialog";
// import { Success } from "../../../components/Success";
// import { infoNotifier } from "../../../components/NotificationHandler";
// import { COLORS } from "../../../constants/colors";

// const { POLLS } = PROTECTED_PATHS;

// export const Vote = () => {
//   const cardBg = useColorModeValue(COLORS.primary, COLORS.bgGrey);
//   const appTextColor = useColorModeValue(COLORS.white, COLORS.black);
//   const { userInfo } = useAuth();
//   const { userId } = userInfo;
//   const { voteID } = useParams();
//   const [poll, setPoll] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [successFullVote, setSuccessFullVote] = useState(false);
//   const navigate = useNavigate();

//   useEffect(() => {
//     getSinglePoll(setLoading, userId, voteID, setPoll);
//   }, [userId, voteID]);

//   const handleVote = (option) => {
//     if (poll.hasVoted) return infoNotifier("You Already Voted on this poll");
//     const payload = { pollId: voteID, userId, response: option };
//     vote(setLoading, payload, setSuccessFullVote, navigate);
//   };

//   const calculatePercentage = (numA, numB) => (numA / numB) * 100;
//   const voteCountsArray = Object?.values(poll?.votes || {}) || [];

//   const totalVoteCounts = voteCountsArray?.reduce(
//     (accumulator, currentValue) => {
//       return accumulator + currentValue;
//     },
//     0
//   );

//   return (
//     <>
//       {!loading && successFullVote && <Success />}

//       {loading && !successFullVote && <FullPageLoader />}

//       {!loading && !successFullVote && (
//         <SlideFade in={true} offsetX="2000px">
//           <Stack bg={appTextColor}>
//             <Box shadow="sm">
//               <Container>
//                 <Header goto={POLLS} />
//               </Container>
//             </Box>
//             <Container className="poll">
//               <Text py="2" fontWeight={400} fontSize={"1.5rem"} color={cardBg}>
//                 {poll?.data?.title}
//               </Text>
//               <Text
//                 pt="3"
//                 pb="1"
//                 fontSize={"1rem"}
//                 fontWeight="600"
//                 color={cardBg}
//               >
//                 {poll?.data?.description}
//               </Text>

//               {poll?.data?.answerOptions?.map((option) => (
//                 <Flex
//                   // color={poll?.data?.resultVisibility ? 'white' : 'inherit'}
//                   my="3"
//                   key={v4()}
//                   bg={poll?.data?.resultVisibility ? "blue.100" : "gray.200"}
//                   borderRadius="5px"
//                   pos="relative"
//                 >
//                   {/* <Box pos='absolute' borderRadius='5px' h='100%' /> */}
//                   <CautionAlertDialog
//                     onContinue={() => handleVote(option)}
//                     size="xs"
//                     buttonProps={{
//                       justifyContent: "flex-start",
//                       color: { appTextColor },
//                       bg: poll?.choice === option ? {cardBg} : "white",
//                       w: "100%",
//                     }}
//                     button={option}
//                   >
//                     Your Chosen Option is {option}?
//                   </CautionAlertDialog>
//                   {poll?.data?.resultVisibility ? (
//                     <Text
//                       fontWeight="600"
//                       top="50%"
//                       transform="translate(0%, -50%)"
//                       right="2%"
//                       pos="absolute"
//                     >
//                       {(poll?.votes &&
//                         calculatePercentage(
//                           poll?.votes[option],
//                           totalVoteCounts
//                         )) ||
//                         0}
//                       %
//                     </Text>
//                   ) : null}
//                 </Flex>
//               ))}
//               {poll?.data?.resultVisibility ? (
//                 <Text fontSize="1rem" textAlign="center" py="5" color={cardBg}>
//                   How It was voted
//                 </Text>
//               ) : null}
//             </Container>
//           </Stack>
//         </SlideFade>
//       )}
//     </>
//   );
// };
