import { Box, Button, Input, Text } from '@chakra-ui/react';
import Papa from 'papaparse';
import { useRef, useState } from 'react';

const BulkUpload = ({ setBulkGuestList }) => {
  const uploadRef = useRef(null);
  const [rawFile, setRawFile] = useState({});

  const changeHandler = (event) => {
    console.log(event.target.files[0]);
    setRawFile(event.target.files[0]);

    // Passing file data (event.target.files[0]) to parse using Papa.parse
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        // console.log(results.data);
        setBulkGuestList(results.data);
      },
    });
  };

  const downloadCSV = () => {
    let csv = Papa.unparse([{ name: 'Adekunle Gold', phone: '08033333333' }]);

    let csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    let csvURL = null;
    if (navigator.msSaveBlob) {
      csvURL = navigator.msSaveBlob(csvData, 'download.csv');
    } else {
      csvURL = window.URL.createObjectURL(csvData);
    }

    let tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', 'download.csv');
    tempLink.click();
  };

  return (
    <>
      <Box>
        <Button mb='5' _focus={{ border: 'transparent' }} _hover={{}} bg='primary' w='100%' color='white' onClick={downloadCSV}>
          Download spreadsheet for Bulk Upload
        </Button>

        <Button w='100%' my='5' color='primary' onClick={() => uploadRef.current.click()}>
          Upload Bulk
        </Button>

        <Input ref={uploadRef} type='file' name='file' accept='.csv' onChange={changeHandler} display='none' />
        {rawFile.name && (
          <Text textAlign='center' my='1' fontSize='12px'>
            {rawFile.name}
          </Text>
        )}
      </Box>
    </>
  );
};

export default BulkUpload;
