import {
  Flex,
  FormControl,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  SlideFade,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import {
  AiOutlineEye,
  AiOutlineEyeInvisible,
  AiOutlinePhone,
} from "react-icons/ai";
import Container from "../../components/Container";
import { PrimaryButton } from "../../components/CustomButton";
import { AuthContext } from "../../context/authContext";
import { loginTenant } from "./service/login";
import { UpdatePassword } from "./components/UpdatePassword";
import { BsLock } from "react-icons/bs";
import { errorNotifier } from "../../components/NotificationHandler";
import { Link } from "react-router-dom";
import { PUBLIC_PATHS } from "../../app/constants";
import { COLORS } from "../../constants/colors";

const { FORGOTPASSWORD, PRIVACY_POLICY } = PUBLIC_PATHS;

const Login = () => {
  const cardBg = useColorModeValue(COLORS.primary, COLORS.white);
  const pageBg = useColorModeValue(COLORS.white, COLORS.black);
  const [tenantPhone, setTenantPhone] = useState("");
  const [tenantCode, setTenantCode] = useState("");
  const [loading, setLoading] = useState(false);
  const { setIsLoggedin } = useContext(AuthContext);
  const [nextPage, setNextPage] = useState(false);
  const [data, setData] = useState([]);
  const [showTenantCode, setShowTenantCode] = useState(true);

  const handleLogin = (e) => {
    e.preventDefault();

    const payload = { tenantCode, tenantPhone, flag: "Old" };

    if (!payload.tenantCode || !payload.tenantCode) {
      errorNotifier("Input valid details");
      return;
    }
    loginTenant(payload, setLoading, setIsLoggedin, setNextPage, setData);
  };

  return nextPage ? (
    <UpdatePassword data={data} />
  ) : (
    <SlideFade in={true} offsetX="-5000px">
      <Container bg={pageBg}>
        <Stack w="100%" as="section" pt="15vh" h="100vh">
          {/*<Box pb="7" align="center">
            <Image src={logo} alt="logo" height="130px" />
  </Box>*/}

          <Text color={cardBg} fontWeight="600" fontSize="2.7rem">
            Log in
          </Text>
          <Text color={cardBg} fontWeight="500" pb={"20px"} fontSize="1.2rem">
            Kindly fill up the details below
          </Text>
          <FormControl w="100%">
            <InputGroup>
              <Input
                autoFocus
                {...inputStyles}
                borderColor={COLORS.green}
                placeholder="Phone Number"
                _placeholder={{ color: `${COLORS.green}` }}
                value={tenantPhone}
                type="number"
                onChange={(e) => setTenantPhone(e.target.value)}
              />
              <InputLeftElement children={<AiOutlinePhone />} mt="10px" />
            </InputGroup>
            <InputGroup my="7">
              <Input
                {...inputStyles}
                borderColor={COLORS.green}
                placeholder="Tenant code"
                _placeholder={{ color: `${COLORS.green}` }}
                value={tenantCode}
                type={!showTenantCode ? "text" : "password"}
                onChange={(e) => setTenantCode(e.target.value)}
              />
              <InputLeftElement children={<BsLock />} mt="10px" />

              <InputRightElement
                mt="10px"
                cursor={"pointer"}
                onClick={() => setShowTenantCode(!showTenantCode)}
              >
                {showTenantCode ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
              </InputRightElement>
            </InputGroup>
            <Text
              fontSize="16px"
              cursor="pointer"
              textAlign="right"
              fontWeight={600}
              color={cardBg}
              mt="-17px"
              mb="50px"
            >
              <Link to={FORGOTPASSWORD}>Forgot Password?</Link>
            </Text>
            <PrimaryButton
              loadingText="logging you in"
              btnText="Log in"
              isLoading={loading}
              onClick={handleLogin}
              h="60px"
              fontSize="18px"
              rounded="2xl"
            />
          </FormControl>
          <Flex width="100%" justify="end" color={cardBg}>
            <Link to={PRIVACY_POLICY}>
              By logging in you have accepted our terms and conditions.
            </Link>
          </Flex>
        </Stack>
      </Container>
    </SlideFade>

    // </Slide>
  );
};

export default Login;

export const inputStyles = {
  bg: "white",
  rounded: "2xl",
  h: "60px",
  color: "#262636",
};
