import {
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { timeout } from "../../../components/utils/timeout";
import { COLORS } from "../../../constants/colors";

const PickContactFromPhoneWarning = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (localStorage.getItem("understand")) return;
    timeout(() => onOpen(), 400);
  }, [onOpen]);

  const handleUnderstand = () => {
    localStorage.setItem("understand", true);
    onClose();
  };

  return (
    <>
      <Modal
        size="xs"
        isCentered
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            fontSize="24px"
            pb="2"
            textAlign={"center"}
            color={COLORS.green}
          >
            Notice
          </ModalHeader>
          <Divider />

          <ModalBody pt="5" pb="10" color={COLORS.green}>
            <Text fontSize="15px" fontWeight={"500"}>
              Please note that the current version of safari and Opera mini
              might not support picking contact from phone
            </Text>
            <br />
            <Text fontSize="15px" fontWeight={"500"}>
              You can pick contact from you contact list or type in directly.
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button
              variant="ghost"
              border={`1.5px solid ${COLORS.green}`}
              size="sm"
              mr={3}
              onClick={handleUnderstand}
            >
              I understand
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PickContactFromPhoneWarning;
