import { Menu, MenuButton, MenuList } from '@chakra-ui/react';
import { BiDotsVerticalRounded } from 'react-icons/bi';
// import EditGuest from '../pages/my-estate/components/EditGuest';
import { DeleteModal } from './DeleteModal';
// import { EditProfile } from './EditProfile';
import { GuestDetailsModal } from './GuestDetailsModal';

export const Popover = (props) => {
  return (
    <Menu>
      <MenuButton>
        <BiDotsVerticalRounded size='1.5rem' />
      </MenuButton>
      <MenuList>
        <GuestDetailsModal {...props} />
        {/* <EditGuest /> */}
        <DeleteModal {...props} />
      </MenuList>
    </Menu>
  );
};
