import { Box, Flex, Image, Stack, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import Container from '../../components/Container';
import Header from '../../components/Header';
import errand from '../../assets/sent.svg';
import { PrimaryButton } from '../../components/CustomButton';
import TypeOfErrands from './components/TypeOfErrands';
import { useNavigate } from 'react-router-dom';
import { PROTECTED_PATHS } from '../../app/constants';
import FullPageLoader from '../../components/FullPageLoader';

const MyErrands = () => {
  const navigate = useNavigate();

  const [loading] = useState(false);
  const { ERRAND_HOME } = PROTECTED_PATHS;

  return loading ? (
    <FullPageLoader />
  ) : (
    <Stack minH='100vh'>
      <Box shadow='md'>
        <Container>
          <Header menu />
        </Container>
      </Box>
      <Container>
        <Stack py='5' textAlign='center'>
          <Flex justify='center'>
            <Image w='60px' src={errand} alt='errand' />
          </Flex>
          <Text py='3'>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta
            dolore cupiditate veritatis odit nostrum doloribus qui rem dicta
            mollitia quibusdam quo laboriosam reprehenderit distinctio iure
          </Text>
          <TypeOfErrands />
        </Stack>
        <Box textAlign='center' py='10'>
          <PrimaryButton
            btnText='Get Started'
            maxW='60%'
            onClick={() => navigate(ERRAND_HOME)}
          />
        </Box>
      </Container>
    </Stack>
  );
};

export default MyErrands;
