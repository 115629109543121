import { Box, Flex, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import { MenuItems } from "./Menu";
import { COLORS } from "../../../constants/colors";

const ContactList = ({ contactList, userId, setRefresh }) => {
  const cardBg = useColorModeValue(COLORS.lightGrey, COLORS.white);
  const appTextColor = useColorModeValue(COLORS.primary, COLORS.primary);
  const { guestName, phoneNumber } = contactList;

  return (
    <Box
      width="100%"
      p="20px"
      mb="2rem"
      borderRadius="10px"
      boxShadow="sm"
      bg={cardBg}
      color={appTextColor}
    >
      <Flex justify="space-between" align="center" width="100%">
        <Stack fontSize="0.8rem" width="90%">
          <Text fontSize="1.1rem" fontWeight="500" color="#614385" isTruncated>
            {guestName}
          </Text>

          <Text m="0 !important">{phoneNumber}</Text>
        </Stack>
        <Box alignSelf={"flex-end"} width="10%">
          <MenuItems
            contactList={contactList}
            userId={userId}
            setRefresh={setRefresh}
          />
        </Box>
      </Flex>
    </Box>
  );
};

export default ContactList;
