import { Box, Button, Flex, HStack, Img, Stack, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { v4 } from "uuid";
import { PROTECTED_PATHS } from "../../../app/constants";
import CautionAlertDialog from "../../../components/CautionAlertDialog";
import Container from "../../../components/Container";
import FullPageLoader from "../../../components/FullPageLoader";
import Header from "../../../components/Header";
import { useAuth } from "../../../context/authContext";
import {
  archieveProduct,
  deleteUserProduct,
  getSingleActiveProduct,
} from "../service";
import { COLORS } from "../../../constants/colors";

export const ViewOneShopProduct = () => {
  const { userInfo } = useAuth();
  const { userId, tenatCode } = userInfo;
  const { shopProduct } = useParams();
  const navigate = useNavigate();
  const { SHOP, EDIT_SHOP_PRODUCT } = PROTECTED_PATHS;
  const [loading, setLoading] = useState(false);
  const [singleActiveProduct, setSingleActiveProduct] = useState([]);

  useEffect(() => {
    getSingleActiveProduct(
      userId,
      setLoading,
      setSingleActiveProduct,
      shopProduct
    );
  }, [userId, shopProduct]);

  const handleArchieveProduct = (product) => {
    const payload = { userId, tenatCode, productId: product._id };
    archieveProduct(payload, setLoading, navigate);
  };

  const handleDelete = (product) => {
    const payload = { userId, productId: product._id };
    deleteUserProduct(payload, setLoading, navigate);
  };

  return loading ? (
    <FullPageLoader />
  ) : (
    <Stack pb="10vh">
      <Box shadow="sm">
        <Container>
          <Header goto={SHOP} />
        </Container>
      </Box>

      <Container>
        <Box bg={"white"} p={"20px"} rounded={"lg"}>
          <HStack wrap="wrap" gap="1rem">
            {singleActiveProduct?.imageUrl?.map((img) => (
              <Img
                key={v4()}
                m="0 !important"
                w="100px"
                h="100px"
                src={img}
                alt={singleActiveProduct?.itemName}
                shadow="md"
                borderRadius="4px"
              />
            ))}
          </HStack>
        </Box>
        <Box bg={"white"} p={"20px"} rounded={"lg"} mt="30px">
          <Stack py="15px" spacing="15px" fontSize="1rem">
            <HStack justify="space-between">
              <Text color="#B7B6B6">Name of item</Text>
              <Text color="#161616">{singleActiveProduct?.itemName}</Text>
            </HStack>
            <HStack justify="space-between">
              <Text color="#B7B6B6">Category</Text>
              <Text color="#161616">{singleActiveProduct?.category}</Text>
            </HStack>
            <HStack justify="space-between">
              <Text color="#B7B6B6">Sub Category</Text>
              <Text color="#161616">
                {singleActiveProduct?.subCategory || "N/A"}
              </Text>
            </HStack>
            <HStack justify="space-between">
              <Text color="#B7B6B6">Price</Text>
              <Text color="#161616">
                &#8358; {singleActiveProduct?.price?.toLocaleString()}
              </Text>
            </HStack>
            <HStack justify="space-between">
              <Text color="#B7B6B6">Quantity</Text>
              <Text color="#161616">
                {singleActiveProduct?.quantity || "N/A"}
              </Text>
            </HStack>
            <Text color="#B7B6B6">Description</Text>
            <Text color="#161616">
              {singleActiveProduct?.itemDescription}
            </Text>
          </Stack>

          {/*<Flex justify="center" pt="10" gap="2rem">
            <CautionAlertDialog
              loading={loading}
              size="xs"
              onContinue={() => handleArchieveProduct(singleActiveProduct)}
              buttonProps={{ ...deleteBtn, color: "unset" }}
              button=" Archive"
            >
              <Text fontSize="1.2rem" textAlign="center">
                Archive this Product?
              </Text>
              <Text textAlign="center" py="5">
                Item will no longer be visible to others until reactivation
              </Text>
            </CautionAlertDialog>
            <CautionAlertDialog
              size="xs"
              onContinue={() => handleDelete(singleActiveProduct)}
              buttonProps={{ ...deleteBtn }}
              button=" Delete"
            >
              <Text fontSize="1.2rem" textAlign="center">
                Premanently Delete Product?
              </Text>
            </CautionAlertDialog>{" "}
            </Flex>*/}
        </Box>
        <Flex justify="center" pt="10" gap="2rem">
          <CautionAlertDialog
            loading={loading}
            size="xs"
            onContinue={() => handleArchieveProduct(singleActiveProduct)}
            buttonProps={{ ...deleteBtn, color: "unset" }}
            button=" Archive"
          >
            <Text fontSize="1.2rem" textAlign="center">
              Archive this Product?
            </Text>
            <Text textAlign="center" py="5">
              Item will no longer be visible to others until reactivation
            </Text>
          </CautionAlertDialog>
          <CautionAlertDialog
            size="xs"
            onContinue={() => handleDelete(singleActiveProduct)}
            buttonProps={{ ...deleteBtn }}
            button=" Delete"
          >
            <Text fontSize="1.2rem" textAlign="center">
              Premanently Delete Product?
            </Text>
          </CautionAlertDialog>
        </Flex>
        <Flex justify="end" mt={'2rem'}>
          <Button
            bg={COLORS.green}
            color="white"
            w="100px"
            onClick={() => navigate(EDIT_SHOP_PRODUCT)}
          >
            Edit
          </Button>
        </Flex>
      </Container>
    </Stack>
  );
};

export const deleteBtn = {
  flex: "1",
  size: "md",
  _focus: { outline: "none" },
  color: "red.300",
  variant: "outline",
};
