import { Box, Button, Divider, HStack, Input, InputGroup, InputLeftElement, SlideFade, Stack, Text } from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import { BsPhone } from 'react-icons/bs';
import { FaRegUser } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import { transition } from '../../..';
import { PROTECTED_PATHS } from '../../../app/constants';
import CautionAlertDialog from '../../../components/CautionAlertDialog';
import Container from '../../../components/Container';
import FullPageLoader from '../../../components/FullPageLoader';
import Header from '../../../components/Header';
import { infoNotifier } from '../../../components/NotificationHandler';
import { dynamicPathhandler } from '../../../components/utils/dynamicPathHandler';
import { AuthContext } from '../../../context/authContext';
import { inputBorder } from '../../post advert/components/data';
import { addGuest, getSinglePartyEvent } from '../service/partyAndEvents';
import BulkUpload from './BulkUpload';
import { GrFormAdd } from 'react-icons/gr';

const { PARTY_EVENT, EVENT_GUEST_LIST } = PROTECTED_PATHS;

export const AddGuest = () => {
  const { userInfo } = useContext(AuthContext);
  const { userId } = userInfo;
  const guetsListParentPath = dynamicPathhandler(EVENT_GUEST_LIST);
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [partyEvent, setPartyEvent] = useState([]);
  const [bntLoading, setBntLoading] = useState(false);

  const [refresh, setRefresh] = useState({});
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [bulkGuestList, setBulkGuestList] = useState([]);
  let pushed = [];

  useEffect(() => {
    getSinglePartyEvent(userId, eventId, setLoading, setPartyEvent);
  }, [eventId, userId, refresh]);

  const handleAddGuest = () => {
    pushed.push({ name, phone }, ...bulkGuestList);

    const guestList = pushed.filter((p) => p.name !== '' || p.phone !== '');

    if (!guestList.length) {
      infoNotifier('Enter valid Guest name and phone number');
      return;
    }

    if (!guestList[0].name) {
      infoNotifier('Enter the Guest name');
      return;
    }

    if (!guestList[0].phone) {
      infoNotifier('Enter the Guest phone number');
      return;
    }

    if (guestList[0].phone.length !== 11) {
      infoNotifier('Phone number must be eleven digits');
      return;
    }

    addGuest(userId, eventId, { guestList }, setRefresh, setBntLoading);
  };

  return loading ? (
    <FullPageLoader />
  ) : (
    <SlideFade in={true} offsetX='-1000px' transition={{ ...transition }}>
      <Stack>
        <Box shadow='sm'>
          <Container>
            <Header goto={PARTY_EVENT} />
          </Container>
        </Box>

        <Container>
          <Stack spacing='10'>
            <Text mt='3' fontSize='15px' fontWeight='500'>
              You can upload bulk contact or input manually the names of people you're inviting to the estate
            </Text>

            <BulkUpload
              setBulkGuestList={setBulkGuestList}
              // setGuestList={setGuestList}
              bulkGuestList={bulkGuestList}
            />

            <HStack>
              <Divider />
              <Text> OR </Text>
              <Text> SINGLE </Text>
              <Text> GUEST </Text>
              <Divider />
            </HStack>

            <InputGroup>
              <InputLeftElement children={<FaRegUser />} />
              <Input {...inputBorder} id='name' name='name' placeholder='Name' onChange={(e) => setName(e.target.value)} />
            </InputGroup>

            <InputGroup mt='-5'>
              <InputLeftElement children={<BsPhone />} />
              <Input {...inputBorder} name='phone' id='phone' placeholder='Phone number' type='number' onChange={(e) => setPhone(e.target.value)} />
            </InputGroup>

            <HStack gap='2rem' justify='space-between'>
              <CautionAlertDialog
                loading={bntLoading}
                size='xs'
                onContinue={handleAddGuest}
                buttonProps={{ color: 'primary', bg: 'transparent', p: 0 }}
                button={
                  <Button bg='gray.200' leftIcon={<GrFormAdd size={20} />} variant='outline' fontSize='13px'>
                    add guest
                  </Button>
                }
              >
                <Text fontSize='1.2rem' textAlign='center'>
                  Are you sure you want to add {`${name || 'This Guest?'}`}?
                </Text>
              </CautionAlertDialog>
              <Button
                bg='gray.200'
                variant='outline'
                fontSize='13px'
                border='2px solid #333'
                onClick={() => navigate(`${guetsListParentPath}/${eventId}`)}
              >
                View Guest list
              </Button>

              {/* <Text>Click to add guest</Text> */}
              {/* <Button>Click to add guest</Button> */}
            </HStack>

            <Text textAlign='center'>
              {partyEvent?.guestList?.length} of {partyEvent?.numberOfGuests} guests uploaded
            </Text>
          </Stack>
        </Container>
      </Stack>
    </SlideFade>
  );
};
