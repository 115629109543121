import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Img,
  Input,
  Select,
  Stack,
  Text,
  Textarea,
  SlideFade,
  InputGroup,
  InputLeftElement,
  useColorModeValue,
  HStack,
} from "@chakra-ui/react";
import React, { useState } from "react";

import { AiOutlineCloudUpload } from "react-icons/ai";
import { COLORS } from "../../../constants/colors";
import { PROTECTED_PATHS } from "../../../app/constants";
import { infoNotifier } from "../../../components/NotificationHandler";
import Container from "../../../components/Container";
import Header from "../../../components/Header";
import { formLabelStyles, issueTypes } from "./issueReportData";
import { PrimaryButton } from "../../../components/CustomButton";
import { Link } from "react-router-dom";
import Footer from "../../../components/footer";
import OneIssue from "./OneIssue";

const IssueReport = () => {
  const cardBg = useColorModeValue(COLORS.lightGrey, COLORS.white);
  const bgColor = useColorModeValue(COLORS.primary, COLORS.green);
  const appTextColor = useColorModeValue(COLORS.primary, COLORS.primary);
  const [issueType, setIssueType] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [media, setMedia] = useState([]);
  const [previewImg] = useState([]);
  const [loading, setLoading] = useState(false);
  const [displayOneIssue, setDisplayOneIssue] = useState(false);
  const { ISSUE_REPORTS_HISTORY, ISSUE_REPORT } = PROTECTED_PATHS;

  const inputStyle = {
    bg: "white",
    rounded: "lg",
    color: "#262636",
  };

  const handleImage = (event) => {
    if (!event.target.files) return;

    if (Array(...event.target.files).length > 5) {
      infoNotifier("Pick a maximum of Three Images");
      return;
    }
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      setMedia((prev) => [
        ...prev,
        { imgUrl: reader.result, file: event.target.files[0] },
      ]);
    };
  };

  const handleOneIssueDisplay = () => {
    if (issueType && title && description) {
      setDisplayOneIssue(true);
      return;
    }
    infoNotifier("Please Fill in the Details");
  };

  return !displayOneIssue ? (
    <SlideFade in={true} offsetX="-2000px">
      <Box minH="100vh">
        <Box shadow="sm">
          <Container>
            <Header goto={ISSUE_REPORT} />
          </Container>
        </Box>

        <Container minH="calc(100vh - 100px)">
          <FormControl h="100%" mt="50px">
            <Stack py="15px" spacing="40px">
              <Select
                {...inputStyle}
                placeholder="Report Type"
                color={COLORS.green}
                _focus={{ border: "1px solid #F9C8C5" }}
                value={issueType}
                onChange={(e) => setIssueType(e.target.value)}
              >
                {issueTypes?.map((type, index) => (
                  <option key={index} value={type}>
                    {type}
                  </option>
                ))}
              </Select>

              <Input
                {...inputStyle}
                placeholder="Title"
                _placeholder={{ opacity: 1, color: "black" }}
                border="none"
                borderBottom="1px solid #ccc"
                borderRadius="0"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <Textarea
                {...inputStyle}
                placeholder="Enter full details"
                _placeholder={{ opacity: 1, color: "black" }}
                resize="none"
                rows="6"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Stack>

            <Flex mt="5" gap="10px">
              <Box>
                <FormLabel {...formLabelStyles}>
                  <HStack cursor="pointer">
                    <Text color={cardBg} fontWeight="500">
                      Upload media
                    </Text>
                    <AiOutlineCloudUpload size={"1.5rem"} color={cardBg} />
                  </HStack>
                  <Input
                    type="file"
                    display="none"
                    id="media"
                    onChange={handleImage}
                  />
                </FormLabel>
              </Box>
              <Flex wrap={"wrap"}>
                {media?.map((medium, i) => {
                  return (
                    <Img
                      key={i}
                      w="70px"
                      h="70px"
                      m="2"
                      borderRadius={"5px"}
                      src={medium.imgUrl}
                      alt={medium.file.name}
                    />
                  );
                })}
              </Flex>
            </Flex>
            <Box pt="8vh" pb="17vh">
              <PrimaryButton
                btnText="Send Report"
                isLoading={loading}
                onClick={handleOneIssueDisplay}
              />
            </Box>
          </FormControl>
          {/* <Text textAlign="center" py="10" fontWeight="600">
            <Link to={ISSUE_REPORTS_HISTORY}>Goto report history</Link>
              </Text> */}
        </Container>
        <Footer />
      </Box>
    </SlideFade>
  ) : (
    <OneIssue
      setDisplayOneIssue={setDisplayOneIssue}
      media={media}
      description={description}
      issueType={issueType}
      title={title}
      previewImg={previewImg}
      setLoading={setLoading}
    />
  );
};

export default IssueReport;
