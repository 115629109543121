import {
  Box,
  Center,
  Circle,
  HStack,
  Image,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useState, useContext, useEffect } from "react";
import Container from "../../components/Container";
import { getNotifications } from "./service";
import { AuthContext } from "../../context/authContext";
import FullPageLoader from "../../components/FullPageLoader";
import { FiCheck } from "react-icons/fi";
import { BsInfoLg } from "react-icons/bs";
import Header from "../../components/Header";
import { COLORS } from "../../constants/colors";
import Footer from "../../components/footer";

const Notification = () => {
  const cardBg = useColorModeValue(COLORS.lightGrey, COLORS.white);
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState();
  const { userInfo } = useContext(AuthContext);
  const { userId } = userInfo;

  useEffect(() => {
    getNotifications(userId, setLoading, setNotifications);
  }, [userId]);

  const filteredNotifications = notifications?.filter(
    (noti) => !noti.subject.includes("event")
  );

  return (
    <Stack>
      <Box shadow="sm">
        <Container>
          <Header menu />
        </Container>
      </Box>
      <Container>
        <Stack>
          {loading ? (
            <FullPageLoader />
          ) : (
            <Stack spacing="5" h="100%">
              {!notifications ? (
                <Center flexDirection="column" gap="2rem" w="100%" h="100%">
                  <Image src="/Empty.svg" alt="Empty" />
                  <Text color={cardBg} fontSize="1.5rem">
                    Opps! You have no notification
                  </Text>
                </Center>
              ) : (
                filteredNotifications?.slice(0, 10)?.map((notification) => {
                  return (
                    <HStack key={notification?._id} {...notificationStyle}>
                      {notification?.subject === "Guest Check-in" ||
                      notification?.subject === "vehicle Check-in" ||
                      notification?.subject === "contractor Check-in" ? (
                        <Circle bg="#36B257" p="1">
                          <FiCheck size={20} color="#fff" />
                        </Circle>
                      ) : (
                        <Circle bg="#cccc00" p="1">
                          <BsInfoLg size={20} color="#fff" />
                        </Circle>
                      )}

                      <Text>
                        {notification?.substitutional_parameters?.body ||
                          notification?.substitutional_parameters?.itemName}
                      </Text>
                    </HStack>
                  );
                })
              )}
            </Stack>
          )}
        </Stack>
      </Container>
      <Footer />
    </Stack>
  );
};

export default Notification;

const notificationStyle = {
  rounded: "lg",
  p: "20px",
  bg: "gray.50",
  gap: "5px",
  shadow: "sm",
};
