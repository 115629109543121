// import {
//   successNotifier,
//   errorNotifier,
// } from '../../../components/NotificationHandler';

import MyWallet from "..";
import {
  errorNotifier,
  successNotifier,
} from "../../../components/NotificationHandler";
import { timeout } from "../../../components/utils/timeout";
import http,  { AUTH_ROUTES } from "../../../services/api";
import axiosInstance from "../../../services/api";


export const buyPoints = async (
  payload,
  setLoading,
  setAuthorizationUrl,
  setPayStackModalOpen,
  setReferenceCode
) => {
  setLoading(true);
  try {
    const {
      data: { result },
    } = await http.post(AUTH_ROUTES.INITIALIZE_BUY_POINTS, payload);

    setAuthorizationUrl(result?.data?.authorization_url);
    setReferenceCode(result?.data?.reference);
    setPayStackModalOpen(true);
  } catch (err) {
    console.log(err.response);
  } finally {
    setLoading(false);
  }
};

export const verifyWalletPayment = async (payload, setSuccess, navigate) => {
  try {
    await http.post(AUTH_ROUTES.VERIFY_BUY_POINTS, payload);

    setSuccess(true);
    timeout(() => navigate(MyWallet), 1000);
  } catch (error) {
    console.log(error.response);
  } finally {
  }
};

export const getAllTransactions = async (
  userId,
  setLoading,
  setAllTransactions
) => {
  setLoading(true);
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_ALL_PREVIOUS_TRANSACTIONS(userId));

    setAllTransactions(data);
  } catch (error) {
  } finally {
    setLoading(false);
  }
};

export const getWallet = async (userId, setLoading, setWallet) => {
  setLoading(true);
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_BANK(userId));

    setWallet(data);
  } catch (error) {
  } finally {
    setLoading(false);
  }
};

export const accountStatement = async (payload, setLoading) => {
  setLoading(true);
  try {
    const result = await http.post(AUTH_ROUTES.GET_ACCOUNT_STATEMENT, payload);
    console.log(result.data, "account statement");
    setLoading(false);
    return result.data; // Return the result
  } catch (e) {
    console.log(e, "error with account statement");
    setLoading(false);
    throw e; // Rethrow the error
  }
};

// Get Due Type
export const getDueTypes = async (userId, setLoading, setDueTypes) => {
  setLoading(true);
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_DUE_TYPES(userId));
    setDueTypes(data);
  } catch (error) {
  } finally {
    setLoading(false);
  }
};

export const verifyPayment = async (reference, userId) => {
  try {
    const response = await axiosInstance.post("/route-pay/estate-due/verify", {
      reference,
      userId, // Include userId in the payload
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "Verification failed");
  }
};

// export const accountStatement = async (payload, setLoading) => {
//   setLoading(true);
//   try {
//     const result = await http.post(AUTH_ROUTES.GET_ACCOUNT_STATEMENT, payload);
//     console.log(result.data, "account statement");
//     // successNotifier(result?.message || "Success");
//     setLoading(false);
//   } catch (e) {
//     console.log(e, "error with account statement");
//     // errorNotifier("No data found");
//     setLoading(false);
//   }
// };

// export const accountStatement = async (payload, setLoading) => {
//   setLoading(true); // Set loading to true to indicate request is in progress
//   try {
//     // Making a POST request to GET_ACCOUNT_STATEMENT with the payload
//     const response = await http.post(
//       AUTH_ROUTES.GET_ACCOUNT_STATEMENT,
//       payload
//     );
//     const { data, message, success } = response.result;

//     if (data && data.length > 0) {
//       successNotifier("Account statement retrieved successfully!");
//       return data;
//     } else {
//       errorNotifier("No transactions found for the specified date range");
//       return [];
//     }
//   } catch (error) {
//     console.error("Error fetching account statement:", error);
//     errorNotifier("An error occurred while fetching account statement");
//     return [];
//   } finally {
//     setLoading(false);
//   }
// };
