import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { COLORS } from '../constants/colors';

export default function CustomDrawer({ children, btn }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
    const appTextColor = useColorModeValue(COLORS.primary, COLORS.white);

  return (
    <>
      <Button
        _focus={{ border: "none" }}
        size="sm"
        p="0"
        color={appTextColor}
        onClick={onOpen}
      >
        {btn}
      </Button>
      <Drawer p="0" isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent bg="transparent">
          <DrawerCloseButton color="#fff" />
          <DrawerBody p="0">{children}</DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
