import {
  Box,
  Center,
  Circle,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  SlideFade,
  Stack,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import React from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { BsAlarmFill } from 'react-icons/bs';
import { FaMoneyBillAlt } from 'react-icons/fa';
import DatePicker from 'react-multi-date-picker';
import { useParams } from 'react-router-dom';
import { v4 } from 'uuid';
import { transition } from '../../..';
import { PROTECTED_PATHS } from '../../../app/constants';
import Container from '../../../components/Container';
import { PrimaryButton } from '../../../components/CustomButton';
import FullPageLoader from '../../../components/FullPageLoader';
import Header from '../../../components/Header';
import { infoNotifier } from '../../../components/NotificationHandler';
import { AuthContext } from '../../../context/authContext';
import { inputBorder } from '../../post advert/components/data';
import { editPartyEvent, getSinglePartyEvent } from '../service/partyAndEvents';
import CustomeSpecialReqestModal from './CustomeSpecialReqestModal';
import { ProcessingRequest } from './ProcessingRequest';

export const EditPartyRequest = () => {
  const { userInfo } = useContext(AuthContext);
  const { userId } = userInfo;
  const { requestId } = useParams();
  const { PARTY_EVENT } = PROTECTED_PATHS;

  const [success, setSuccess] = useState(false);
  const today = new Date();
  const [loading, setLoading] = useState(false);
  const [partyEvent, setPartyEvent] = useState([]);
  const [partyEventValues, setPartyEventValues] = useState();

  useEffect(() => {
    getSinglePartyEvent(userId, requestId, setLoading, setPartyEvent);
  }, [userId, requestId]);

  useEffect(() => {
    setPartyEventValues({ ...partyEvent });
  }, [partyEvent]);

  const onChange = (e) => {
    setPartyEventValues((values) => ({
      ...values,
      [e.target.name]: e.target.value,
    }));
  };

  const handleNumberOfDays = (date) => {
    const sortedDate = date.sort((a, b) => a.dayOfYear - b.dayOfYear);

    const formatedDates = sortedDate.map((d) => dayjs(d).format('YYYY-MM-DD'));

    setPartyEventValues((prev) => ({ ...prev, dates: formatedDates }));
  };

  const handleNumberOfGuests = (number) => {
    setPartyEventValues((prev) => ({ ...prev, numberOfGuests: number }));
  };

  const handleEditRequest = () => {
    const { dates, description, numberOfGuests, address } = partyEventValues;

    if (!dates.length) {
      infoNotifier('please input dates for the event');
      return;
    }
    if (!address) {
      infoNotifier('please input venue for the event');
      return;
    }
    if (!description) {
      infoNotifier('please input description for the event');
      return;
    }

    const payload = {
      description,
      dates,
      numberOfGuests,
      address,
      requestStatus: 'pending',
    };
    console.log('payload', payload);
    editPartyEvent(userId, requestId, setLoading, payload);
  };

  return (
    <>
      {!success && loading && <FullPageLoader />}

      {success && !loading && (
        <ProcessingRequest setSuccess={() => setSuccess(false)}>
          <Text fontSize={"1.5em"}>Sucessfully Edited</Text>
        </ProcessingRequest>
      )}

      {!success && !loading && (
        <SlideFade in={true} offsetX="-1000px" transition={{ ...transition }}>
          <Stack>
            <Box shadow="sm">
              <Container>
                <Header goto={PARTY_EVENT} />
              </Container>
            </Box>
            <Container>
              <FormControl minH="80vh">
                <Stack mb="20" spacing="5">
                  <Text fontSize="1.2rem" py="4">
                    Make a request from the estate management for your
                    party/event
                  </Text>
                  <HStack justify="space-between">
                    <FormLabel>
                      Total number of guest
                      <NumberInput
                        w="100px"
                        min={0}
                        value={partyEventValues?.numberOfGuests}
                        onChange={handleNumberOfGuests}
                      >
                        <NumberInputField
                          {...inputBorder}
                          textAlign="center"
                          placeholder="10"
                        />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    </FormLabel>
                    <Box>
                      <FormLabel m="0">Date</FormLabel>
                      <Center
                        {...inputBorder}
                        className="special-request-date-picker-wrapper"
                        position="relative"
                      >
                        <DatePicker
                          multiple="true"
                          minDate={today}
                          placeholder={dayjs(today).format("DD-MMM-YYYY")}
                          onChange={handleNumberOfDays}
                        />
                        <Box pos="absolute" pointerEvents="none" right="15px">
                          <BsAlarmFill size="20px" />
                        </Box>
                      </Center>
                    </Box>
                  </HStack>

                  <FormLabel>
                    Venue for Party
                    <Select
                      {...inputBorder}
                      value={partyEventValues?.address}
                      placeholder={partyEventValues?.address}
                      onChange={onChange}
                      name="address"
                    >
                      {[1, 1, 1].map(() => (
                        <option key={v4()}>Central Hall</option>
                      ))}
                    </Select>
                  </FormLabel>
                  <FormLabel>
                    Reason/desription for invite
                    <Textarea
                      {...inputBorder}
                      name="description"
                      onChange={onChange}
                      value={partyEventValues?.description}
                      placeholder="Type here..."
                      _placeholder={{ opacity: 1, color: "black" }}
                      resize="none"
                      rows="5"
                    />
                  </FormLabel>
                </Stack>
                {/* <PrimaryButton
                  // onClick={() => setSuccess(t
                  onClick={handleSendRequest}
                  btnText='Edit Request'
                /> */}

                <CustomeSpecialReqestModal
                  allowToOpen
                  btnTitle={<PrimaryButton btnText="Edit Special Request" />}
                >
                  {/* <PartyEventSummary
                    values={partyEventValues}
                    setSuccess={setSuccess}
                    edit
                  /> */}
                  <Stack pos="relative">
                    <VStack>
                      <Circle className="vehicle-payment-money" bg="secondary">
                        <FaMoneyBillAlt color="#fff" size="2.8rem" />
                      </Circle>
                      <Text pb="3" fontSize="1.2rem" fontWeight={"600"}>
                        Special Request Summary
                      </Text>
                    </VStack>
                    {/* <Stack> */}

                    <Text fontSize="1rem">
                      <em style={{ fontWeight: "600" }}>Venue: </em>{" "}
                      {partyEventValues?.address}{" "}
                    </Text>

                    <Text>
                      <em style={{ fontWeight: "600" }}>
                        Expected number of Guests:
                      </em>{" "}
                      {partyEventValues?.numberOfGuests}
                    </Text>

                    <Text fontSize="1rem">
                      <em style={{ fontWeight: "600" }}>dates:</em>{" "}
                    </Text>

                    <Flex wrap="wrap" gap="0.6rem" pb="10">
                      {partyEventValues?.dates?.map((date) => (
                        <Text
                          key={v4()}
                          bg="gray.100"
                          px="5px"
                          py="0.5"
                          fontWeight="600"
                          fontSize="11px"
                        >
                          {dayjs(date).format("DD-MMM-YYYY")}
                        </Text>
                      ))}
                    </Flex>

                    <PrimaryButton
                      isLoading={loading}
                      onClick={handleEditRequest}
                      btnText="Edit Request"
                    />
                  </Stack>
                </CustomeSpecialReqestModal>
              </FormControl>
            </Container>
          </Stack>
        </SlideFade>
      )}
    </>
  );
};
