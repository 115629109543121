import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Box,
  useDisclosure,
  Flex,
  Avatar,
  Stack,
  Text,
  Divider,
  MenuItem,
  useColorModeValue,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { COLORS } from "../../../constants/colors";

export const VeiwModal = ({ datum }) => {
  const cardBg = useColorModeValue(COLORS.lightGrey, COLORS.white);
  const whiteCol = useColorModeValue(COLORS.primary, COLORS.lightGrey);
  const detailsCardBg = useColorModeValue(COLORS.white, COLORS.darkGrey);
      const menuColor = useColorModeValue(COLORS.black, COLORS.white);

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box>
      <MenuItem onClick={onOpen} color={menuColor}>
        View Details
      </MenuItem>
      <Modal size="sm" isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalBody borderRadius=".8rem">
            <Box my="30px">
              <Flex align="center">
                <Avatar name={datum?.contactPerson} size="lg" />

                <Stack pl="10px">
                  <Text color={cardBg} fontSize="lg">
                    {datum?.contactPerson}
                  </Text>
                  <Text color={whiteCol} fontSize="md">
                    {datum?.contactPersonPhone}
                  </Text>
                  <Flex>
                    <Text color="#FEE8E7" fontSize="x-small">
                      Created:
                    </Text>
                    <Text color="#B7B6B6" fontSize="x-small" pl="5px">
                      {dayjs(datum?.createdAt).format("DD/MMM/YYYY")}
                    </Text>
                    <Text color="#FEE8E7" fontSize="x-small" pl="5px">
                      Updated
                    </Text>
                    <Text color="#B7B6B6" fontSize="x-small" pl="5px">
                      {dayjs(datum?.updateAt).format("DD/MMM/YYYY")}
                    </Text>
                  </Flex>
                  <Divider orientation="horizontal" color="#FAF6FF" />
                </Stack>
              </Flex>
              <Divider />
              <Box
                display="flex"
                flexDirection="column"
                gap="1rem"
                bg={detailsCardBg}
                p="5"
                borderRadius="lg"
                my="40px"
                color={cardBg}
              >
                <Flex width="100%" justify="space-between">
                  <Text fontSize="sm">Description</Text>
                  <Text color={whiteCol} fontSize="md" maxW={"60%"}>
                    {datum?.description}
                  </Text>
                </Flex>
                <Flex width="100%" justify="space-between">
                  <Text fontSize="sm">Type</Text>
                  <Text color={whiteCol} fontSize="md">
                    {datum?.subType}
                  </Text>
                </Flex>
                <Flex width="100%" justify="space-between">
                  <Text fontSize="sm">Start date</Text>
                  <Text color={whiteCol} fontSize="md">
                    {dayjs(datum?.startDate).format("DD/MM/YYYY")}
                  </Text>
                </Flex>
                <Flex width="100%" justify="space-between">
                  <Text fontSize="sm">End date</Text>
                  <Text color={whiteCol} fontSize="md">
                    {dayjs(datum?.endDate).format("DD/MM/YYYY")}
                  </Text>
                </Flex>
                <Flex width="100%" justify="space-between">
                  <Text fontSize="sm">Status</Text>
                  <Text
                    color="#F98B88"
                    fontSize="xs"
                    bg="#FAF6FF"
                    borderRadius="full"
                    p="3px 12px"
                  >
                    {datum.status}
                  </Text>
                </Flex>

                <Flex justify="space-between" align="center">
                  <Text fontSize="sm">Guest Code</Text>
                  <Text color={whiteCol} fontSize="md">
                    {datum?.inviteCode}
                  </Text>
                </Flex>
                {/*<Flex justify="space-between" align="center" color={whiteCol}>
                  <Text>One Time Visit</Text>
                  {datum.oneTimeVisit ? (
                    <Text>Yes</Text>
                  ) : (
                    <Text color={whiteCol}>No</Text>
                  )}
                  </Flex>*/}

                <Flex justify="space-between" align="center" color={whiteCol}>
                  <Text>Allow Company</Text>
                  {datum?.noOfGuests > 1 ? (
                    <Text>Yes</Text>
                  ) : (
                    <Text color={whiteCol}>No</Text>
                  )}
                </Flex>
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
