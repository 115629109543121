import { Box, Center, Input, Text } from '@chakra-ui/react';

export const Success = () => {
  return (
    <Center
      w='100vw'
      position='fixed'
      top='0'
      left='0'
      bg='#FFFFFF'
      h='100vh'
      flexDirection='column'
      mx='auto'
    >
      <Box>
        <Input className='success' type='checkbox' id='check' />
        <label htmlFor='check' id='checker'>
          <div className='check-icon'></div>
        </label>
      </Box>
      <Text color='#614385' fontSize='lg' pt='10px' textAlign='center'>
        Successful
      </Text>
    </Center>
  );
};
