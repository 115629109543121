import { createContext, useState, useEffect, useContext } from "react";

export const AuthContext = createContext({});

const AuthContextProvider = ({ children }) => {
  const [isLoggedin, setIsLoggedin] = useState(() => {
    // Initialize isLoggedin based on the presence of a token in localStorage
    return !!localStorage.getItem("11p221#");
  });

  const [userInfo, setUserInfo] = useState(() => {
    // Initialize userInfo based on stored localStorage data
    return {
      token: localStorage.getItem("11p221#") || "",
      userId: localStorage.getItem("userId") || "",
    };
  });

  useEffect(() => {
    const token = localStorage.getItem("11p221#");
    const userId = localStorage.getItem("userId");

    // Update userInfo if localStorage changes
    setUserInfo({ token, userId });

    // Update isLoggedin based on the presence of a token
    setIsLoggedin(!!token);
  }, []);

  return (
    <AuthContext.Provider value={{ isLoggedin, setIsLoggedin, userInfo }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
