import { Circle, HStack, Stack, Text } from "@chakra-ui/react";
import { boldtext, countPickedDaysInDateRange } from "./inviteData";

export default function Summary(props) {
  const { customVisitValues, name, phone } = props;
  console.log(props, "days");

  const { weekdaysOfDatesWithinRange, datesOfDatesWithinRange } =
    props.getDatesWithinDateRange();

  return (
    <Stack fontWeight="500">
      <Text fontSize="1.3rem" pb="3" fontWeight="500" textAlign={"center"}>
        Invitation summary
      </Text>
      <HStack>
        <Text w="110px">Name: </Text>
        <Text {...boldtext}>{name} </Text>
      </HStack>
      <HStack>
        <Text w="110px">Phone number: </Text>
        <Text {...boldtext}>{phone} </Text>
      </HStack>

      <HStack>
        <Text w="110px">Visit days: </Text>
        <HStack>
          {customVisitValues?.days.map((day) => (
            <Circle
              fontWeight="600"
              key={day}
              w="35px"
              h="35px"
              fontSize="12px"
              bg="bgColor"
            >
              {day}
            </Circle>
          ))}
        </HStack>
      </HStack>

      {datesOfDatesWithinRange && (
        <HStack>
          <Text w="110px">No of visits: </Text>
          <Text {...boldtext}>
            {countPickedDaysInDateRange(
              weekdaysOfDatesWithinRange,
              customVisitValues.days
            )}
          </Text>
        </HStack>
      )}

      <HStack>
        <Text w="110px">Visit Start date: </Text>
        <Text {...boldtext}>{customVisitValues.startDate} </Text>
      </HStack>
      <HStack>
        <Text w="110px">Visit end date: </Text>
        <Text {...boldtext}>{customVisitValues.endDate} </Text>
      </HStack>
    </Stack>
  );
}
