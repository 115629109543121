export const issueTypes = ['House Colapse', 'Flood', 'Dangerous bend', 'Security bridge', 'Noisy neeighbour', 'Others'];

export const formLabelStyles = {
  htmlFor: 'media',
  // borderRadius: '50%',
  // bg: 'primaryDark',
  // h: '50px',
  // w: '100px',
  // display: 'flex',
  // justifyContent: 'center',
  // alignItems: 'center',
};

export const issueTypeStyles = {
  h: '20px',
  px: 2,
  w: '120px',
  borderRadius: '20px',
  border: '1px solid #FEE8E7',
  bg: '#FAF6FF',
  fontSize: '1rem',
  justifyContent: 'start',
};
