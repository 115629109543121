import { PROTECTED_PATHS, PUBLIC_PATHS } from '../../../app/constants';
import { errorNotifier, successNotifier } from '../../../components/NotificationHandler';
import { timeout } from '../../../components/utils/timeout';
import http, { AUTH_ROUTES } from '../../../services/api';

const { LOGIN } = PUBLIC_PATHS;

export const resetCode = async (payload, setLoading, setIsLoggedin) => {
  try {
    const { LOGIN } = PROTECTED_PATHS;
    setLoading(true);
    const {
      data: { result },
    } = await http.put(AUTH_ROUTES.UPDATE_TENANT_CODE, payload);

    if (!result.success) return;

    successNotifier(result.message);
    setLoading(false);
    setIsLoggedin(true);
    localStorage.clear();
    window.location.href = LOGIN;
  } catch (e) {
    setLoading(false);
    setIsLoggedin(false);

    if (e?.response) {
      e.response?.data?.message ? errorNotifier(e.response?.data?.message) : errorNotifier(e.response?.data?.result?.message);
    } else {
      errorNotifier('Please Check your network connection!!!');
    }
  }
};

export const requestResetPassword = async (setLoading, tenantEmail, navigate) => {
  setLoading(true);
  try {
    const { data } = await http.post(AUTH_ROUTES.RESET_PASSWORD, tenantEmail);

    successNotifier(data?.result?.message);
    timeout(() => navigate(LOGIN), 2500);
  } catch (e) {
    if(e.response) {
      errorNotifier(e.response?.data?.message);
    } else {
      errorNotifier("Please check your network connection");
    }
  } finally {
    setLoading(false);
  }
};

export const validateOtp = async (payload, setLoading, navigate) => {
  setLoading(true);
  try {
    await http.post(AUTH_ROUTES.VALIDATE_OTP, payload);

    successNotifier('success proceed to creat a new password');
    timeout(() => navigate(LOGIN), 2500);
  } catch (e) {
    errorNotifier(e.response.data.errors[0].msg);
  } finally {
    setLoading(false);
  }
};
