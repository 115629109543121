import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuList,
  SlideFade,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { COLORS } from "../../../constants/colors";
import dayjs from "dayjs";
import { Popover } from "../../../components/Popover";
import Container from "../../../components/Container";
import { transition } from "../../..";
import Header from "../../../components/Header";
import { PROTECTED_PATHS } from "../../../app/constants";
import { BsFillPlusCircleFill, BsSearch } from "react-icons/bs";
import { Link } from "react-router-dom";
import { addBtnStyles } from "../../issue report";
import { getVehicleList } from "../service/vehicle";
import { AuthContext } from "../../../context/authContext";
import { VeiwModal } from "./VeiwModal";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { DeleteContractorModal } from "./DeleteContractorModal";

export const VehicleList = (props) => {
  const { userInfo } = useContext(AuthContext);
  const { userId } = userInfo;
  const { SPECIAL_GUEST_REQUEST, VEHICLES } = PROTECTED_PATHS;
  const { name, endDate, allowCompany } = props;
  const cardBg = useColorModeValue(COLORS.lightGrey, COLORS.white);
  const bgColor = useColorModeValue(COLORS.primary, COLORS.green);
  const appTextColor = useColorModeValue(COLORS.primary, COLORS.primary);
  const [vehicleList, setVehicleList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    getVehicleList(userId, setLoading, setVehicleList);
  }, [userId, ignored]);
  console.log("vehicleList:", vehicleList);
  console.log("userId:", userId);
  return (
    <SlideFade in={true} offsetX="-1000px" transition={{ ...transition }}>
      <Stack>
        <Box shadow="sm">
          <Container>
            <Header goto={SPECIAL_GUEST_REQUEST} />
          </Container>
        </Box>
        <Container>
          <InputGroup my={"50px"}>
            <InputLeftElement fontSize="1.2em" children={<BsSearch />} />
            <Input
              border="none"
              borderRadius="30px !important"
              placeholder="Search Vehicle"
              _placeholder={{ opacity: 1, color: `${appTextColor}` }}
              color={cardBg}
              bg={COLORS.lightBrown}
            />
          </InputGroup>
          {vehicleList?.map((datum) => (
            <Box
              p="1rem"
              borderRadius="10px"
              boxShadow="sm"
              bg={cardBg}
              color={appTextColor}
              mb="20px"
              key={datum._id}
            >
              <Flex justify="space-between" align="center">
                <Stack fontSize="0.8rem" lineHeight={1}>
                  <Text
                    fontSize="1.1rem"
                    fontWeight="semibold"
                    color={COLORS.black}
                  >
                    {datum?.contactPerson}
                  </Text>
                  <Text fontWeight={"semibold"} fontSize="1rem">
                    {datum?.subType}
                  </Text>
                  <Text>
                    Last day of visit{" "}
                    {dayjs(datum?.dates[0]).format("DD/MMM/YYYY")}
                  </Text>
                </Stack>
                <Box alignSelf={"flex-start"}>
                  <Menu>
                    <MenuButton>
                      <BiDotsVerticalRounded size="1.5rem" />
                    </MenuButton>
                    <MenuList>
                      <VeiwModal datum={datum} />
                      <DeleteContractorModal
                        datum={datum}
                        forceUpdate={forceUpdate}
                      />
                    </MenuList>
                  </Menu>
                </Box>
              </Flex>
            </Box>
          ))}

          <Link to={VEHICLES}>
            <Flex {...addBtnStyles}>
              <BsFillPlusCircleFill color={bgColor} size={"2.5rem"} />
            </Flex>
          </Link>
        </Container>
      </Stack>
    </SlideFade>
  );
};
