import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { infoNotifier } from '../../../../components/NotificationHandler';
import { UserProfileContext } from '../../../../context/userProfileContext';
import { buyDataSubscription } from '../../service/dataPlans';

const useBuyData = () => {
  const { userProfile } = useContext(UserProfileContext);
  const { _id, tenantName } = userProfile;
  const [success, setSuccess] = useState(false);
  const [mobileNumber, setMobileNumber] = useState('');
  const [selectedOption, setSelectedOption] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleDataSubscription = (network) => {
    const payload = {
      network,
      mobileNumber,
      plan_id: selectedOption?.planId,
      userId: _id,
      tenantName,
      amount: selectedOption?.companyAmount + selectedOption?.planAmount,
    };

    if (!payload.plan_id) {
      infoNotifier('Please select a data plan');
      return;
    }

    if (payload.mobileNumber.length !== 11) {
      infoNotifier('Invalid Phone number');
      return;
    }

    buyDataSubscription(setLoading, payload, navigate, setSuccess);
  };

  return { loading, mobileNumber, setMobileNumber, handleDataSubscription, setLoading, selectedOption, setSelectedOption, success };
};

export default useBuyData;
