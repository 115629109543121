import { Circle, HStack } from '@chakra-ui/react';
import React from 'react';

const Dots = ({ screen }) => {
  return (
    <HStack justify='center'>
      <Circle h='5px' w='5px' bg={screen === 1 ? 'primaryDark' : 'gray.300'} />
      <Circle h='5px' w='5px' bg={screen === 2 ? 'primaryDark' : 'gray.300'} />
      <Circle h='5px' w='5px' bg={screen === 3 ? 'primaryDark' : 'gray.300'} />
    </HStack>
  );
};

export default Dots;
