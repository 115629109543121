import { useContext, useEffect, useState } from 'react';
import etisalat from '../../../../assets/9mobile.svg';
import { AuthContext } from '../../../../context/authContext';
import { getDataPlans } from '../../service/dataPlans';
import DataPayment from './DataPayment';
import useBuyData from './useBuyData';

const EtisalatDataPayment = () => {
  const { userInfo } = useContext(AuthContext);
  const { userId } = userInfo;
  const [dataPlans, setDataPlans] = useState([]);

  useEffect(() => {
    getDataPlans(userId, setDataPlans);
  }, [userId]);

  const removeWords = (word) => `${word.split(' ').slice(1, 4).join('')})`;

  const filterDataPlansithLabel = () => {
    return dataPlans
      .filter((plan) => plan.provider === '9MOBILE')
      .map((m) => ({ ...m, label: removeWords(m.name) }));
  };

  const {
    handleDataSubscription,
    setMobileNumber,
    mobileNumber,
    selectedOption,
    setSelectedOption,
    loading,
    success,
  } = useBuyData();

  // const etisalatDataPlans = dataPlans.filter((plan) => plan.isp === '9MOBILE');

  return (
    <DataPayment
      logo={etisalat}
      sucess={success}
      dataPlans={filterDataPlansithLabel()}
      selectedOption={selectedOption}
      setSelectedOption={setSelectedOption}
      setMobileNumber={setMobileNumber}
      mobileNumber={mobileNumber}
      handleDataSubscription={() => handleDataSubscription(3)}
      loading={loading}
    />
  );
};

export default EtisalatDataPayment;
