import { Box, HStack, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { COLORS } from '../../../constants/colors';

export const RequestTypeCard = ({ icon, heading, text, to }) => {

  const navigate = useNavigate(to);
  return (
    <Stack
      px="20px"
      py="35px"
      rounded={"lg"}
      cursor="pointer"
      onClick={() => navigate(to)}
      bg={COLORS.white}
      shadow={"lg"}
    >
      <HStack>
        <Box>{icon}</Box>
        <Text fontSize="16px" fontWeight="700">
          {heading}
        </Text>
      </HStack>
      <Text>{text}</Text>
    </Stack>
  );
};
