import { Box, Button, Flex, HStack, Img, Stack, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useEffect } from "react";
import { BiEditAlt } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import { v4 } from "uuid";
import { PROTECTED_PATHS } from "../../../app/constants";
import CautionAlertDialog from "../../../components/CautionAlertDialog";
import Container from "../../../components/Container";
import FullPageLoader from "../../../components/FullPageLoader";
import Header from "../../../components/Header";
import { dynamicPathhandler } from "../../../components/utils/dynamicPathHandler";
import { UserProfile } from "../../../context/userProfileContext";
import {
  deleteUserProduct,
  getSingleArchieveProduct,
  resellProduct,
} from "../service";
import { deleteBtn } from "./ViewOneShopProduct";
import { COLORS } from "../../../constants/colors";

export const ViewOneArchievedProduct = () => {
  // const { userInfo } = useAuth();
  // const { userId, tenantCode } = userInfo;
  const { userProfile } = UserProfile();
  const { _id, tenantCode } = userProfile;
  const { archievedProduct } = useParams();
  const navigate = useNavigate();
  const { SHOP, EDIT_SHOP_PRODUCT } = PROTECTED_PATHS;
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [singleArchievedProduct, setSingleArchievedProduct] = useState([]);
  const parentPath = dynamicPathhandler(EDIT_SHOP_PRODUCT);

  useEffect(() => {
    getSingleArchieveProduct(
      _id,
      setLoading,
      setSingleArchievedProduct,
      archievedProduct
    );
  }, [_id, archievedProduct]);

  const handleDelete = (product) => {
    const payload = { userId: _id, productId: product._id };
    deleteUserProduct(payload, setBtnLoading, navigate);
  };

  const handleResell = (prod) => {
    const payload = {
      advertId: prod._id,
      userId: _id,
      tenantCode,
      status: "PENDING",
    };
    resellProduct(payload, setLoading, navigate);
  };

  return loading ? (
    <FullPageLoader />
  ) : (
    <Stack>
      <Box shadow="sm">
        <Container>
          <Header goto={SHOP} />
        </Container>
      </Box>

      <Container>
        <Box bg={"white"} p={"20px"} rounded={"lg"}>
          <HStack wrap="wrap" gap="1rem">
            {singleArchievedProduct?.imageUrl?.map((img) => (
              <Img
                key={v4()}
                m="0 !important"
                w="100px"
                h="100px"
                src={img}
                alt={singleArchievedProduct?.itemName}
                shadow="sm"
                borderRadius="4px"
              />
            ))}
          </HStack>
        </Box>
        <Box bg={"white"} p={"20px"} rounded={"lg"} mt="30px">
          <Stack py="5" spacing="7" fontSize="1rem">
            <HStack justify="space-between">
              <Text color="#B7B6B6">Name of item</Text>
              <Text color="#161616">{singleArchievedProduct?.itemName}</Text>
            </HStack>
            <HStack justify="space-between">
              <Text color="#B7B6B6">Category</Text>
              <Text color="#161616">{singleArchievedProduct?.category}</Text>
            </HStack>
            <HStack justify="space-between">
              <Text color="#B7B6B6">Sub Category</Text>
              <Text color="#161616">
                {singleArchievedProduct?.subCategory || "N/A"}
              </Text>
            </HStack>
            <HStack justify="space-between">
              <Text color="#B7B6B6">Price</Text>
              <Text color="#161616">
                &#8358; {singleArchievedProduct?.price?.toLocaleString()}
              </Text>
            </HStack>
            <HStack justify="space-between">
              <Text color="#B7B6B6">Quantity</Text>
              <Text color="#161616">
                {singleArchievedProduct?.quantity || "N/A"}
              </Text>
            </HStack>
            <Text color="#B7B6B6">Description</Text>
            <Text color="#161616">
              {singleArchievedProduct?.itemDescription}
            </Text>
          </Stack>
        </Box>
        <Flex gap="2rem" mt={"2rem"}>
          <CautionAlertDialog
            size="xs"
            loading={btnLoading}
            onContinue={() => handleResell(singleArchievedProduct)}
            buttonProps={{ ...deleteBtn, color: "teal.300" }}
            button="Resell"
          >
            <Text fontSize="1.2rem" textAlign="center">
              Resell with the following Product inforamation?
            </Text>
          </CautionAlertDialog>

          <CautionAlertDialog
            size="xs"
            loading={btnLoading}
            onContinue={() => handleDelete(singleArchievedProduct)}
            buttonProps={{ ...deleteBtn }}
            button=" Delete"
          >
            <Text fontSize="1.2rem" textAlign="center">
              Premanently Delete Product?
            </Text>
          </CautionAlertDialog>
        </Flex>
        <Flex justify="flex-end" py="8">
          <Button
            _focus={{ outline: "none" }}
            // leftIcon={<BiEditAlt />}
            // flex="1"
            w="100px"
            bg={COLORS.green}
            onClick={() =>
              navigate(`${parentPath}/${singleArchievedProduct._id}`)
            }
            variant="outline"
          >
            Edit
          </Button>
        </Flex>
      </Container>
    </Stack>
  );
};
