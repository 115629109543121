import React, { useContext,useEffect, useState } from "react";
import {
  Box,
  Img,
  Text,
  Stack,
  Flex,
  Button,
  Center,
  useColorModeValue,
  IconButton,
  Textarea,
  Input
} from "@chakra-ui/react";
import {
  errorNotifier,
  successNotifier,
} from "../../../components/NotificationHandler";
import { MdPhone, MdStar, MdSend } from "react-icons/md";
import { useParams } from "react-router-dom";
import Container from "../../../components/Container";
import Header from "../../../components/Header";
import Footer from "../../../components/footer";
import { COLORS } from "../../../constants/colors";
import FullPageLoader from "../../../components/FullPageLoader";
import { fetchArtisanDetails, submitArtisanRating, fetchArtisanReviews } from "../service";
import { PROTECTED_PATHS } from "../../../app/constants";
import { AuthContext } from "../../../context/authContext";

const ArtisanDetails = () => {
  const { artisanId } = useParams();
  const [artisan, setArtisan] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const pageBg = useColorModeValue(COLORS.bgGrey, COLORS.darkModeBg);
  const textColor = useColorModeValue(COLORS.black, COLORS.white);
  const btTextColor = useColorModeValue(COLORS.white, COLORS.primary);
  const cardBg = useColorModeValue(COLORS.primary, COLORS.white);
  const { userInfo } = useContext(AuthContext);
  const { userId } = userInfo;

  const { ARTISAN_PAGE } = PROTECTED_PATHS;

  const originalArtisanId = artisanId.replaceAll("-", "/");

  useEffect(() => {
    if (originalArtisanId) {
      fetchArtisanDetails(originalArtisanId, setArtisan, setError, setLoading);
    }
  }, [originalArtisanId]);
  
  useEffect(() => {
    if (artisan && artisan._id) {
      fetchArtisanReviews(artisan._id, setReviews, setLoading);
    }
  }, [artisan]);
  

  const handleRatingClick = (star) => {
    setRating(star);
  };

  const handleSubmit = () => {
    if (comment.trim()) {
      const payload = {
        rating,
        comment,
        artisanId: artisan._id,
        ratedBy: userId,
      };
  
      submitArtisanRating(
        setLoading,
        setError,
        payload,
        (data) => {
          successNotifier("Your rating has been submitted successfully.");
          window.location.reload();
        }
      );
    } else {
      errorNotifier("Please add a comment before submitting.");
    }
  };
  

  const [reviews, setReviews] = useState([]);
  const isStarActive = (star) => star <= rating;


  if (loading) {
    return <FullPageLoader />;
  }


  

  return (
    <Box bg={pageBg} minH="100%" display="flex"  pb="5rem" flexDirection="column">
      <Box shadow="sm">
        <Container>
          <Header goto={ARTISAN_PAGE} />
        </Container>
      </Box>

      <Box flex="1" overflowY="auto">
        <Container>
          <Stack spacing="3" py="5">
            <Flex justifyContent="center">
              <Img
                src={artisan.image}
                alt={artisan.name}
                borderRadius="full"
                boxSize="150px"
                objectFit="cover"
              />
            </Flex>
            <Text
              textAlign="center"
              fontSize="2xl"
              fontWeight="bold"
              color={textColor}
            >
              {artisan.name}
            </Text>
            {artisan.subscription && (
              <Flex justifyContent="center" alignItems="center">
                <MdStar
                  color={COLORS.green}
                  size="20px"
                  style={{ marginRight: "8px" }}
                />
                <Text color={COLORS.green} fontWeight="bold" fontSize="sm">
                  Top Artisan
                </Text>
              </Flex>
            )}
            <Text textAlign="center" fontSize="lg" color={COLORS.green}>
              {artisan.skillType}
            </Text>

            <Box borderWidth="1px" borderRadius="lg" p="6" bg={COLORS.white}>
              <Stack spacing="4">
                <Flex justifyContent="space-between">
                  <Text fontWeight="bold" color={textColor}>
                    Email:
                  </Text>
                  <Text>{artisan.email}</Text>
                </Flex>
                <Flex justifyContent="space-between">
                  <Text fontWeight="bold" color={textColor}>
                    Phone Number:
                  </Text>
                  <Text>{artisan.phoneNumber}</Text>
                </Flex>
                <Flex justifyContent="space-between">
                  <Text fontWeight="bold" color={textColor}>
                    Address:
                  </Text>
                  <Text>{artisan.address}</Text>
                </Flex>
                <Flex justifyContent="space-between">
                  <Text fontWeight="bold" color={textColor}>
                    Approved:
                  </Text>
                  <Text>{artisan.approved ? "Yes" : "No"}</Text>
                </Flex>
                <Flex justifyContent="space-between">
                  <Text fontWeight="bold" color={textColor}>
                    Level of Expertise:
                  </Text>
                  <Text>{artisan.expertise} / 10</Text>
                </Flex>
                <Flex justifyContent="space-between">
                  <Text fontWeight="bold" color={textColor}>
                    Registered On:
                  </Text>
                  <Text>
                    {new Date(artisan.createdAt)
                      .toLocaleDateString("en-GB", {
                        day: "numeric",
                        month: "long",
                        year: "numeric"
                      })
                      .replace(",", " of")}
                  </Text>
                </Flex>
              </Stack>

              {/* Call Button */}
              <Flex justifyContent="center" pt="4">
                <Button
                  bg={cardBg}
                  color={btTextColor}
                  size="md"
                  leftIcon={<MdPhone />}
                  onClick={() =>
                    (window.location.href = `tel:${artisan.phoneNumber}`)
                  }
                >
                  Call {artisan.name}
                </Button>
              </Flex>
            </Box>
          </Stack>

          {/* Rating and Comment Section */}
          <Stack>
            <Box borderWidth="1px" borderRadius="lg" p="6" bg={COLORS.white}>
              <Text fontWeight="bold" color={textColor} mb="4">
                Rate this Artisan:
              </Text>
              <Flex mb="4">
                {[1, 2, 3, 4, 5].map((star) => (
                  <IconButton
                  key={star}
                  icon={<MdStar />}
                  aria-label={`Rate ${star}`}
                  onClick={() => handleRatingClick(star)}
                  colorScheme={isStarActive(star) ? "yellow" : "gray"}
                  mx="1"
                />
                ))}
              </Flex>
              <Textarea
                placeholder="Leave a comment..."
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                mb="4"
              />
              <Flex justifyContent="center">
                <Button
                  bg={cardBg}
                  color={btTextColor}
                  size="md"
                  leftIcon={<MdSend />}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Flex>
            </Box>
          </Stack>

          <Stack py="5">
            {/* Display Reviews Section */}
            <Box borderWidth="1px" borderRadius="lg" p="6" bg={COLORS.white}>
              <Text fontWeight="bold" color={textColor} mb="4">
                Reviews:
              </Text>
              {reviews.length > 0 ? (
                reviews.map((review, index) => (
                  <Box
                    key={index}
                    borderWidth="1px"
                    borderRadius="md"
                    p="4"
                    mb="4"
                  >
                    <Flex justifyContent="space-between" alignItems="center">
                      <Flex>
                        {[...Array(review.rating)].map((_, idx) => (
                          <MdStar key={idx} color="gold" />
                        ))}
                      </Flex>
                      <Text fontSize="sm" color="gray.500">
                      {new Date(review.createdAt).toLocaleDateString("en-GB", {
            day: "numeric",
            month: "long",
            year: "numeric",
          })}
                      </Text>
                    </Flex>
                    <Text mt="2" color={textColor}>
                      {review.comment}
                    </Text>
                  </Box>
                ))
              ) : (
                <Text color={textColor}>No reviews yet.</Text>
              )}
            </Box>
          </Stack>
        </Container>
      </Box>

      <Footer />
    </Box>
  );
};

export default ArtisanDetails;
