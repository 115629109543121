import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Box,
  useDisclosure,
  Flex,
  Circle,
  Stack,
  MenuItem,
  Button,
  useColorModeValue,
} from "@chakra-ui/react";
import { COLORS } from "../../../constants/colors";
import { deleteSpecialRequest } from "../service";
import { useContext, useState } from "react";
import { AuthContext } from "../../../context/authContext";

export const DeletePartyModal = ({ forceUpdate, props }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const { userInfo } = useContext(AuthContext);
  const { userId } = userInfo;
  const appTextColor = useColorModeValue(COLORS.lightGrey, COLORS.white);
  const menuColor = useColorModeValue(COLORS.black, COLORS.white);

  const payload = {
    userId,
    requestId: props._id,
  };

  const handleDelete = () => {
    setLoading(true);
    deleteSpecialRequest(payload, setLoading, payload.userId, payload.requestId)
      .then(() => {
        setLoading(false);
        onClose();
        forceUpdate();
      })
      .catch((error) => {
        console.error("Error deleting:", error);
        setLoading(false);
      });
  };

  return (
    <Box>
      <MenuItem onClick={onOpen} color={menuColor}>
        Delete
      </MenuItem>

      <Modal size="xs" isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalBody textAlign={"center"}>
            <Circle
              mx="auto"
              align="center"
              my="5"
              size="30px"
              bg="#FFF"
              color="white"
              border="1px solid #707070"
            />
            <Stack>
              <Text fontSize="lg" px="auto" fontWeight="light" color="#7A7A7A">
                Are you sure?
              </Text>

              <Text fontSize="sm" color="#7A7A7A">
                This action cannot be reversed
              </Text>

              <Flex justify={"center"} gap="2rem" fontSize="md" pb="5">
                <Button color="#B7B6B6" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  color="#FC7067"
                  pl="5"
                  isLoading={loading}
                  onClick={handleDelete}
                >
                  Delete
                </Button>
              </Flex>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

// import {
//   Text,
//   Modal,
//   ModalOverlay,
//   ModalContent,
//   ModalBody,
//   Box,
//   useDisclosure,
//   Flex,
//   Circle,
//   Stack,
//   MenuItem,
//   Button,
//   useColorModeValue,
// } from "@chakra-ui/react";
// import { COLORS } from "../../../constants/colors";
// import { deleteGuestFromEventList } from "../service/partyAndEvents";
// import { useContext, useState } from "react";
// import { AuthContext } from "../../../context/authContext";
// import { deleteSpecialRequest } from "../service";

// export const DeletePartyModal = (props) => {
//   const { isOpen, onOpen, onClose } = useDisclosure();
//   const [loading, setLoading] = useState(false);
//   const { userInfo } = useContext(AuthContext);
//   const { userId } = userInfo;
//   const appTextColor = useColorModeValue(COLORS.lightGrey, COLORS.white);

//   console.log(props, "delete");
//   const payload = {
//     userId,
//     requestId: props.props.props._id,
//   };
//   const handleDelete = () => {
//     deleteSpecialRequest(payload, setLoading, payload.userId, payload.requestId)
//       .then(() => {
//         props.setRefresh({});
//         onClose();
//         window.location.reload();
//       })
//       .catch((error) => {
//         console.error("Error deleting:", error);
//       });
//   };

//   // const payload = {
//   //   guestId: props?.props._id,
//   //   guestNumber: props?.props?.contactPersonPhone,
//   // };
//   // const handleDelete = () => {
//   //   deleteGuestFromEventList(
//   //     payload,
//   //     setLoading,
//   //     payload?.guestId,
//   //     payload?.guestNumber
//   //   ).then(props.setRefresh({}));
//   // };

//   return (
//     <Box>
//       <MenuItem onClick={onOpen} color={appTextColor}>
//         Delete
//       </MenuItem>

//       <Modal size="xs" isOpen={isOpen} onClose={onClose} isCentered>
//         <ModalOverlay />
//         <ModalContent>
//           <ModalBody textAlign={"center"}>
//             <Circle
//               mx="auto"
//               align="center"
//               my="5"
//               size="30px"
//               bg="#FFF"
//               color="white"
//               border="1px solid #707070"
//             />
//             <Stack>
//               <Text fontSize="lg" px="auto" fontWeight="light" color="#7A7A7A">
//                 Are you sure?
//               </Text>

//               <Text fontSize="sm" color="#7A7A7A">
//                 This action cannot be reversed
//               </Text>

//               <Flex justify={"center"} gap="2rem" fontSize="md" pb="5">
//                 <Button color="#B7B6B6" onClick={onClose}>
//                   Cancel
//                 </Button>
//                 <Button
//                   color="#FC7067"
//                   pl="5"
//                   onClick={() => handleDelete(props)}
//                 >
//                   delete
//                 </Button>
//               </Flex>
//             </Stack>
//           </ModalBody>
//         </ModalContent>
//       </Modal>
//     </Box>
//   );
// };
