import { useContext } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { infoNotifier } from '../../../../../components/NotificationHandler';
import { UserProfileContext } from '../../../../../context/userProfileContext';
import { payCableSubscription } from '../../../service/cable';

const usePayCableSubscription = () => {
  const navigate = useNavigate();
  const { userProfile } = useContext(UserProfileContext);
  const { _id, tenantName } = userProfile;
  const [IUCNumber, setIUCNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [selectedOption, setSelectedOption] = useState({});

  const handlePayCableSubscription = (cableId, planId) => {
    const payload = {
      cable_id: cableId,
      plan_id: planId,
      IUCNumber,
      userId: _id,
      tenantName,
      amount: selectedOption?.planAmount + selectedOption?.companyAmount,
    };

    if (!payload.plan_id) {
      infoNotifier('Please Select a plan');
      return;
    }
    if (!payload.IUCNumber) {
      infoNotifier('Please Enter your IUC number');
      return;
    }

    payCableSubscription(payload, setIsLoading, setSuccess, navigate);
  };

  return { isLoading, setIUCNumber, selectedOption, setSelectedOption, handlePayCableSubscription, success };
};

export default usePayCableSubscription;
