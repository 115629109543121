import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import {
  Box,
  SlideFade,
  Stack,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useColorModeValue,
  Image,
  Flex,
  Button,
} from "@chakra-ui/react";
import Container from "../../../components/Container";
import { getUserProfile } from "../../settings/service/getUserProfile";
import dayjs from "dayjs";
import { COLORS } from "../../../constants/colors";
import logo from "../logo.svg";
import { transition } from "../../..";

export const FilteredAccountStatement = () => {
  const cardBg = useColorModeValue("white", "gray.800");
  const headerBg = useColorModeValue(COLORS.green, COLORS.green);
  const headerColor = useColorModeValue("white", "white");
  const textColor = useColorModeValue("black", "white");
  const [userProfile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showButtons, setShowButtons] = useState(true);
  const location = useLocation();
  const data = location.state.data || [];
  const startDate = location.state.startDate || [];
  const endDate = location.state.endDate || [];
  const userId = data[0].userId;

  useEffect(() => {
    getUserProfile(userId, setLoading, setUserProfile);
  }, [userId]);

  const downloadPdf = () => {
    const doc = new jsPDF();
    const contentDiv = document.getElementById("pdf-container");
    setShowButtons(false);

    html2canvas(contentDiv, {
      scrollX: 0,
      scrollY: -window.scrollY,
      scale: 2,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdfWidth = doc.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

      doc.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      doc.save("account-statement.pdf");
    });
  };

  const handlePrint = () => {
    setShowButtons(false);
    setTimeout(() => {
      window.print();
    }, 1000);
  };

  // Calculate total amount
  const totalAmount = data.reduce(
    (acc, transaction) => acc + transaction.amount,
    0
  );

  // Calculate total balance
  const totalBalance = data.reduce(
    (acc, transaction) => acc + transaction.balanceAfter,
    0
  );

  return (
    <SlideFade in={true} offsetY="500px" {...transition}>
      <Stack
        spacing={0}
        p={[2, 4]}
        id="pdf-container"
        w={"100%"}
        // minW={["100%", "700px"]}
      >
        <Box
          shadow="md"
          bg="white"
          px={["20px", "30px"]}
          py={"20px"}
          display="flex"
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Flex align={"center"}>
            <Box maxW="200px">
              <Image src={logo} alt="Bank Logo" style={{ height: "40px" }} />
            </Box>
            <Text color={"#000"} fontSize={"18px"} fontWeight={"bold"}>
              MyEstateNG
            </Text>
          </Flex>
          <Text fontSize={["18px", "20px"]} color={"black"} fontWeight={"bold"}>
            Account Statement
          </Text>
        </Box>
        <Container
          // minW="100%"
          bg={cardBg}
          px={["20px", "30px"]}
          py={"50px"}
          boxShadow="md"
        >
          <Box mb={6}>
            <Text
              fontSize="xl"
              fontWeight="bold"
              mb={2}
              color={headerColor}
              p={"10px"}
              bg={headerBg}
            >
              Customer Details
            </Text>
            <Text color={textColor}>Name: {userProfile?.tenantName}</Text>
            <Text color={textColor}>Email: {userProfile?.email}</Text>
            <Text color={textColor}>Phone: {userProfile?.tenantPhone}</Text>
            <Text color={textColor}>
              Address: {userProfile?.houseId?.estate[0]?.houseNumber}
            </Text>
            <Text color={textColor}>User ID: {userProfile?._id}</Text>
          </Box>
          <Text
            border={`1px solid green`}
            py={"10px"}
            px={"5px"}
            mt={"20px"}
            fontWeight={"semibold"}
            color={textColor}
          >
            Duration: {dayjs(startDate).format("MMM D, YYYY")} -{" "}
            {dayjs(endDate).format("MMM D, YYYY")}
          </Text>
          <Flex mt={"20px"} w={"100%"} overflowX="hidden">
            <Table
              variant="simple"
              size="sm"
              bg="white"
              // borderRadius="lg"
              boxShadow={`0 4px 12px 0 gray.200`}
              borderColor="transparent"
            >
              <Thead>
                <Tr fontSize={"10px"} maxW={"100%"}>
                  <Th
                    bg={headerBg}
                    color={headerColor}
                    py={"10px"}
                    maxW={"90px"}
                    fontSize={"10px"}
                  >
                    Date
                  </Th>
                  <Th bg={headerBg} color={headerColor} fontSize={"10px"}>
                    Description
                  </Th>
                  <Th bg={headerBg} color={headerColor} fontSize={"10px"}>
                    Amount
                  </Th>
                  <Th bg={headerBg} color={headerColor} fontSize={"10px"}>
                    Category
                  </Th>
                  <Th bg={headerBg} color={headerColor} fontSize={"10px"}>
                    Balance
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {data?.map((transaction, index) => (
                  <Tr
                    key={transaction.id}
                    bg={index % 2 === 0 ? "gray.100" : "gray.200"}
                    fontSize={"8px"}
                  >
                    <Td maxW={"100px"} fontSize={"11px"}>
                      {dayjs(transaction?.createdAt).format("DD-MMM-YYYY")}
                    </Td>
                    <Td maxW={"90px"} fontSize={"11px"}>
                      {transaction?.transactionType}
                    </Td>
                    <Td fontSize={"11px"}>
                      &#8358; {transaction?.amount?.toLocaleString()}
                    </Td>
                    <Td fontSize={"11px"}>
                      {transaction?.transactioninfo.services}
                    </Td>
                    <Td fontSize={"11px"}>
                      &#8358; {transaction?.balanceAfter?.toLocaleString()}
                    </Td>
                  </Tr>
                ))}
                {/* New row for total amount */}
                <Tr>
                  <Td colSpan={1}></Td>
                  <Td fontSize={"11px"} fontWeight="bold">
                    Total:
                  </Td>
                  <Td fontSize={"11px"} fontWeight="bold">
                    &#8358; {totalAmount.toLocaleString()}
                  </Td>
                  <Td colSpan={1}></Td>
                  <Td fontSize={"11px"} fontWeight="bold">
                    &#8358; {totalBalance.toLocaleString()}
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Flex>
          <Flex justify={"flex-end"} mt={8}>
            <Button
              display={showButtons === false ? "none" : "block"}
              bg={headerBg}
              color={headerColor}
              size="lg"
              _hover={{ backgroundColor: headerBg }}
              onClick={handlePrint}
            >
              Print
            </Button>
          </Flex>
          <Box mt="100px">
            <Text textAlign="center">Powered by MyEstate.ng</Text>
          </Box>
        </Container>
      </Stack>
    </SlideFade>
  );
};

// import {
//   Box,
//   SlideFade,
//   Stack,
//   Text,
//   Table,
//   Thead,
//   Tbody,
//   Tr,
//   Th,
//   Td,
//   useColorModeValue,
//   Image,
//   Flex,
//   Button,
// } from "@chakra-ui/react";
// import React, { useEffect, useState, useRef } from "react";
// import { COLORS } from "../../../constants/colors";
// import Container from "../../../components/Container";
// import { PROTECTED_PATHS } from "../../../app/constants";
// import logo from "../logo.svg";
// import { useLocation } from "react-router-dom";
// import { getUserProfile } from "../../settings/service/getUserProfile";
// import dayjs from "dayjs";
// import { jsPDF } from "jspdf";
// import html2canvas from "html2canvas";

// export const FilteredAccountStatement = () => {
//   const cardBg = useColorModeValue("white", "gray.800");
//   const headerBg = useColorModeValue(COLORS.green, COLORS.green);
//   const headerColor = useColorModeValue("white", "white");
//   const textColor = useColorModeValue("black", "white");
//   const [userProfile, setUserProfile] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [showButtons, setShowButtons] = useState(true);
//   const location = useLocation();
//   const data = location.state.data || [];
//   const startDate = location.state.startDate || [];
//   const endDate = location.state.endDate || [];
//   const userId = data[0].userId;

//   useEffect(() => {
//     getUserProfile(userId, setLoading, setUserProfile);
//   }, [userId]);

//   const downloadPdf = () => {
//     const doc = new jsPDF();
//     const contentDiv = document.getElementById("pdf-container");
//     setShowButtons(false);

//     html2canvas(contentDiv, {
//       scrollX: 0,
//       scrollY: -window.scrollY,
//       scale: 2, // Increase scale to improve image quality
//     }).then((canvas) => {
//       const imgData = canvas.toDataURL("image/png");
//       const pdfWidth = doc.internal.pageSize.getWidth();
//       const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

//       doc.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
//       doc.save("account-statement.pdf");
//     });
//   };

//   const handlePrint = () => {
//     setShowButtons(false);
//     setTimeout(() => {
//       window.print();
//     }, 1000);
//   };

//   return (
//     <SlideFade in={true} offsetX="-1000px">
//       <Stack
//         spacing={0}
//         p={[2, 4]}
//         id="pdf-container"
//         w={"100%"}
//         minW={["700px"]}
//       >
//         <Box
//           shadow="md"
//           bg="white"
//           px={["20px", "30px"]}
//           py={"20px"}
//           display="flex"
//           alignItems="center"
//           justifyContent={"space-between"}
//         >
//           <Flex align={"center"}>
//             <Box maxW="200px">
//               <Image src={logo} alt="Bank Logo" style={{ height: "40px" }} />
//             </Box>
//             <Text color={"#000"} fontSize={"18px"} fontWeight={"bold"}>
//               MyEstateNG
//             </Text>
//           </Flex>
//           <Text fontSize={["18px", "20px"]} color={"black"} fontWeight={"bold"}>
//             Account Statement
//           </Text>
//         </Box>
//         <Container
//           minW="100%"
//           bg={cardBg}
//           px={["20px", "30px"]}
//           py={"50px"}
//           boxShadow="md"
//         >
//           <Box mb={6}>
//             <Text
//               fontSize="xl"
//               fontWeight="bold"
//               mb={2}
//               color={headerColor}
//               p={"10px"}
//               bg={headerBg}
//             >
//               Customer Details
//             </Text>
//             <Text color={textColor}>Name: {userProfile?.tenantName}</Text>
//             <Text color={textColor}>Email: {userProfile?.email}</Text>
//             <Text color={textColor}>Phone: {userProfile?.tenantPhone}</Text>
//             <Text color={textColor}>
//               Address: {userProfile?.houseId?.estate[0]?.houseNumber}
//             </Text>
//             <Text color={textColor}>User ID: {userProfile?._id}</Text>
//           </Box>
//           <Text
//             border={`1px solid green`}
//             py={"10px"}
//             px={"5px"}
//             mt={"20px"}
//             fontWeight={"semibold"}
//             color={textColor}
//           >
//             Duration: {"  "}
//             {dayjs(startDate).format("DD/MMM/YYYY")} -{" "}
//             {dayjs(endDate).format("DD/MMM/YYYY")}
//           </Text>
//           <Flex mt={"20px"}>
//             <Table
//               variant="simple"
//               size="sm"
//               bg="white"
//               borderRadius="lg"
//               // w={"100%"}
//               maxW={"600px"}
//               boxShadow={`0 4px 12px 0 gray.200`}
//               borderColor="transparent"
//             >
//               <Thead>
//                 <Tr w={"100% !important"} fontSize={"10px"}>
//                   <Th bg={headerBg} color={headerColor} py={"10px"}>
//                     Date
//                   </Th>
//                   <Th bg={headerBg} color={headerColor}>
//                     Description
//                   </Th>
//                   <Th bg={headerBg} color={headerColor}>
//                     Amount
//                   </Th>
//                   <Th bg={headerBg} color={headerColor}>
//                     Category
//                   </Th>
//                   <Th bg={headerBg} color={headerColor}>
//                     Status
//                   </Th>
//                   <Th bg={headerBg} color={headerColor}>
//                     Balance
//                   </Th>
//                 </Tr>
//               </Thead>
//               <Tbody>
//                 {data?.map((transaction, index) => (
//                   <Tr
//                     key={transaction.id}
//                     bg={index % 2 === 0 ? "gray.100" : "gray.200"}
//                     w={"100% !important"}
//                     fontSize={"10px"}
//                   >
//                     <Td>
//                       {dayjs(transaction?.createdAt).format("DD/MMM/YYYY")}
//                     </Td>
//                     <Td>{transaction?.transactionType}</Td>
//                     <Td>NGN {transaction?.amount}</Td>
//                     <Td>{transaction?.transactioninfo.services}</Td>
//                     <Td>{transaction?.status}</Td>
//                     <Td>NGN {transaction?.balanceAfter}</Td>
//                   </Tr>
//                 ))}
//               </Tbody>
//             </Table>
//           </Flex>
//           <Flex justify={"flex-end"} mt={8}>
//             <Button
//               display={showButtons === false ? "none" : "block"}
//               bg={headerBg}
//               color={headerColor}
//               size="lg"
//               _hover={{ backgroundColor: headerBg }}
//               onClick={handlePrint}
//             >
//               Print
//             </Button>
//           </Flex>
//           <Box
//             // shadow="sm"
//             // bg="white"
//             // p={4}
//             // w={"95%"}
//             // color="gray.700"
//             // bottom={"20px"}
//             // position={"absolute"}
//             mt="100px"
//           >
//             <Text textAlign="center">Powered by MyEstate.ng</Text>
//           </Box>
//         </Container>
//       </Stack>
//     </SlideFade>
//   );
// };

// import {
//   Box,
//   SlideFade,
//   Stack,
//   Text,
//   Table,
//   Thead,
//   Tbody,
//   Tr,
//   Th,
//   Td,
//   useColorModeValue,
//   Image,
//   Flex,
//   Button,
// } from "@chakra-ui/react";
// import React, { useEffect, useState } from "react";
// import { COLORS } from "../../../constants/colors";
// import Container from "../../../components/Container";
// import { transition } from "../../..";
// import { PROTECTED_PATHS } from "../../../app/constants";
// import logo from "../logo.svg";
// import "../../../App.css";
// import { useLocation } from "react-router-dom";
// import { getUserProfile } from "../../settings/service/getUserProfile";
// import dayjs from "dayjs";
// import { successNotifier } from "../../../components/NotificationHandler";
// import { jsPDF } from "jspdf";

// export const FilteredAccountStatement = () => {
//   const cardBg = useColorModeValue("white", "gray.800");
//   const tableRowEven = useColorModeValue("gray.100", "white.500");
//   const tableRowOdd = useColorModeValue("gray.200", "white.400");
//   const headerBg = useColorModeValue(COLORS.green, COLORS.green);
//   const headerColor = useColorModeValue("white", "white");
//   const textColor = useColorModeValue("black", "white");
//   const shadowColor = useColorModeValue("gray.200", "gray.600");
//   const [loading, setLoading] = useState(false);
//   const [userProfile, setUserProfile] = useState(false);
//   const location = useLocation();
//   const data = location.state || [];
//   const userId = data[0].userId;

//   useEffect(() => {
//     getUserProfile(userId, setLoading, setUserProfile);
//   }, [userId]);

//   console.log(userId, "location:", data);
//   console.log("profile:", userProfile);

//   const downloadPdf = () => {
//     const doc = new jsPDF();

//     // Source HTMLElement or a string containing HTML.
//     const elementHTML = document.querySelector("pdf-container");

//     doc.html(elementHTML, {
//       callback: function (doc) {
//         // Save the PDF
//         doc.save("sample-document.pdf");
//       },
//       x: 15,
//       y: 15,
//       width: 170, //target width in the PDF document
//       windowWidth: 650, //window width in CSS pixels
//     });
//   };

//   return (
//     <SlideFade in={true} offsetX="-1000px">
//       <Stack spacing={0} p={4} id="#pdf-container">
//         <Box
//           shadow="md"
//           bg="white"
//           px={"50px"}
//           py={"20px"}
//           display="flex"
//           alignItems="center"
//           justifyContent={"space-between"}
//         >
//           <Flex align={"center"}>
//             <Box maxW="200px">
//               <Image src={logo} alt="Bank Logo" style={{ height: "40px" }} />
//             </Box>
//             <Text color={"#000"} fontSize={"18px"} fontWeight={"bold"}>
//               MyEstateNG
//             </Text>
//           </Flex>
//           <Text fontSize={"20px"} color={"black"} fontWeight={"bold"}>
//             Account Statement
//           </Text>
//         </Box>
//         <Container maxW="xl" bg={cardBg} p={"50px"} boxShadow="md">
//           {/* Render customer details */}
//           <Box mb={6}>
//             <Text
//               fontSize="xl"
//               fontWeight="bold"
//               mb={2}
//               color={headerColor}
//               p={"10px"}
//               bg={headerBg}
//             >
//               Customer Details
//             </Text>
//             <Text color={textColor}>Name: {userProfile?.tenantName}</Text>
//             <Text color={textColor}>Email: {userProfile?.email}</Text>
//             <Text color={textColor}>Phone: {userProfile?.tenantPhone}</Text>
//             <Text color={textColor}>
//               Address: {userProfile?.houseId?.estate[0]?.houseNumber}
//             </Text>
//             <Text color={textColor}>User ID: {userProfile?._id}</Text>

//             <Text
//               border={`1px solid green`}
//               py={"10px"}
//               px={"5px"}
//               mt={"20px"}
//               fontWeight={"semibold"}
//               color={textColor}
//             >
//               Duration:
//               {/*} {dayjs(data?.estateDue.startDate).format(
//                 "DD/MMM/YYYY"
//              )} - {dayjs(data?.estateDue.endDate).format("DD/MMM/YYYY")}*/}
//             </Text>
//           </Box>
//           <Flex overflowX="auto">
//             <Table
//               variant="simple"
//               size="md"
//               bg="white"
//               borderRadius="lg"
//               boxShadow={`0 4px 12px 0 ${shadowColor}`}
//               borderColor="transparent"
//               minWidth="900px"
//             >
//               <Thead>
//                 <Tr>
//                   <Th bg={headerBg} color={headerColor}>
//                     Date
//                   </Th>
//                   <Th bg={headerBg} color={headerColor}>
//                     Description
//                   </Th>
//                   <Th bg={headerBg} color={headerColor}>
//                     Amount
//                   </Th>
//                   <Th bg={headerBg} color={headerColor}>
//                     Category
//                   </Th>
//                   <Th bg={headerBg} color={headerColor}>
//                     Status
//                   </Th>
//                   <Th bg={headerBg} color={headerColor}>
//                     Balance
//                   </Th>
//                 </Tr>
//               </Thead>
//               <Tbody>
//                 {data?.map((transaction, index) => (
//                   <Tr
//                     key={transaction.id}
//                     bg={index % 2 === 0 ? tableRowEven : tableRowOdd}
//                   >
//                     <Td>
//                       {dayjs(transaction?.createdAt).format("DD/MMM/YYYY")}
//                     </Td>
//                     <Td>{transaction?.transactionType}</Td>
//                     <Td>NGN {transaction?.amount}</Td>
//                     <Td>{transaction?.transactioninfo.services}</Td>
//                     <Td>{transaction?.status}</Td>
//                     <Td>NGN {transaction?.balanceAfter}</Td>
//                   </Tr>
//                 ))}
//               </Tbody>
//             </Table>
//           </Flex>
//           {/*<Box my={6}>
//             <Text
//               fontSize="xl"
//               fontWeight="bold"
//               mb={2}
//               color={headerColor}
//               p={"10px"}
//               bg={headerBg}
//             >
//               Other Details
//             </Text>
//             <Text color={textColor}>
//               Transaction Ref: {userProfile?.tenantName}
//             </Text>
//             <Text color={textColor}>User Id: {userProfile?.email}</Text>
//             <Text color={textColor}>
//               Transaction Channel: {userProfile?.tenantPhone}
//             </Text>
//                 </Box>*/}
//           <Flex justify={"flex-end"} mt={8}>
//             <Button
//               bg={headerBg}
//               color={headerColor}
//               size="lg"
//               _hover={{ backgroundColor: { headerBg } }}
//               onClick={downloadPdf}
//             >
//               Download
//             </Button>
//           </Flex>
//         </Container>
//         <Box shadow="sm" bg="white" p={4} color="gray.700">
//           <Text textAlign="center">Powered by MyEstate.ng</Text>
//         </Box>
//       </Stack>
//     </SlideFade>
//   );
// };
