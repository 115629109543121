import {
  Box,
  Center,
  Flex,
  HStack,
  Img,
  Stack,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { v4 } from "uuid";
import { PROTECTED_PATHS } from "../../../app/constants";
import { dynamicPathhandler } from "../../../components/utils/dynamicPathHandler";
import { COLORS } from "../../../constants/colors";

export const ActiveProducts = ({ data }) => {
  const appTextColor = useColorModeValue(COLORS.black, COLORS.white);
  const { VIEW_ONE_SHOP_PRODUCT } = PROTECTED_PATHS;
  const path = dynamicPathhandler(VIEW_ONE_SHOP_PRODUCT);

  return (
    <Box>
      {data?.length > 0 ? (
        <Stack spacing="5">
          {data?.map((data) => (
            <Link key={v4()} to={`${path}/${data._id}`}>
              <HStack
                gap="0.7rem"
                bg="gray.50"
                shadow="sm"
                p="7px"
                rounded="lg"
              >
                <Img
                  w="75px"
                  h="75px"
                  shadow="lg"
                  src={data?.imageUrl[0]}
                  alt="product"
                />
                <Flex justify="space-between" w="100%">
                  <Stack spacing={0}>
                    <Text fontSize="1rem">{data?.itemName}</Text>
                    {/*<Tooltip label={data?.itemDescription}>
                      <Text fontSize="0.8rem" isTruncated>
                        {data?.itemDescription}
                      </Text>
                    </Tooltip>*/}
                    <Text fontSize="1.1rem" fontWeight={600}>
                      &#8358; {data?.price?.toLocaleString()}
                    </Text>
                  </Stack>
                  {/*<BsThreeDotsVertical size={"1.5rem"} />*/}
                </Flex>
              </HStack>
            </Link>
          ))}
        </Stack>
      ) : (
        <Center flexDirection="column" h="70vh" w="100%">
          <Img src="/empty.svg" alt="empty" />
          <Text py="5" fontWeight="500" textAlign="center" color={appTextColor}>
            You do not have any active product yet
          </Text>
        </Center>
      )}
    </Box>
  );
};
