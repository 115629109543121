import {
  Box,
  Img,
  Stack,
  VStack,
  Text,
  Grid,
  useColorModeValue,
  Center,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { v4 } from "uuid";
import { PROTECTED_PATHS } from "../../../app/constants";
import Container from "../../../components/Container";
import FullPageLoader from "../../../components/FullPageLoader";
import Header from "../../../components/Header";
import { AuthContext } from "../../../context/authContext";
import SearchBar from "./SearchBar";
import { Link, useNavigate } from "react-router-dom";
import { getCategories } from "../service";
import { COLORS } from "../../../constants/colors";
import { dynamicPathhandler } from "../../../components/utils/dynamicPathHandler";

const AllCategory = () => {
  const appTextColor = useColorModeValue(COLORS.black, COLORS.white);
  const { userInfo } = useContext(AuthContext);
  const { userId } = userInfo;
  const [loading, setLoading] = useState(false);
  const { MARKET_PLACE, SAME_CATEGORY, CATEGORY } = PROTECTED_PATHS;
  const [input, setInput] = useState("");
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();
  const parentPath = dynamicPathhandler(CATEGORY);

  useEffect(() => {
    getCategories(userId, setLoading, setCategories);
  }, [userId]);

  return loading ? (
    <FullPageLoader />
  ) : (
    <Stack>
      <Box shadow="sm">
        <Container>
          <Header goto={MARKET_PLACE} />
        </Container>
      </Box>

      <Container>
        <Box py="5" mb="50px">
          <SearchBar input={input} setInput={setInput} />
        </Box>
        {categories?.length > 0 ? (
          <Grid templateColumns="repeat(3, 1fr)" gap={4}>
            {categories?.map((category, index) => (
              <>
                <VStack
                  bg="#fff"
                  shadow="md"
                  rounded="lg"
                  p={"5px"}
                  cursor="pointer"
                  w="100%"
                  justify="center"
                  className="category-container"
                  key={index}
                  onClick={() =>
                    navigate(
                      `${parentPath}/${category?.categoryName.toLowerCase()}`
                    )
                  }
                >
                  <Img
                    w={["80px"]}
                    maxW="80px"
                    h={["70px"]}
                    objectFit="cover"
                    rounded="lg"
                    src={category?.image}
                    alt={category?.categoryName}
                  />

                  <Text
                    textAlign="center"
                    fontSize="12px"
                    fontWeight="600"
                    // color="#7A7A7A"
                    color={COLORS.green}
                  >
                    {category?.categoryName}
                  </Text>
                </VStack>
              </>
            ))}
          </Grid>
        ) : (
          <Center flexDirection="column" h="70vh" w="100%">
            <Img src="/empty.svg" alt="empty" />
            <Text
              py="5"
              fontWeight="500"
              textAlign="center"
              color={appTextColor}
            >
              No categories yet
            </Text>
          </Center>
        )}
      </Container>
    </Stack>
  );
};

export default AllCategory;
