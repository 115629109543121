import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";

const PaystackModal = ({
  paymentModal,
  setPaymentModal,
  paymentData,
  onFinish,
}) => {
  console.log(paymentData, "pay");
  return (
    <Modal
      isOpen={paymentModal}
      onClose={() => {
        onFinish && onFinish();
        setPaymentModal(false);
      }}
      size="sm"
    >
      <ModalOverlay />
      <ModalContent bg={"white"}>
        <ModalHeader>Payment checkout</ModalHeader>
        <ModalCloseButton _focus={{ boxShadow: "none" }} />
        <ModalBody>
          <iframe
            src={paymentData?.authorization_url}
            frameBorder="0"
            width="100%"
            height="450"
            title="payment checkout"
          ></iframe>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PaystackModal;
