import React from "react";
import {
  AlertDialog,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Button,
  AlertDialogBody,
  Flex,
} from "@chakra-ui/react";
import { COLORS } from "../constants/colors";

export default function CautionAlertDialog({
  onContinue,
  loading,
  button,
  cautionTitle,
  agree,
  disagree,
  buttonProps,
  children,
  size,
  yesLoading,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  const BTN = {
    _focus: { boxShadow: "none" },
    color: "white",
    isLoading: yesLoading,
  };

  const handleClick = () => {
    onClose();
    onContinue(true);
  };

  return (
    <>
      <Button
        _focus={{ boxShadow: "none", bg: "none", border: "none" }}
        _hover={{ opacity: "0.8" }}
        onClick={onOpen}
        isLoading={loading && loading}
        color="#fff"
        {...buttonProps}
      >
        {button}
      </Button>

      <AlertDialog
        motionPreset="slideInBottom"
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        size={size || "xs"}
      >
        <AlertDialogOverlay />

        <AlertDialogContent bg="white" shadow="lg" textAlign="center">
          <AlertDialogHeader fontSize="16px">{cautionTitle}</AlertDialogHeader>
          <AlertDialogBody>{children}</AlertDialogBody>

          <Flex
            justify="space-between"
            w="100%"
            pt=".8rem"
            // gap="1rem"
            pb="20px"
            px="30px"
          >
            <Button
              border={`1px solid ${COLORS.green}`}
              px="15px"
              ref={cancelRef}
              onClick={onClose}
              _focus={{ boxShadow: "none" }}
            >
              {disagree || "Cancel"}
            </Button>
            <Button
              {...BTN}
              onClick={handleClick}
              _hover={{
                backgroundColor: `${COLORS.green}`,
              }}
              px="15px"
              bg={COLORS.green}
              ml={3}
            >
              {agree || "Confirm"}
            </Button>
          </Flex>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
