import { Circle, Flex, Stack, Text, VStack } from '@chakra-ui/react';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { FaMoneyBillAlt } from 'react-icons/fa';
import { v4 } from 'uuid';
import { PrimaryButton } from '../../../components/CustomButton';
import { makePartyEventRequest } from '../service/partyAndEvents';

export const PartyEventSummary = ({ values, setSuccess }) => {
  const [loading, setLoading] = useState(false);

  const handleSpecialRequet = () => {
    makePartyEventRequest(values, setLoading, setSuccess);
  };

  return (
    <Stack pos='relative'>
      <VStack>
        <Circle className='vehicle-payment-money' bg='secondary'>
          <FaMoneyBillAlt color='#fff' size='2.8rem' />
        </Circle>
        <Text pb='3' fontSize='1.2rem' fontWeight={'600'}>
          Special Request Summary
        </Text>
      </VStack>
      {/* <Stack> */}

      <Text fontSize='1rem'>
        <em style={{ fontWeight: '600' }}>Venue: </em> {values?.address}{' '}
      </Text>

      <Text>
        <em style={{ fontWeight: '600' }}>Expected number of Guests:</em>{' '}
        {values?.numberOfGuests}
      </Text>

      <Text fontSize='1rem'>
        <em style={{ fontWeight: '600' }}>dates:</em>{' '}
      </Text>

      <Flex wrap='wrap' gap='0.6rem' pb='10'>
        {values?.dates?.map((date) => (
          <Text
            key={v4()}
            bg='gray.100'
            px='5px'
            py='0.5'
            fontWeight='600'
            fontSize='12px'
          >
            {dayjs(date).format('DD-MMM-YYYY')}
          </Text>
        ))}
      </Flex>

      <PrimaryButton
        isLoading={loading}
        onClick={handleSpecialRequet}
        btnText='Send Request'
      />
    </Stack>
  );
};
