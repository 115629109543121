import http, { AUTH_ROUTES } from '../../../services/api';

export const getErrands = async (userId, setErrands, setLoading) => {
  setLoading(true);
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_ERRANDS({ userId }));

    setErrands(data?.errands);
  } catch (error) {
    console.log(error?.response);
  } finally {
    setLoading(false);
  }
};

export const getErrandPackages = async (setLoading, userId, setErrandPackage) => {
  setLoading(true);
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_ERRANDS_PACKAGES(userId));

    setErrandPackage(data.errandPackage);
  } catch (e) {
    console.log(e.response);
  } finally {
    setLoading(false);
  }
};
