import { Circle, Flex, Text, VStack } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { FaMoneyBillAlt } from 'react-icons/fa';
import { PrimaryButton } from '../../../components/CustomButton';
import { v4 } from 'uuid';
import { useEffect } from 'react';

export const ConfirmContractorArtisanPayment = ({
  setPaymentMethod,
  values,
  estateFee,
  setValues,
}) => {
  useEffect(() => {
    setValues((prev) => ({ ...prev, estateFee }));
  }, [estateFee, setValues]);

  return (
    <VStack pos='relative' bg={"white"}>
      <Circle className='vehicle-payment-money' bg='secondary'>
        <FaMoneyBillAlt color='#fff' size='2.8rem' />
      </Circle>
      <Text fontSize='1rem'>Confirm Payment</Text>

      <Text fontSize='1.2rem'>&#8358; {estateFee}</Text>
      <Text fontSize='1rem'>
        {values.numberOfGuests} {values?.subType}(s) for {values?.dates?.length}
        days
      </Text>
      <Text fontSize='1rem'>name: {values?.contactPerson}</Text>
      <Text fontSize='1rem'>phone: {values?.contactPersonPhone}</Text>
      <Flex wrap='wrap' gap='0.6rem' pb='10' pt='3'>
        {values?.dates?.map((date) => (
          <Text
            key={v4()}
            bg='gray.100'
            px='5px'
            py='0.5'
            fontWeight='600'
            fontSize='11px'
          >
            {dayjs(date).format('DD-MMM-YYYY')}
          </Text>
        ))}
      </Flex>
      <PrimaryButton btnText='Continue to payment' onClick={setPaymentMethod} />
    </VStack>
  );
};
