import { PROTECTED_PATHS } from '../../../app/constants';
import { errorNotifier } from '../../../components/NotificationHandler';
import { timeout } from '../../../components/utils/timeout';
import http, { AUTH_ROUTES } from '../../../services/api';

export const getDataPlans = async (userId, setDataPlans) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.GET_DATA_PLANS(userId));

    setDataPlans(data?.dataPlans);
  } catch (e) {
    console.log(e.response);

    if (e.response.status === 500) {
      errorNotifier('internet connection error');
    } else {
      errorNotifier(e.response.data.message);
    }
  }
};

export const buyDataSubscription = async (setLoading, payload, navigate, setSuccess) => {
  const { MY_WALLET } = PROTECTED_PATHS;
  setLoading(true);

  try {
    await http.post(AUTH_ROUTES.BUY_DATA_SUBSCRIPTION, payload);

    setSuccess(true);
    timeout(() => navigate(MY_WALLET), 3000);
  } catch (e) {
    console.log(e.response);
    errorNotifier(e.response.data.result.data);
  } finally {
    setLoading(false);
  }
};
