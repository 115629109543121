import {
  Box,
  Flex,
  FormControl,
  Input,
  Select,
  Square,
  Textarea,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PROTECTED_PATHS } from '../../../app/constants';
import Container from '../../../components/Container';
import { PrimaryButton } from '../../../components/CustomButton';
import Header from '../../../components/Header';
import { Success } from '../../../components/Success';
import { timeout } from '../../../components/utils/timeout';

const SelectedErrands = () => {
  const errandTypes = [
    'Filling Gas',
    'Shopping',
    'Pich Up',
    'Drop off',
    'Cash Withdrawal',
    'Unpacking',
    'Laundry',
    'Deliveries',
    'Unpacking',
  ];

  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const { ERRAND_HOME } = PROTECTED_PATHS;

  const handSendErrand = () => {
    setSuccess(true);

    timeout(() => {
      setSuccess(false);
      navigate(ERRAND_HOME);
    }, 4000);
  };

  return success ? (
    <Success />
  ) : (
    <Box as='main'>
      <Box shadow='md'>
        <Container>
          <Header menu />
        </Container>
      </Box>
      <Container>
        <Flex gap='1rem' py='5' wrap='wrap' justify='center'>
          {errandTypes?.map((errand, index) => (
            <Square key={index} px='3' py='1' border='1px solid #FEDEDC'>
              {errand}
            </Square>
          ))}
        </Flex>
        <Flex justify='center'>
          <PrimaryButton
            btnText='see more errnads'
            bg='none'
            size='sm'
            color='primaryDark'
            w='130px'
            _hover={{ bg: 'none', textDecoration: 'underline' }}
          />
        </Flex>
        <FormControl p='10'>
          <Input
            placeholder='Item'
            size='sm'
            border='1px solid #f0f'
            errorBorderColor='crimson'
          />
          <Textarea
            my='8'
            placeholder='Description'
            resize='none'
            rows='6'
            border='1px solid #FBABA7E0'
          />
          <Select mb='10' placeholder='Delivery options'>
            {[...Array(4)].map((item) => (
              <option>options</option>
            ))}
          </Select>
          <PrimaryButton btnText='Send errand' onClick={handSendErrand} />
        </FormControl>
      </Container>
    </Box>
  );
};

export default SelectedErrands;
