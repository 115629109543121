import { Box, HStack } from "@chakra-ui/react";
import CustomModal from "../../../components/CustomModal";
import { BuyAirtime, BuyData, PayBills } from "./Bills";
import BuyAirtimeModal from "./buy airtime/BuyAirtimeModal";
import BuyDataModal from "./buy data/BuyDataModal";
import PayBillsModal from "./pay bills/PayBillsModal";
import Container from "../../../components/Container";
import Header from "../../../components/Header";
import { PROTECTED_PATHS } from "../../../app/constants";

const Services = () => {
  const { MY_WALLET } = PROTECTED_PATHS;

  return (
    <Box>
      <Box shadow="sm">
        <Container>
          <Header goto={MY_WALLET} />
        </Container>
      </Box>
      <Container>
        <HStack mt="50px" gap="1rem" justify="space-between" wrap="wrap">
          <CustomModal addCloseModalBtn div btnTitle={<PayBills />}>
            <PayBillsModal />
          </CustomModal>

          <CustomModal addCloseModalBtn div btnTitle={<BuyAirtime />}>
            <BuyAirtimeModal />
          </CustomModal>

          <CustomModal addCloseModalBtn div btnTitle={<BuyData />}>
            <BuyDataModal />
          </CustomModal>
        </HStack>
      </Container>
    </Box>
  );
};

export default Services;
