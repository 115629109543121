export const greet = (currentHour) => {
  switch (currentHour) {
    case currentHour < 12:
      return 'Good Morning';
    case currentHour >= 17 && currentHour <= 24:
      return 'Good Evening';
    default:
      return 'Good Afternoon';
  }
};

export const dueStyles = {
  justify: 'space-between',
  // bg: '#EFE2FF',
  bg: '#FFFFFF',
  p: '5px 10px',
  color: '#4A1977',
  borderRadius: '5px',
  fontWeight: 500,
};

export const monthsAheadStyles = {
  bg: 'primaryLight',
  justify: 'space-between',
  align: 'center',
  px: '20px',
  py: '5px',
  borderRadius: '5px',
};

export const amountToPayInputStyles = {
  bg: 'gray.100',
  type: 'number',
  fontWeight: 'bold',
  placeholder: '30000',
  color: '#614385',
  fontSize: '1rem',
};
