import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";
import { Pie } from "react-chartjs-2";
import { Flex } from "@chakra-ui/react";

ChartJS.register(ArcElement, Tooltip, Legend, Title);

export function BillsPieChart({ data }) {
  const labels = data.map((item) => item.dueType);
  const amountsPaid = data.map((item) => item.totalAmountPaid);

  const chartData = {
    labels: labels,
    datasets: [
      {
        data: amountsPaid,
        backgroundColor: ["#116072", "#A5C2E7", "#F96400AD"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    responsive: true,
    cutoutPercentage: 85,
    maintainAspectRatio: false,
    legend: {
      display: false,
      position: "bottom",
      usePointStyle: "true",
      labels: {
        fontSize: 12,
        padding: 25,
        fontColor: "#6D7278",
        fontFamily: "kanit light",
      },
    },
  };

  return (
    <Flex justify={["center", "center", "start"]} w={"100%"}>
      <Pie
        data={chartData}
        options={options}
        height={"300px"}
        width={"200px"}
      />
    </Flex>
  );
}

