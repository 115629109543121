import { Box, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 } from "uuid";
import { PROTECTED_PATHS } from "../../app/constants";
import Container from "../../components/Container";
import Footer from "../../components/footer";
import FullPageLoader from "../../components/FullPageLoader";
import Header from "../../components/Header";
import { dynamicPathhandler } from "../../components/utils/dynamicPathHandler";
import { useAuth } from "../../context/authContext";
import { getPolls } from "./service";
import { COLORS } from "../../constants/colors";

const { VOTE } = PROTECTED_PATHS;

const Polls = () => {
  const cardBg = useColorModeValue(COLORS.primary, COLORS.white);
  const { userInfo } = useAuth();
  const { userId } = userInfo;
  const [loading, setLoading] = useState(false);
  const parent = dynamicPathhandler(VOTE);
  const navigate = useNavigate();
  const [polls, setPolls] = useState([]);

  useEffect(() => {
    getPolls(setLoading, userId, setPolls);
  }, [userId]);

  return loading ? (
    <FullPageLoader />
  ) : (
    <Stack mb="50px">
      <Box shadow="sm">
        <Container>
          <Header menu />
        </Container>
      </Box>
      <Container>
        {/*<Heading>Polls</Heading>*/}
        <Text py="10px" fontSize={"17px"} color={cardBg}>
          To participate and express your opinion, Click on a poll.
        </Text>
      </Container>

      <Container>
        {polls?.map((poll) => (
          <Stack
            my="8"
            key={v4()}
            p="20px"
            rounded="lg"
            spacing="10px"
            bg="gray.100"
            shadow="sm"
            cursor={"pointer"}
            onClick={() => navigate(`${parent}/${poll?.data?._id}`)}
          >
            <Text fontWeight="600" fontSize="1rem">
              {poll?.data.title}
            </Text>
            <Text textAlign="right">
              Start Time {dayjs(poll?.startDate).format("DD MMM YYYY HH:mm")}
            </Text>
          </Stack>
        ))}
      </Container>

      <Footer />
    </Stack>
  );
};

export default Polls;


// import {
//   Box,
//   Button,
//   Circle,
//   Flex,
//   HStack,
//   Stack,
//   Text,
//   useColorModeValue,
// } from "@chakra-ui/react";
// import dayjs from "dayjs";
// import { useEffect } from "react";
// import { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { v4 } from "uuid";
// import { PROTECTED_PATHS } from "../../app/constants";
// import Container from "../../components/Container";
// import Footer from "../../components/footer";
// import FullPageLoader from "../../components/FullPageLoader";
// import Header from "../../components/Header";
// import { dynamicPathhandler } from "../../components/utils/dynamicPathHandler";
// import { useAuth } from "../../context/authContext";
// import { getPolls } from "./service";
// import { COLORS } from "../../constants/colors";
// import { AiTwotoneHeart } from "react-icons/ai";
// import { BsFillPlusCircleFill } from "react-icons/bs";

// const { VOTE, CREATE_POLLS } = PROTECTED_PATHS;

// const Polls = () => {
//   const cardBg = useColorModeValue(COLORS.lightGrey, COLORS.white);
//   const bgColor = useColorModeValue(COLORS.primary, COLORS.green);
//   const appTextColor = useColorModeValue(COLORS.primary, COLORS.primary);
//   const { userInfo } = useAuth();
//   const { userId } = userInfo;
//   const [loading, setLoading] = useState(false);
//   const parent = dynamicPathhandler(VOTE);
//   const navigate = useNavigate();
//   const [polls, setPolls] = useState([]);

//   useEffect(() => {
//     getPolls(setLoading, userId, setPolls);
//   }, [userId]);

//   const totalVotes = polls.votes;
//   console.log(totalVotes, "POLLS", polls);

//   return loading ? (
//     <FullPageLoader />
//   ) : (
//     <Stack mb="50px">
//       <Box shadow="sm">
//         <Container>
//           <Header menu />
//         </Container>
//       </Box>
//       <Container>
//         <Text py="10px" fontSize={"17px"} color={cardBg}>
//           To participate and express your opinion, Click on a poll.
//         </Text>
//       </Container>

//       <Container>
//         {polls?.map((poll) => (
//           <Stack
//             my="8"
//             key={v4()}
//             p="20px"
//             rounded="lg"
//             spacing="10px"
//             bg="gray.100"
//             shadow="sm"
//             onClick={() => navigate(`${parent}/${poll?.data?._id}`)}
//           >
//             <Text fontWeight="600" fontSize="1rem">
//               {poll?.data?.title}
//             </Text>
//             <Text>
//               Anonymous{" "}
//               {dayjs(poll?.data?.startDate).format("DD MMM YYYY HH:mm")}
//             </Text>
//             <Flex width="100%" justify="space-between" wrap="wrap" gap="10px">
//               {poll?.data?.answerOptions?.map((answer, index) => (
//                 <Button
//                   flex={"1, 1, auto"}
//                   border="1px"
//                   px="40px"
//                   fontsize="14px"
//                   w="48%"
//                   key={index}
//                   isTruncated
//                   // maxWidth="200px !important"
//                 >
//                   {answer}
//                 </Button>
//               ))}
//             </Flex>
//             {/*<Flex justify="space-between">
//               <HStack>
//                 <AiTwotoneHeart color="red" />
//                 <Text>10K</Text>
//               </HStack>
//               <Text>1k Votes</Text>
//               </Flex>*/}
//           </Stack>
//         ))}
//         <Flex justify={"flex-end"} mt={"30px"} mb={"13vh"}>
//           <Circle cursor="pointer" bottom="10vh" shadow="md">
//             <BsFillPlusCircleFill
//               color={bgColor}
//               size={"2.5rem"}
//               onClick={() => navigate(CREATE_POLLS)}
//             />
//           </Circle>
//         </Flex>
//       </Container>

//       <Footer />
//     </Stack>
//   );
// };

// export default Polls;