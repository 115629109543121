import { Center } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { Calendar } from 'react-multi-date-picker';

const RandomPicker = ({ setCustomVisitValues, setSelectedRandomDates }) => {
  const today = new Date();

  const handleRandomDates = (date) => {
    const days = date.map((d) => dayjs(d).format('YYYY-MM-DD'));

    const sortedDate = date.sort((a, b) => a.dayOfYear - b.dayOfYear);

    const sortedCalendarDates = sortedDate.map((d) => {
      const calendarDate = `${d.day}-${d.month.shortName}`;
      return { calendarDate };
    });

    setSelectedRandomDates(sortedCalendarDates);

    const startDate = `${sortedDate[0].year}-${sortedDate[0].monthIndex + 1}-${sortedDate[0].day}`;
    const endDate = `${sortedDate[sortedDate.length - 1].year}-${sortedDate[sortedDate.length - 1].monthIndex + 1}-${date[date.length - 1].day}`;

    const formatedStartDate = dayjs(startDate).format('YYYY-MM-DD');
    const formatedEndDate = dayjs(endDate).format('YYYY-MM-DD');

    setCustomVisitValues((customeValues) => ({
      ...customeValues,
      dates: days,
      randomVisit: true,
      startDate: formatedStartDate,
      endDate: formatedEndDate,
    }));
  };
  return (
    <Center>
      <Calendar
        multiple
        minDate={today}
        // value={value}
        onChange={handleRandomDates}
      />
    </Center>
  );
};

export default RandomPicker;
