import { PROTECTED_PATHS } from '../../../app/constants';
import { errorNotifier } from '../../../components/NotificationHandler';
import { timeout } from '../../../components/utils/timeout';

import http, { AUTH_ROUTES } from '../../../services/api';
import { utitlityBillsImages } from '../components/data';

export const getElectricityPlans = async (userId, setLoading, setelecticityPlans) => {
  setLoading(true);

  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_ECTRICITY_PLANS(userId));

    const added = data.map((val) => ({
      ...val,
      image: utitlityBillsImages[val.disco_name.replace(/\s+/g, '')],
    }));

    setelecticityPlans(added);
  } catch (e) {
    console.log(e.response);
    setelecticityPlans([]);
  } finally {
    setLoading(false);
  }
};

export const payElectricityBill = async (setLoading, payload, setSuccess, navigate) => {
  const { MY_WALLET } = PROTECTED_PATHS;
  setLoading(true);

  try {
    await http.post(AUTH_ROUTES.PAY_ELECTRICITY_BILL, payload);

    setSuccess(true);
    timeout(() => navigate(MY_WALLET), 3000);
  } catch (err) {
    console.log(err.response.data);
    errorNotifier(err?.response.data.result.message);
  } finally {
    setLoading(false);
  }
};
