import http, { AUTH_ROUTES } from "../../../services/api";

export const getVehicleList = async (userId, setLoading, setVehicleList) => {
  setLoading(true);
  try {
    const data = await http.get(AUTH_ROUTES.GET_VEHICLE_LIST(userId));
    console.log(data, "vvvvv")
    setVehicleList(data?.data?.data?.vehicles);
    setLoading(false);
  } catch (error) {
    console.log("error", error.response);
    setLoading(false);
  }
};
