import {
  FormControl,
  Input,
  InputGroup,
  InputLeftElement,
  SlideFade,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useState } from "react";
import Container from "../../../components/Container";
import { PrimaryButton } from "../../../components/CustomButton";
import { inputStyles } from "../Login";
import { AiOutlineMail } from "react-icons/ai";
import { requestResetPassword } from "../service/resetCode";
import { Link, useNavigate } from "react-router-dom";
import { infoNotifier } from "../../../components/NotificationHandler";
import { PUBLIC_PATHS } from "../../../app/constants";
import { COLORS } from "../../../constants/colors";

const { LOGIN } = PUBLIC_PATHS;

const validateEmail =
  /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

export const RequestPasswordReset = () => {
  const cardBg = useColorModeValue(COLORS.primary, COLORS.white);
  const pageBg = useColorModeValue(COLORS.white, COLORS.black);

  const [tenantEmail, setTenantEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const requestPasswordRest = (e) => {
    e.preventDefault();

    if (!validateEmail.test(tenantEmail))
      return infoNotifier("Not a valid email");
    requestResetPassword(setLoading, { email: tenantEmail }, navigate);
  };

  return (
    <SlideFade in={true} offsetX="-5000px">
      <Container bg={pageBg}>
        <Stack w="100%" as="section" pt="15vh" h="100vh">
          <Text fontWeight="600" fontSize="2.5rem" color={cardBg}>
            Reset Password
          </Text>
          <Text fontWeight="500" color={cardBg} fontSize="1.0rem">
            Enter your email address to reset your password
          </Text>
          <FormControl w="100%">
            <InputGroup mt="20px">
              <Input
                autoFocus
                {...inputStyles}
                borderColor={COLORS.green}
                mb="20px"
                placeholder="Enter Your Registered Email"
                value={tenantEmail}
                type="email"
                onChange={(e) => setTenantEmail(e.target.value)}
              />
              <InputLeftElement children={<AiOutlineMail />} mt="10px" />
            </InputGroup>
            <Text
              fontSize="16px"
              textAlign="right"
              fontWeight={600}
              color={cardBg}
              cursor="pointer"
              mt="-17px"
              mb="50px"
            >
              <Link to={LOGIN}>Go back to Login</Link>
            </Text>

            <PrimaryButton
              loadingText="Loading"
              btnText="Submit"
              isLoading={loading}
              onClick={requestPasswordRest}
              h="60px"
              fontSize="18px"
              rounded="2xl"
            />
          </FormControl>
        </Stack>
      </Container>
    </SlideFade>
  );
};
