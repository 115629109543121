import {
  Box,
  Circle,
  Flex,
  HStack,
  Img,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SlideFade,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { PROTECTED_PATHS } from '../../../app/constants';
import Container from '../../../components/Container';
import Header from '../../../components/Header';
import { GoPrimitiveDot } from 'react-icons/go';
import { PrimaryButton } from '../../../components/CustomButton';
import { useState } from 'react';
import { transition } from '../../..';
import { paySpecialRequest, verifiySpecialRequestPayment } from '../service';
import { EReceipt } from './EReceipt';
import { COLORS } from '../../../constants/colors';

export const PaymentMethod = ({ values }) => {
    const cardBg = useColorModeValue(COLORS.primary, COLORS.white);
  const { SPECIAL_GUEST_REQUEST } = PROTECTED_PATHS;
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initializationResponse, setInitializationResponse] = useState({});
  const [payStackModalOpen, setPayStackModalOpen] = useState(false);
  const { onClose } = useDisclosure();
  const [verifyResponse, setVerifyResponse] = useState({});

  console.log('values', values);
  const handleMakePayment = () => {
    paySpecialRequest(
      values,
      setLoading,
      setSuccess,
      setPayStackModalOpen,
      setInitializationResponse
    );
  };

  const verifyAndCloseModal = () => {
    const payload = {
      reference: initializationResponse.reference,
      userId: values.userId,
    };

    verifiySpecialRequestPayment(
      payload,
      setSuccess,
      setLoading,
      setVerifyResponse,
      setPayStackModalOpen
    );
  };

  return (
    <>
      {success && <EReceipt verifyResponse={verifyResponse} />}

      {!success && (
        <SlideFade in={true} offsetX='-1000px' transition={{ ...transition }}>
          <Box>
            <Box shadow='sm'>
              <Container>
                <Header goto={SPECIAL_GUEST_REQUEST} />
              </Container>
            </Box>
            <Container>
              <Stack color={cardBg}>
                <Text fontSize='2.5rem' pb='5' fontWeight='800' maxW='80%'>
                  Payment Method
                </Text>

                <Flex
                  justify='space-between'
                  p='5'
                  borderRadius='5px'
                  shadow='2px 2px 10px 2px rgba(0,0,0,0.2)'
                >
                  <HStack>
                    <Img w='20px' src='/paystack.png' alt='paystack' />
                    <Text>PayStack</Text>
                  </HStack>
                  <Circle border='2px solid red' color='secondary'>
                    <GoPrimitiveDot size='1.4rem' />
                  </Circle>
                </Flex>
              </Stack>
              <Box py='16'>
                <PrimaryButton
                  isLoading={loading}
                  onClick={handleMakePayment}
                  btnText='Make payment'
                />
              </Box>
            </Container>
          </Box>
          <Modal
            isOpen={payStackModalOpen}
            isCentered
            onClose={onClose}
            size='sm'
            closeOnOverlayClick={false}
            scrollBehavior='outside'
          >
            <ModalOverlay />
            <ModalContent >
              <ModalHeader
                display='flex'
                align='center'
                justifyContent='space-between'
                color={cardBg}
              >
                Payment checkout
                <PrimaryButton
                  isLoading={loading}
                  onClick={verifyAndCloseModal}
                  size='sm'
                  w='50px'
                  btnText={'Close'}
                />
              </ModalHeader>
              {/* <ModalCloseButton onClick={onClose} bg='red' /> */}
              <ModalBody p='0' pb='2 !important'>
                <Box w='100%' pb='2 !important'>
                  <iframe
                    src={initializationResponse.authorization_url}
                    frameBorder='0'
                    width='100%'
                    height='500'
                    title='payment checkout'
                  ></iframe>
                </Box>
              </ModalBody>
            </ModalContent>
          </Modal>
        </SlideFade>
      )}
    </>
  );
};
