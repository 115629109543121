import { Stack, Text } from '@chakra-ui/react';
import { GrSatellite } from 'react-icons/gr';
import { FaRegLightbulb } from 'react-icons/fa';
import { PROTECTED_PATHS } from '../../../../app/constants';
import BillPicker from '../BillPicker';

const PayBillsModal = () => {
  const { CABLE_TV, POWER_UTILITY } = PROTECTED_PATHS;

  return (
    <Stack fontWeight='500' spacing='6'>
      <BillPicker to={CABLE_TV}>
        <GrSatellite size={25} />
        <Text>Cable Tv</Text>
      </BillPicker>

      <BillPicker to={POWER_UTILITY}>
        <FaRegLightbulb size={25} />
        <Text>Power & Utility</Text>
      </BillPicker>
    </Stack>
  );
};

export default PayBillsModal;
