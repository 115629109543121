import {
  Avatar,
  Box,
  Flex,
  HStack,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { RiAlarmWarningLine } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UserProfileContext } from "../../context/userProfileContext";
import { NAV_ITEMS } from "./navItems";
import { v4 as id } from "uuid";
import CustomModal from "../CustomModal";
import NavEmergencyModal from "./NavEmergencyModal";
import { BsPersonXFill } from "react-icons/bs";
import { SecondaryButton } from "../CustomButton";
import { COLORS } from "../../constants/colors";
import { PROTECTED_PATHS } from "../../app/constants";

const Sidebar = () => {
  const { userProfile } = useContext(UserProfileContext);
  const sidebarBg = useColorModeValue(COLORS.primary, COLORS.grey);
  const navigate = useNavigate();
  // useEffect(() => {
  //   if (!isLoggedin) {
  //     navigate('/');
  //   }
  // }, []);

  const handleLogout = () => {
    localStorage.removeItem("11p221#");
    window.location.replace("/login");
  };

  const handleNavItemClick = (navItem) => {
    if (navItem.externalLink) {
      navigate(PROTECTED_PATHS.CHAT, {
        state: { externalLink: navItem.externalLink },
      });
    } else {
      navigate(navItem.to);
    }
  };

  return (
    <Stack
      width="80%"
      bg={sidebarBg}
      opacity="90%"
      minH="100%"
      borderBottomRightRadius="5px"
      color={COLORS.white}
      pt="10"
      pl="10%"
    >
      <Box>
        <Avatar
          size="lg"
          name={userProfile?.tenantName}
          src={userProfile?.profilePicture}
        />
      </Box>
      <Text fontWeight="500" fontSize={"1rem"} pt="10px">
        {userProfile?.tenantName}
      </Text>
      <Text fontWeight="500" fontSize={".8rem"}>
        {userProfile?.email}
      </Text>
      {/* <Text fontSize='x-small'>Estate ID: 1512852</Text> */}
      <Stack spacing="5" pt="1.5rem">
        {NAV_ITEMS.map((navItem) => (
          <HStack
            w="100%"
            cursor="pointer"
            key={id()}
            onClick={() => handleNavItemClick(navItem)}
          >
            {navItem.icon}
            <Text fontSize="15px" pl="10px">
              {navItem.title}
            </Text>
          </HStack>
        ))}

        {/*<CustomModal
          overlayBG="primaryDark"
          size="xs"
          buttonProps={{ ...buttonProps }}
          btnTitle="Emergency"
          btnIcon={<RiAlarmWarningLine size={20} />}
        >
          <NavEmergencyModal />
        </CustomModal>*/}

        <Flex justify="flex-start" py="6vh">
          <SecondaryButton
            size="sm"
            shadow="md"
            opacity="80%"
            bg="primaryDark"
            _hover={{ backgroundColor: "primaryDark" }}
            w="150px"
            leftIcon={<BsPersonXFill />}
            btnText="Logout"
            onClick={handleLogout}
          />
        </Flex>
      </Stack>
    </Stack>
  );
};

export default Sidebar;

const buttonProps = {
  size: "sm",
  bg: "transparent",
  fontSize: "14px",
  fontWeight: "light",
  w: "120px",
  pl: "0",
};
