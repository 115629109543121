import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import Header from '../../../components/Header';
import Container from '../../../components/Container';
import { v4 } from 'uuid';
import { MdLock } from 'react-icons/md';
import { RiVisaLine } from 'react-icons/ri';
import { selectStyles } from './errandsData';
import { PrimaryButton } from '../../../components/CustomButton';
import { Success } from '../../../components/Success';
import { timeout } from '../../../components/utils/timeout';
import { useNavigate } from 'react-router-dom';
import { PROTECTED_PATHS } from '../../../app/constants';

const ErrandPaymentGateway = () => {
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const { MY_WALLET } = PROTECTED_PATHS;

  const handleMakePayment = () => {
    setSuccess(true);
    timeout(() => {
      setSuccess(false);
      navigate(MY_WALLET);
    }, 4000);
  };

  return success ? (
    <Success />
  ) : (
    <Stack>
      <Box shadow='md'>
        <Container>
          <Header />
        </Container>
      </Box>
      <Container>
        <Text fontSize='1.5rem' fontWeight='800' py='5'>
          Payment <br /> Method
        </Text>
        <FormControl>
          <FormLabel htmlFor='name' m='0' fontSize='13px'>
            Name On Card
          </FormLabel>
          <Input
            id='name'
            placeholder='Akogwu Emmanuel'
            name='name'
            border='1px solid #F9C8C5 !important'
          />
          <FormLabel htmlFor='card number' m='0' fontSize='13px' pt='5'>
            Card Number
          </FormLabel>
          <InputGroup>
            <Input
              placeholder='5656 **** **** ****'
              id='card number'
              name='cardNumber'
              border='1px solid #F9C8C5 !important'
            />
            <InputRightElement right='5' children={<RiVisaLine size={30} />} />
          </InputGroup>
          <Flex justify='space-between' py='5' gap='2rem'>
            <Box>
              <FormLabel fontSize='13px'>Expiry Date</FormLabel>
              <HStack spacing='0'>
                <Select {...selectStyles}>
                  {[...Array(12).keys()].map((item) => (
                    <option key={v4()}>{item + 1}</option>
                  ))}
                </Select>
                <Select {...selectStyles}>
                  {[...Array(2).keys()].map((item) => (
                    <option key={v4()}>{item + 1}</option>
                  ))}
                </Select>
              </HStack>
            </Box>
            <Box>
              <FormLabel htmlFor='cvv' fontSize='13px'>
                cvv
              </FormLabel>
              <InputGroup>
                <Input
                  id='cvv'
                  name='cvv'
                  border='1px solid #F9C8C5 !important'
                />
                <InputRightElement right='2' children={<MdLock />} />
              </InputGroup>
            </Box>
          </Flex>
          <Flex justify='center'>
            <PrimaryButton
              my='5'
              w='80%'
              btnText='Make Payment'
              onClick={handleMakePayment}
            />
          </Flex>
        </FormControl>
      </Container>
    </Stack>
  );
};

export default ErrandPaymentGateway;
