import React, { useContext } from "react";
import { UserProfileContext } from "../../../context/userProfileContext";
import { successNotifier } from "../../../components/NotificationHandler";
import {
  Box,
  Flex,
  HStack,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { BsWhatsapp, BsClipboardData } from "react-icons/bs";
import { timeout } from "../../../components/utils/timeout";
import { useNavigate } from "react-router-dom";
import { COLORS } from "../../../constants/colors";

function ShareInviteLink({ guestCode, onClose }) {
  const navigate = useNavigate();
  const { userProfile } = useContext(UserProfileContext);
  const appTextColor = useColorModeValue(COLORS.primary, COLORS.white);
    const cardBg = useColorModeValue(COLORS.primary, COLORS.white);


  const inviteMessage = `${userProfile.tenantName} is inviting you to Abraham Adesanya Estate. Please use ${guestCode} at the access point to gain access. reach me on ${userProfile.tenantPhone}. Powered by MyEstate.ng`;

  const copyToClippBoard = () => {
    navigator.clipboard.writeText(inviteMessage).then(() => {
      successNotifier("Link copied successfully");
      onClose();
      timeout(() => navigate("/my-estate"), 2500);
    });
  };

  const handleShareViaWhatsapp = () => {
    window
      .open(`whatsapp://send?text=${inviteMessage}`, "_blank")
      .then(onClose);
  };

  return (
    <Stack textAlign="center" color={COLORS.green}>
      <Text fontSize="1.2rem" fontWeight="bold" color={cardBg}>
        Share Guest Access Code:
      </Text>
      <HStack gap="2rem" justify="space-between">
        <Box flex="1" onClick={handleShareViaWhatsapp} cursor={"pointer"}>
          <Text pb="2" color={cardBg}> Share Via whatsapp</Text>
          <Flex justify="center" color="teal.400">
            <BsWhatsapp
              size={30}
              cursor="pointer"
              onClick={handleShareViaWhatsapp}
            />
          </Flex>
        </Box>
        <Box flex="1" onClick={copyToClippBoard} cursor={"pointer"}>
          <Text pb="2" color={cardBg}>Copy code to clipboard</Text>
          <Flex justify="center" color="teal.400">
            <BsClipboardData
              size={30}
              cursor="pointer"
              onClick={copyToClippBoard}
            />
          </Flex>
        </Box>
      </HStack>
    </Stack>
  );
}

export default ShareInviteLink;
