export const errandTabHeads = ['Pending', 'Ongoing', 'Completed'];

export const selectedTabStyles = {
  bg: 'primaryDark',
  color: '#fff',
  borderRadius: '3px',
};

export const selectStyles = {
  border: '1px solid #F9C8C5 !important',
  bg: 'lightPink',
  borderRadius: '2px',
  w: '80px',
  _focus: { border: 'none' },
};

export const errandSub = [
  {
    numberOfErrands: 20,
    points: 700,
  },
  {
    numberOfErrands: 40,
    points: 1200,
  },
  {
    numberOfErrands: 60,
    points: 1800,
  },
];
