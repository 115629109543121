import { isValidElement, Children, cloneElement } from "react";
import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";

export default function ShareModal({ children, size, isOpen, onClose }) {
  //   const { onOpen, isOpen, onClose } = useDisclosure();
  const childrenWithProps = Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a TS error too.
    if (isValidElement(child)) {
      return cloneElement(child, { onClose });
    }

    return child;
  });

  return (
    <>
      <ChakraModal
        size={size || "xs"}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent py={5} borderRadius="10px">
          <ModalCloseButton color="black" />
          <ModalBody py={{ base: 4, md: 4, lg: 6 }}>
            {childrenWithProps}
          </ModalBody>
        </ModalContent>
      </ChakraModal>
    </>
  );
}
