import http, { AUTH_ROUTES } from "../../../services/api";
import {
  errorNotifier,
  successNotifier,
} from "../../../components/NotificationHandler";

export const getMarketplaceBanner = async (userId, setLoading, setBanner) => {
  setLoading(true);
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_MARKET_BANNER(userId));

    setBanner(data?.banners);
  } catch (error) {
  } finally {
    setLoading(false);
  }
};


export const fetchSkillTypes = async (setLoading,setSkillTypes) => {
  setLoading(true);
  try {
    const response = await http.get(AUTH_ROUTES.GET_ARTISAN, {
      params: { approved: true }
    });
    const artisans = response.data?.result?.data || [];
    const uniqueSkills = [
      ...new Set(artisans.map((artisan) => artisan.skillType))
    ];
    setSkillTypes(uniqueSkills);
  } catch (error) {
    console.error("Error fetching artisans:", error);
  } finally {
    setLoading(false);
  }
};

export const fetchTopArtisans = async (setLoading, setTopArtisans) => {
  setLoading(true);
  try {
    const response = await http.get(AUTH_ROUTES.GET_TOP_ARTISAN);
    const artisans = response.data?.result?.data || [];
    setTopArtisans(artisans);
    return artisans;
  } catch (error) {
    console.error("Error fetching top artisans:", error);
    return [];
  } finally {
    setLoading(false);
  }
};


export const fetchArtisanDetails = async (originalArtisanId, setArtisan, setError, setLoading) => {
  try {
    const response = await http.get(AUTH_ROUTES.GET_ARTISAN, {
      params: { artisanId: originalArtisanId },
    });
    const artisanData = response.data.result?.data?.[0];
    if (artisanData) {
      setArtisan(artisanData);
    } else {
      setError("Artisan not found");
    }
  } catch (err) {
    setError("Failed to fetch artisan details");
  } finally {
    setLoading(false);
  }
};

export const fetchArtisans = async (setLoading,setArtisans,skillType) => {
  setLoading(true);
  try {
    const response = await http.get(AUTH_ROUTES.GET_ARTISAN,{
      params: { approved: true, skillType }
    });
    const data = response.data; // Axios returns the JSON data directly under `data`
    setArtisans(data.result.data || []);
  } catch (error) {
    console.error("Error fetching artisans:", error);
  } finally{
    setLoading(false);
  }
};

export const submitArtisanRating = async (setLoading, setError, payload, onSuccess) => {
  setLoading(true);
  try {
    const response = await http.post(AUTH_ROUTES.RATE_ARTISAN, payload);
    const { result } = response.data;

    if (result.success) {
      if (onSuccess) {
        onSuccess(result.data); // Pass the relevant data to the success callback
      }
    } else {
      const message = result.message || "An error occurred while submitting the rating.";
      errorNotifier(message);
      setError(message); // Update error state
    }
  } catch (error) {
    console.error("Error submitting rating:", error);
    const message =
      error.response?.data?.result?.message ||
      "An error occurred while submitting the rating.";
    errorNotifier(message); // Notify user of error
    setError(message); // Update error state
  } finally {
    setLoading(false);
  }
};


export const fetchArtisanReviews = async (artisanId, setReviews, setLoading) => {
  setLoading(true);
  try {
    const response = await http.get(AUTH_ROUTES.ARTISAN_RATING(artisanId));
    const reviews = response.data?.result?.data; // Extract the reviews array
    setReviews(Array.isArray(reviews) ? reviews : []); // Ensure it’s an array
  } catch (error) {
    console.error("Error fetching reviews:", error.message); // Log only the error message
    const errorMessage = error.response?.data?.message || "Failed to fetch reviews.";
    errorNotifier(errorMessage); // Notify user of error
    // Uncomment this if you want to update the error state
    // setError(errorMessage); 
  } finally {
    setLoading(false);
  }
};

